import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { of } from "rxjs";
import { Observable } from "rxjs/observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { MensajesAppService } from "src/app/services/mensajes-app.service";
import { SucuralesService } from "src/app/services/sucursales/sucursales.services";
import * as fromSucursalesConfuguradas from '../actions/configuracion-sucursales.action';

@Injectable()
export class SucursalesConfiguradasEffects {
    constructor(private actions$: Actions,
        private sucursalesServices: SucuralesService,
        private messageServices: MensajesAppService) {
    }

    @Effect()
    sucursalesConfiguradas$: Observable<Action> = this.actions$.pipe(
        ofType(fromSucursalesConfuguradas.GET_SUCURSALES_CONFIGURACION_LOAD),
        map((action: fromSucursalesConfuguradas.ConfiguracionSucursalesAction) => action.payload),
        switchMap((payload) => this.sucursalesServices.getSucursalesConfiguradas(payload).pipe(
            map(response => {
                return new fromSucursalesConfuguradas.ConfiguracionSucursalesLoadSuccess(response);
            }, catchError(error => of(new fromSucursalesConfuguradas.ConfiguracionSucursalesSucursalesLoadFail(error))))
        ))
    );
    @Effect()
    asignaSucursales$: Observable<Action> = this.actions$.pipe(
        ofType(fromSucursalesConfuguradas.ASIGNA_SUCURSAL_LOAD),
        map((action: fromSucursalesConfuguradas.AsignaSucursalLoad) => action.payload),
        switchMap((payload) => this.sucursalesServices.asignarSucursales(payload).pipe(
            map(response => {
                this.messageServices.messageBox(response.tipoRespuesta, response.mensaje);
                return new fromSucursalesConfuguradas.AsignaSucursalLoadSuccess(response);
            }, catchError(error => of(new fromSucursalesConfuguradas.AsignaSucursalLoadFail(error))))
        ))
    );
    
}