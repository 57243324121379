import { RouteInfo } from "./vertical-sidebar.metadata";

export const ROUTES: RouteInfo[] = [

    {
        path: 'dashboard',
        title: 'Dashboards',
        icon: 'Home',
        class: 'has-arrow',
        label: '',
        labelClass: 'side-badge badge badge-info',
        extralink: false,

        submenu: [
            {
                path: '/dashboard/encuestas',
                title: 'Encuestas',
                icon: 'mdi mdi-library-books',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },
            {
                path: '/dashboard/evaluaciones',
                //path:'',
                title: 'Evaluaciones',
                icon: 'mdi mdi-library-books',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            }
        ]
    },
    {
        path: '/pages/mis-encuestas',
        title: 'Mis Encuestas',
        icon: 'Layers',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {

        path: '/pages/mis-evaluaciones',
        //path:'',
        title: 'Mis Evaluaciones',
        icon: 'List',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {

        path: '/pages/grupos',
        title: 'Grupos',
        icon: 'Users',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {

        path: '/pages/invitaciones',
        title: 'Invitaciones',
        icon: 'Inbox',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    {

        path: '/pages/sucursales',
        title: 'Sucursales',
        icon: 'mdi mdi-city',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []

    }
    // {
    //     path: 'icons',
    //     title: 'Icons',
    //     icon: 'Feather',
    //     class: 'has-arrow',
    //     label: '',
    //     labelClass: '',
    //     extralink: false,
    //     submenu: [
    //         {
    //             path: '/icons/fontawesome',
    //             title: 'Fontawesome',
    //             icon: 'mdi mdi-emoticon-cool',
    //             class: '',
    //             label: '',
    //             labelClass: '',
    //             extralink: false,
    //             submenu: []
    //         },
    //         {
    //             path: '/icons/simpleline',
    //             title: 'Simple Line Icons',
    //             icon: 'mdi mdi mdi-image-broken-variant',
    //             class: '',
    //             label: '',
    //             labelClass: '',
    //             extralink: false,
    //             submenu: []
    //         },
    //         {
    //             path: '/icons/material',
    //             title: 'Material Icons',
    //             icon: 'mdi mdi-emoticon',
    //             class: '',
    //             label: '',
    //             labelClass: '',
    //             extralink: false,
    //             submenu: []
    //         }
    //     ]
    // }
];
