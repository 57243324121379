
import { Departamento } from 'src/app/shared/models/response/departamentos/departamento.model';
import * as fromDepartamentoAction from '../actions/departamentos.action';




export interface DepartamentoState {
    loanding: boolean;
    loaded: boolean;
    error: any;
    departamentos: Departamento [];
}

export const initialState: DepartamentoState = {
    loanding: false,
    loaded: false,
    error : '',
    departamentos: []
}


export function reducer(state= initialState, action: fromDepartamentoAction.DepartamentoAction ): DepartamentoState {
    switch (action.type) {
        case fromDepartamentoAction.DEPARTAMENTO_LOAD: {
            return {
                ...state,
                loanding: true,
                loaded : false,
                error: '',
                departamentos : []
            };
        }
        case fromDepartamentoAction.DEPARTAMENTO_SUCCESS: {
            const data = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                departamentos : data
            };
        }
        case fromDepartamentoAction.DEPARTAMENTO_FAIL : {
            const error = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                departamentos : [],
                error : error
            };

        }
    }
}

