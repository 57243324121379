
export class ConfiguracionBasica {
    public id: number;
    public codigo: string;
    public idTipoEncuesta : number;
    public habilitado: boolean;
    public fechaExpiracion?: Date;
    public nombreEncuesta: string;
    public configuraFecha : boolean;
}
