import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { EvaluacionesService } from "src/app/services/evaluaciones/evaluaciones.service";
import { MensajesAppService } from "src/app/services/mensajes-app.service";
import * as fromResponderEvaluacionV2 from '../../actions/evaluaciones/responder-evaluacionV2.action';




@Injectable()
export class LevantamientoEvaluacionEffects {
    constructor(private actions$: Actions,
        private evaluacionesService: EvaluacionesService,
        private messageServices: MensajesAppService,
        private toastr: ToastrService) {
    }
  

    @Effect()
    getEvaluacion$: Observable<Action> = this.actions$.pipe(
        ofType(fromResponderEvaluacionV2.GET_EVALUACION_LOAD_V2),
        map((action: fromResponderEvaluacionV2.GetEvaluacionLoadV2) => action.payload),
        switchMap( (payload)  => this.evaluacionesService.getEvaluacionLoadV2(payload).pipe(
            map(response => {
                return new fromResponderEvaluacionV2.GetEvaluacionSuccessV2(response);
            }, catchError(error => of(new fromResponderEvaluacionV2.GetEvaluacionFailV2(error))))
        ))
    );

  

}