

import { PerfilUsuario } from 'src/app/shared/models/response/perfil/perfil-usuario.model';
import * as fromActionUsuario from '../actions/user.action';


export interface UserAppState {
    loanding: boolean;
    loaded: boolean;
    usuario: PerfilUsuario;
    error: string;
}
export const initialState: UserAppState = {
    loanding: false,
    loaded : false,
    usuario : new PerfilUsuario(),
    error: ''
};

export function reducer(state = initialState, action: fromActionUsuario.UserActions ): UserAppState {
    switch (action.type) {
        case fromActionUsuario.USER_APP_LOAD :
             return {
                 ...state,
                 loanding: true,
                 loaded : false,
                 usuario : new PerfilUsuario(),
                 error : ''
             };
             break;
             case fromActionUsuario.USER_APP_LOAD_SUCCESS :
                const usuario = action.payload;
                return {
                    ...state,
                    loanding: false,
                    loaded : true,
                    usuario,
                    error : ''
                };
            break;
            case fromActionUsuario.USER_APP_LOAD_FAIL :
                const error = action.payload;
                return {
                    ...state,
                    loanding: false,
                    loaded : false,
                    usuario: new PerfilUsuario(),
                    error : error
                };

                break;
    }
}

export const getState = (state: UserAppState) => state;
export const getProfile = (state: UserAppState) => state != null ? state.usuario : new PerfilUsuario();
