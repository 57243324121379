
import { PerfilUsuario } from 'src/app/shared/models/response/perfil/perfil-usuario.model';
import * as fromActionProfile from '../actions/profile-user.action';

export interface PerfilState {
    loanding: boolean;
    loaded: boolean;
    profile: PerfilUsuario;
    error: string;
}
export const initialState: PerfilState = {
    loanding: false,
    loaded : false,
    profile : new PerfilUsuario(),
    error: ''
};

export function reducer(state = initialState, action: fromActionProfile.UserPerfilAction ): PerfilState {
    switch (action.type) {
        case fromActionProfile.PROFILE_USER :
             return {
                 ...state,
                 loanding: true,
                 loaded : false,
                 profile : new PerfilUsuario(),
                 error : ''
             };
             break;
             case fromActionProfile.PROFILE_SUCCESS :
                const profile = action.payload;
                return {
                    ...state,
                    loanding: false,
                    loaded : true,
                    profile,
                    error : ''
                };
            break;
            case fromActionProfile.PROFILE_FAIL :
                const error = action.payload;
                return {
                    ...state,
                    loanding: false,
                    loaded : false,
                    profile: new PerfilUsuario(),
                    error : error
                };

                break;
    }
}

export const getState = (state: PerfilState) => state;
export const getProfile = (state: PerfilState) => state != null ? state.profile : new PerfilUsuario();
