import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/config/config.service';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from '../error-handler.service';
import { MisEncuestasRequest } from 'src/app/shared/models/request/encuesta/mis-encuestas.request';
import { ListadoEncuestaInterna } from 'src/app/shared/models/response/encuestas/listado-encuesta-interna.model';
import { AddEncuestaRespone } from 'src/app/shared/models/response/encuestas/add-encuesta-response.model';
import { AddEncuesta } from 'src/app/shared/models/request/encuesta/addEncuesta.model';
import { DetalleEncuesta } from 'src/app/shared/models/response/encuestas/detalle-encuesta.model';
import { ListadoSecciones } from 'src/app/shared/models/response/encuestas/listado-secciones.model';
import { PreguntasRequest } from 'src/app/shared/models/request/encuesta/preguntas-request.model';
import { ListadoPreguntas } from 'src/app/shared/models/response/encuestas/listado-preguntas.model';
import { RegistroPregunta } from 'src/app/shared/models/request/encuesta/registro-pregunta.model';
import { PreguntaResponse } from 'src/app/shared/models/response/encuestas/pregunta-response.model';
import { Preguntas } from 'src/app/shared/models/response/encuestas/preguntas.model';
import { ResponseBase } from 'src/app/shared/models/response/response-base.model';
import { ObtenerQuizzResponder } from 'src/app/shared/models/request/encuesta/obtener-quizz-responder.request';
import { QuizzResponse } from 'src/app/shared/models/response/encuestas/quizz-response';
import { ConfiguracionResponse } from 'src/app/shared/models/response/encuestas/configuracion-response';
import { EncuestaGruposResponse } from 'src/app/shared/models/response/encuestas/encuesta-grupos-response.model';
import { AsignarGrupoRequest } from 'src/app/shared/models/request/encuesta/asignar-grupo-request.model';
import { ConfiguracionBasicaRequest } from 'src/app/shared/models/request/encuesta/configuracion-basica.model';
import { EncuestaRequest } from 'src/app/shared/models/request/encuesta/encuesta-request.model';
import { EncuestaResponse } from 'src/app/shared/models/response/encuestas/encuesta-response';
import { PublicaEncuestaRequest } from 'src/app/shared/models/request/encuesta/publica-encuesta-request';
import { EncuestaEstadisticaResponse } from 'src/app/shared/models/response/encuestas/encuesta-estadistica-response.model';
import { EliminaEncuestaResponse } from 'src/app/shared/models/response/encuestas/encuesta-eliminada.model';
import { PathLink } from 'src/app/shared/models/request/encuesta/pathLink';
import { ListadoEncuestaSimple } from 'src/app/shared/models/response/evaluaciones/wizard/listado-encuesta-simple-response.model';


@Injectable({
  providedIn: 'root'
})
export class EncuestaInternaService {

  headers: any;
  constructor(private config: ConfigService,
              private http: HttpClient,
              private servicesError: ErrorHandlerService
               ) {
    this.config.headers.subscribe((headers) => {
      if (headers) {
        this.headers = headers;
      }
    });
   }


   getLinks(){

    return this.http.get(`${environment.baseUrl}12/01`,{ headers: this.headers })
    .pipe(catchError(this.servicesError.erroHandler));
  }
  setPath(model: PathLink){
    return this.http.post(`${environment.baseUrl}12/03`,model,{ headers: this.headers })
    .pipe(catchError(this.servicesError.erroHandler));
  }
  
  setPathUpdate(model: PathLink){
    return this.http.patch(`${environment.baseUrl}12/04`,model,{ headers: this.headers })
    .pipe(catchError(this.servicesError.erroHandler));
  }

  deletePath(model: any){
    return this.http.patch(`${environment.baseUrl}12/04`,model,{ headers: this.headers })
    .pipe(catchError(this.servicesError.erroHandler));
  }

   getTiposEncuestas(model : MisEncuestasRequest): Observable<ListadoEncuestaInterna> {
     return this.http.post<ListadoEncuestaInterna>(`${environment.baseUrl}07/05`,model, { headers: this.headers })
     .pipe(catchError(this.servicesError.erroHandler));
   }
   addEncuesta(model: AddEncuesta): Observable<AddEncuestaRespone>  {
     return this.http.post<AddEncuestaRespone>(`${environment.baseUrl}03/02`, model , { headers: this.headers } )
     .pipe(catchError(this.servicesError.erroHandler));
   }
   getDetalleEncuesta(codigo: string): Observable<DetalleEncuesta> {
    return this.http.get<DetalleEncuesta>(`${environment.baseUrl}07/02?codigo=${codigo}`, { headers: this.headers })
    .pipe(catchError(this.servicesError.erroHandler));
   }

   getSecciones(idEncuesta: number): Observable<ListadoSecciones> {
    return this.http.get<ListadoSecciones>(`${environment.baseUrl}03/04?idEncuesta=${idEncuesta}`, { headers: this.headers })
    .pipe(catchError(this.servicesError.erroHandler));
   }
   getPreguntas(request: PreguntasRequest): Observable<ListadoPreguntas> {
     const url = `${environment.baseUrl}03/05?idEncuesta=${request.idEncuesta}&idSeccion=${request.idSeccion}`;
    return this.http.get<ListadoPreguntas>(url, { headers: this.headers })
    .pipe(catchError(this.servicesError.erroHandler));
   }
   AddPregunta(request: RegistroPregunta): Observable<PreguntaResponse> {

  return this.http.post<PreguntaResponse>(`${environment.baseUrl}03/04`, request, { headers:  this.headers })
  .pipe(catchError(this.servicesError.erroHandler));
   }

  preguntaSelected(model: Preguntas): Observable<Preguntas> {
    return of(model).pipe(catchError(this.servicesError.erroHandler));
  }
  deletePregunta(idPregunta: number): Observable<ResponseBase> {
    return this.http.delete<ResponseBase>(`${environment.baseUrl}05/01?idPregunta=${idPregunta}`, { headers:  this.headers })
    .pipe(catchError(this.servicesError.erroHandler));
  }
  copyPregunta(idPregunta: number): Observable<ResponseBase> {
  return this.http.post<ResponseBase>(`${environment.baseUrl}05/00?idPregunta=${idPregunta}`, idPregunta , { headers:  this.headers })
    .pipe(catchError(this.servicesError.erroHandler));
  }
  getQuizz(model: ObtenerQuizzResponder): Observable<QuizzResponse> {
    let url = `${environment.baseUrl}07/04?codigo=${model.codigo}&idMiembro=${model.idMiembro}&preview=${model.preview}&idUsuarioMcl=${model.idUsuarioMcl}&niss=${model.niss}&origen=${model.origen}`
    return this.http.get<QuizzResponse>(url)
    .pipe(catchError(this.servicesError.erroHandler));
  }

  responderQuizz(model: QuizzResponse): Observable<QuizzResponse> {
    model.origen = location.href.split('/')[location.href.split('/').length-1];
    console.log(model);
    return this.http.post<QuizzResponse>(`${environment.baseUrl}05/01`, model,  { headers:  this.headers } )
    .pipe(catchError(this.servicesError.erroHandler));
  }
   configuracionBasica(codigo: string): Observable<ConfiguracionResponse> {
    return this.http.get<ConfiguracionResponse>(`${environment.baseUrl}07/05?codigo=${codigo}`,  { headers:  this.headers })
    .pipe(catchError(this.servicesError.erroHandler));
   }
   grupoEncuestas(request: any): Observable<EncuestaGruposResponse> {
     const url = `${environment.baseUrl}07/06?codigo=${request.codigo}&idUsuarioTemporal=${request.idUsuario}`;

    return this.http.get<EncuestaGruposResponse>(url,  { headers:  this.headers })
    .pipe(catchError(this.servicesError.erroHandler));

   }
   asignaGruposEncuesta(request: AsignarGrupoRequest): Observable<ResponseBase> {
    return this.http.post<ResponseBase>(`${environment.baseUrl}07/02`, request,  { headers:  this.headers } )
    .pipe(catchError(this.servicesError.erroHandler));
   }

   getColonies() {
    return this.http.get(`${environment.baseUrl}12/05`, { headers:  this.headers } )
    .pipe(catchError(this.servicesError.erroHandler));
   }
   enviaConfiguracionBasica(request: ConfiguracionBasicaRequest): Observable<ConfiguracionResponse> {
    return this.http.put<ConfiguracionResponse>(`${environment.baseUrl}07/01`, request,  { headers:  this.headers } )
    .pipe(catchError(this.servicesError.erroHandler));
   }
   sendEncuesta(request : EncuestaRequest) : Observable<EncuestaResponse> {
    return this.http.post<EncuestaResponse>(`${environment.baseUrl}07/03`, request,  { headers:  this.headers } )
    .pipe(catchError(this.servicesError.erroHandler));
   }
   publicaEncuesta(request : PublicaEncuestaRequest) : Observable<ResponseBase> {
    return this.http.post<ResponseBase>(`${environment.baseUrl}07/04`, request,  { headers:  this.headers } )
    .pipe(catchError(this.servicesError.erroHandler));
   }
   getEncuestaWizard(codigo : string) : Observable<EncuestaResponse> {
    return this.http.get<EncuestaResponse>(`${environment.baseUrl}07/09?codigo=${codigo}`,  { headers:  this.headers } )
    .pipe(catchError(this.servicesError.erroHandler));
   }
   getEncuestaEstatisticas(codigo : string) : Observable<EncuestaEstadisticaResponse> {
    return this.http.get<EncuestaEstadisticaResponse>(`${environment.baseUrl}03/06?codigo=${codigo}`,  { headers:  this.headers } )
    .pipe(catchError(this.servicesError.erroHandler));
   }
   eliminarEncuesta(idEncuesta : number) : Observable<EliminaEncuestaResponse> {
    return this.http.delete<EliminaEncuestaResponse>(`${environment.baseUrl}07/02?idEncuesta=${idEncuesta}`,  { headers:  this.headers } )
    .pipe(catchError(this.servicesError.erroHandler));
   }
   generarReporte(idEncuesta : number): Observable<any> {
    return this.http.get(`${environment.baseUrl}07/15?idEncuesta=${idEncuesta}`, { headers: this.headers } )
    .pipe(catchError(this.servicesError.erroHandler));
   }
}
