import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { ConfigService } from './config.service';



@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private autService: ConfigService
  ) {

  }
  canActivate(route: ActivatedRouteSnapshot) {
    
    if (this.autService.token) {
      if (route.routeConfig.data) {
        const rolComponent = Number(route.routeConfig.data.role);
        
        if (rolComponent !== this.autService.rol) {
          this.router.navigateByUrl('authentication/login');
          //window.location.href = '/authentication/login';
        }
      }
      return true;
    } else {
      this.router.navigateByUrl('authentication/login');
      //window.location.href = '/authentication/login';
      return false;
    }
  }
}
