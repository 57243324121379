export class UsuariosAppDetalle {
    id: number;
    idUsuarioTemporal: number;
    nombre: string;
    paterno: string;
    materno: string;
    nombreCompleto: string;
    correo: string;
    habilitado: boolean;
}
