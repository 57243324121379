import { Action } from '@ngrx/store';
import { ObtenerQuizzResponder } from 'src/app/shared/models/request/encuesta/obtener-quizz-responder.request';
import { ResponderPregunta } from 'src/app/shared/models/responder-pregunta';
import { QuizzResponse } from 'src/app/shared/models/response/encuestas/quizz-response';
import { SeccionesQuizz } from 'src/app/shared/models/response/encuestas/secciones-quizz';


export const GET_ENCUESTA_LOAD = '[Get_Encuesta] Load';
export const GET_ENCUESTA_SUCCESS = '[Get_Encuesta] Success';
export const GET_ENCUESTA_FAIL = '[Get_Encuesta] Fail';

export class GetEncuestaLoad implements Action {
    readonly type = GET_ENCUESTA_LOAD;
    constructor(public payload: ObtenerQuizzResponder) {
    }
}

export class GetEncuestaSuccess implements Action {
    readonly type = GET_ENCUESTA_SUCCESS;

    constructor(public payload: QuizzResponse) {
    }
}
export class GetEncuestaFail implements Action {
    readonly type = GET_ENCUESTA_FAIL;

    constructor(public payload: any) {
    }
}

export const RESPONDER_ENCUESTA_LOAD = '[Responer_Encuesta] Load';
export const RESPONDER_ENCUESTA_SUCCESS = '[Responder_Encuesta] Success';
export const RESPONDER_ENCUESTA_FAIL = '[Responder_Encuesta] Fail';

export class ResponderEncuestaLoad implements Action {
    readonly type = RESPONDER_ENCUESTA_LOAD;
    constructor(public payload: QuizzResponse) {
    }
}

export class ResponderEncuestaSuccess implements Action {
    readonly type = RESPONDER_ENCUESTA_SUCCESS;

    constructor(public payload: QuizzResponse) {
    }
}
export class ResponderEncuestaFail implements Action {
    readonly type = RESPONDER_ENCUESTA_FAIL;

    constructor(public payload: any) {
    }
}



export const RESPONDE_PREGUNTA = '[Responer_Encuesta] Responder Pregunta';

export class ResponderPreguntaQuizz implements Action {
    readonly type = RESPONDE_PREGUNTA;

    constructor(public payload: ResponderPregunta) {
    }
}
export const ACTUALIZAR_SECCIONES = '[Responer_Encuesta] Actualizar Secciones';

export class ActualizarSeccionesQuizz implements Action {
    readonly type = ACTUALIZAR_SECCIONES;

    constructor(public payload: SeccionesQuizz[]) {
    }
}

export const INICIAR_ENCUESTA = '[Responer_Encuesta] Iniciar Encuesta';

export class IniciarEncuesta implements Action {
    readonly type = INICIAR_ENCUESTA;

    constructor() {
    }
}
export const TERMINO_ENCUESTA = '[Responer_Encuesta] Termino';

export class TerminoEncuesta implements Action {
    readonly type = TERMINO_ENCUESTA;
    constructor() {
    }
}
export const COMENTARIO_ABIERTO = '[Responer_Encuesta] Comentario Abierto';

export class ComentarioAbierto implements Action {
    readonly type = COMENTARIO_ABIERTO;

    constructor(public payload: ResponderPregunta) {
    }
}

export const REINICIA_ENCUESTA = '[Responer_Encuesta] Reinici';

export class ReiniciaEncuesta implements Action {
    readonly type = REINICIA_ENCUESTA;
    constructor() {
    }
}

export const SELECCIONA_SUCURSAL = '[Responer_Encuesta] Elige Sucursal';

export class EligeSucursal implements Action {
    readonly type = SELECCIONA_SUCURSAL;
    constructor(public payload: number) {
    }
}




export type QuizzAction =
GetEncuestaLoad |
GetEncuestaSuccess |
GetEncuestaFail |
ResponderEncuestaLoad |
ResponderEncuestaSuccess |
ResponderEncuestaFail |
ResponderPreguntaQuizz |
IniciarEncuesta |
TerminoEncuesta |
ReiniciaEncuesta |
EligeSucursal |
ComentarioAbierto |
ActualizarSeccionesQuizz ;

