import { Component, AfterViewInit, EventEmitter, Output, OnDestroy, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '../../store';
import { PerfilUsuario } from '../models/response/perfil/perfil-usuario.model';

declare var $: any;

@Component({
  selector: 'app-horizontal-navigation',
  templateUrl: './horizontal-navigation.component.html'
})
export class HorizontalNavigationComponent implements AfterViewInit, OnDestroy, OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;
  public nombre: string;
  public correo: string;
  storeProfile: Subscription;
  closeResult: string;

  public isCollapsed = false;
  public showMobileMenu = false;

  perfilUsuario: PerfilUsuario = new PerfilUsuario();

 
  constructor(private modalService: NgbModal,
    private store: Store<fromStore.AppState>) {

   
  }
  ngOnInit(): void {
    this.store.dispatch(new fromStore.ProfileUser(localStorage.idUsuario));
    this.initSubcriptions();
  }
  initSubcriptions() {
    this.storeProfile =  this.store.select('profile').subscribe(rs => {
      if (rs) {
        this.perfilUsuario = {...rs.profile};
      }
    });
  }
  ngOnDestroy(): void {
    this.storeProfile.unsubscribe();
  }
  logOff() {
    this.store.dispatch(new fromStore.LogOff());
  }
  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  copyCodigo(codigo: string){
    
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = codigo;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
    

  ngAfterViewInit() { }

 /*  changeLanguage(lang: any) {
    this.translate.use(lang.code)
    this.selectedLanguage = lang;
  }
 */
}
