import { Seccion } from './seccion.model';
import { ResponseBase } from '../response-base.model';

export class DetalleEncuesta extends ResponseBase {
    pnid: number;
    dsnombre: string;
    dsdescripcion:  string;
    dstipo: string;
    dscodigoE: string;
    dshabilitado: boolean;
    dsfexpired?:  Date;
    dsguardarParcial:  boolean;
    dsPadlock?: boolean;
    FnidDepartamento?: number;
    dsfechagen?: Date;
    //secciones: Array<Seccion> = [];
}
