import { Action } from '@ngrx/store';
import { MisEncuestasRequest } from 'src/app/shared/models/request/encuesta/mis-encuestas.request';
import { PreguntasRequest } from 'src/app/shared/models/request/encuesta/preguntas-request.model';
import { RegistroPregunta } from 'src/app/shared/models/request/encuesta/registro-pregunta.model';
import { DetalleEncuesta } from 'src/app/shared/models/response/encuestas/detalle-encuesta.model';
import { EliminaEncuestaResponse } from 'src/app/shared/models/response/encuestas/encuesta-eliminada.model';
import { ListadoEncuestaInterna } from 'src/app/shared/models/response/encuestas/listado-encuesta-interna.model';
import { ListadoPreguntas } from 'src/app/shared/models/response/encuestas/listado-preguntas.model';
import { ListadoSecciones } from 'src/app/shared/models/response/encuestas/listado-secciones.model';
import { PreguntaResponse } from 'src/app/shared/models/response/encuestas/pregunta-response.model';
import { Preguntas } from 'src/app/shared/models/response/encuestas/preguntas.model';
import { ResponseBase } from 'src/app/shared/models/response/response-base.model';


export const ENCUESTAS_LOAD = '[Encuestas] Load';
export const ENCUESTAS_LOAD_SUCCESS = '[Encuestas] Load Success';
export const ENCUESTAS_LOAD_FAIL = '[Encuestas] Load Fail';


export class EncuestasLoad implements Action {
    readonly type = ENCUESTAS_LOAD;
    constructor(public payload : MisEncuestasRequest) { }
}

export class EncuestasLoadSuccess implements Action {
    readonly type = ENCUESTAS_LOAD_SUCCESS;
    constructor(public payload: ListadoEncuestaInterna) {
     }
}
export class EncuestasLoadFail implements Action {
    readonly type = ENCUESTAS_LOAD_FAIL;
    constructor(public payload: any) {
    }
}

// Acciones para obtener el detalle de la encuesta


export const ENCUESTA_DETALLE_LOAD = '[Encuesta] Get Load';
export const ENCUESTA_DETALLE_LOAD_SUCCESS = '[Encuesta] Get Load Success';
export const ENCUESTA_DETALLE_LOAD_FAIL = '[Encuesta] Get Load Fail';

export class EncuestaDetalleLoad implements Action {
    readonly type = ENCUESTA_DETALLE_LOAD;
    constructor(public payload: string) { }
}

export class EncuestaDetalleLoadSuccess implements Action {
    readonly type = ENCUESTA_DETALLE_LOAD_SUCCESS;
    constructor(public payload: DetalleEncuesta) {
     }
}
export class EncuestaDetalleLoadFail implements Action {
    readonly type = ENCUESTA_DETALLE_LOAD_FAIL;
    constructor(public payload: any) {
    }
}


export const SECCIONES_LOAD = '[Secciones] Load';
export const SECCIONES_LOAD_SUCCESS = '[Secciones] Load Success';
export const SECCIONES_LOAD_FAIL = '[Secciones] Load Fail';


export class SeccionesLoad implements Action {
    readonly type = SECCIONES_LOAD;
    constructor(public payload: number) { }
}

export class SeccionesSuccess implements Action {
    readonly type = SECCIONES_LOAD_SUCCESS;
    constructor(public payload: ListadoSecciones) {
     }
}
export class SeccionesFail implements Action {
    readonly type = SECCIONES_LOAD_FAIL;
    constructor(public payload: any) {
    }
}


export const PREGUNTAS_LOAD = '[Preguntas] Load';
export const PREGUNTAS_LOAD_SUCCESS = '[Preguntas] Load Success';
export const PREGUNTAS_LOAD_FAIL = '[Preguntas] Load Fail';


export class PreguntasLoad implements Action {
    readonly type = PREGUNTAS_LOAD;
    constructor(public payload: PreguntasRequest) { }
}

export class PreguntasSuccess implements Action {
    readonly type = PREGUNTAS_LOAD_SUCCESS;
    constructor(public payload: ListadoPreguntas) {
     }
}
export class PreguntasFail implements Action {
    readonly type = PREGUNTAS_LOAD_FAIL;
    constructor(public payload: any) {
    }
}

export const PREGUNTAS_ADD = '[Preguntas] add';
export const PREGUNTAS_ADD_SUCCESS = '[Preguntas] add Success';
export const PREGUNTAS_ADD_FAIL = '[Preguntas] add Fail';


export class PreguntasAddLoad implements Action {
    readonly type = PREGUNTAS_ADD;
    constructor(public payload: RegistroPregunta) { }
}

export class PreguntasAddSuccess implements Action {
    readonly type = PREGUNTAS_ADD_SUCCESS;
    constructor(public payload: PreguntaResponse) {
     }
}
export class PreguntasAddFail implements Action {
    readonly type = PREGUNTAS_ADD_FAIL;
    constructor(public payload: any) {
    }
}


export const PREGUNTAS_SELECTED = '[Pregunta] Selected';
export const PREGUNTAS_SELECTED_SUCCESS = '[Pregunta] Selected Success';
export const PREGUNTAS_SELECTED_FAIL = '[Pregunta] Selected Fail';


export class PreguntaSelected implements Action {
    readonly type = PREGUNTAS_SELECTED;
    constructor(public payload: Preguntas) { }
}

export class PreguntaSelectedSuccesss implements Action {
    readonly type = PREGUNTAS_SELECTED_SUCCESS;
    constructor(public payload: Preguntas) {
     }
}
export class PreguntaSelectedFail implements Action {
    readonly type = PREGUNTAS_SELECTED_FAIL;
    constructor(public payload: any) {
    }
}

export const PREGUNTAS_DELETED = '[Pregunta] Deleted';
export const PREGUNTAS_DELETED_SUCCESS = '[Pregunta] Deleted Success';
export const PREGUNTAS_DELETED_FAIL = '[Pregunta] Deleted Fail';


export class PreguntaDeleted implements Action {
    readonly type = PREGUNTAS_DELETED;
    constructor(public payload: number) { }
}

export class PreguntaDeletedSuccesss implements Action {
    readonly type = PREGUNTAS_DELETED_SUCCESS;
    constructor(public payload: ResponseBase) {
     }
}
export class PreguntaDeletedFail implements Action {
    readonly type = PREGUNTAS_DELETED_FAIL;
    constructor(public payload: any) {
    }
}


export const PREGUNTA_COPY = '[Pregunta] Copy';
export const PREGUNTA_COPY_SUCCESS = '[Pregunta] Copy Success';
export const PREGUNTA_COPY_FAIL = '[Pregunta] Copy Fail';


export class PreguntaCopy implements Action {
    readonly type = PREGUNTA_COPY;
    constructor(public payload: number) { }
}

export class PreguntaCopySuccess implements Action {
    readonly type = PREGUNTA_COPY_SUCCESS;
    constructor(public payload: ResponseBase) {
     }
}
export class PreguntaCopyFail implements Action {
    readonly type = PREGUNTA_COPY_FAIL;
    constructor(public payload: any) {
    }
}
export const ADD_ENCUESTA = '[Mis Encuestas] Add';

export class AgregaEncuestaSideBar implements Action {
    readonly type = ADD_ENCUESTA;
    constructor(

    ) {
    }
}


export const ENCUESTA_DELETE = '[Encuesta] Delete';
export const ENCUESTA_DELETE_SUCCESS = '[Encuesta] Delete Success';
export const ENCUESTA_DELETE_FAIL = '[Encuesta] Delete Fail';


export class DeleteEncuesta implements Action {
    readonly type = ENCUESTA_DELETE;
    constructor(public payload: number) {
     }
}

export class DeleteEncuestaSuccess implements Action {
    readonly type = ENCUESTA_DELETE_SUCCESS;
    constructor(public payload: EliminaEncuestaResponse) {
     }
}

export class DeleteEncuestaFail implements Action {
    readonly type = ENCUESTA_DELETE_FAIL;
    constructor(public payload: any) {
     }
}




export type EncuestaInternaAction =
EncuestasLoad |
EncuestasLoadSuccess |
EncuestasLoadFail | EncuestaDetalleLoad |
EncuestaDetalleLoadSuccess |
EncuestaDetalleLoadFail |
SeccionesLoad |
SeccionesSuccess |
SeccionesFail |
PreguntasLoad |
PreguntasSuccess |
PreguntasFail |
PreguntasAddLoad |
PreguntasAddSuccess |
PreguntasAddFail |
PreguntaSelected |
PreguntaSelectedSuccesss |
PreguntaSelectedFail |
PreguntaDeleted |
PreguntaDeletedSuccesss |
PreguntaDeletedFail |
PreguntaCopy |
PreguntaCopySuccess |
PreguntaCopyFail |
AgregaEncuestaSideBar |
DeleteEncuesta |
DeleteEncuestaSuccess |
DeleteEncuestaFail;
 
