import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { MensajesAppService } from 'src/app/services/mensajes-app.service';
import * as fromResultadosAcion from '../actions/resultados.action';
import { ResultadosService } from '../../services/resultados/resultados.service';
import { TipoRespuesta } from 'src/app/shared/models/enums/enumTipoRespuesta.model';



@Injectable()
export class ResultadosEffects {
    constructor(private actions$: Actions,
        private resultadosServices: ResultadosService,
        private messageServices: MensajesAppService) {
    }
    @Effect()
    analisisResultados$: Observable<Action> = this.actions$.pipe(
        ofType(fromResultadosAcion.ANALISIS_RESULTADOS_LOAD),
        map((action: fromResultadosAcion.AnalisisResultadosLoad) => action.payload),
        switchMap((payload) => this.resultadosServices.getAnalisisResultado(payload).pipe(
            map(response => {
                return new fromResultadosAcion.AnalisisResultadosLoadSuccess(response);
            }, catchError(error => of(new fromResultadosAcion.AnalisisResultadosLoadFail(error))))
        ))
    );
    @Effect()
    ResultadosDetalle$: Observable<Action> = this.actions$.pipe(
        ofType(fromResultadosAcion.RESULTADOS_DETALLE_LOAD),
        map((action: fromResultadosAcion.ResultadosDetalleLoad) => action.payload),
        switchMap((payload) => this.resultadosServices.getEncuestaRespuestas(payload).pipe(
            map(response => {
                if(response.tipoRespuesta == TipoRespuesta.Exito){
                   
                    return new fromResultadosAcion.ResultadosDetalleLoadSuccess(response);
                } else {
                    this.messageServices.messageBox(response.tipoRespuesta, response.mensaje);
                    return new fromResultadosAcion.ResultadosDetalleLoadFail(response);
                }
               
            }, catchError(error => of(new fromResultadosAcion.ResultadosDetalleLoadFail(error))))
        ))
    );
    @Effect()
    RespuestaDetalle$: Observable<Action> = this.actions$.pipe(
        ofType(fromResultadosAcion.DETALLE_RESULTADO_LOAD),
        map((action: fromResultadosAcion.DetalleResultadoLoad) => action.payload),
        switchMap((payload) => this.resultadosServices.getDetalleRespuesta(payload).pipe(
            map(response => {
                
                return new fromResultadosAcion.DetalleResultadoLoadSuccesss(response);
            }, catchError(error => of(new fromResultadosAcion.DetalleResultadoLoadFail(error))))
        ))
    );
}
