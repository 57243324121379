import { switchMap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as fromUserActions from '../actions/user.action';
import { LoginService } from '../../services/usuario/login.service';
import { ConfigService } from 'src/app/config/config.service';
import { Router } from '@angular/router';
import swal from 'sweetalert';
import { UsuarioService } from '../../services/usuario/usuario.service';
import { PerfilUserService } from 'src/app/services/perfil/perfil-user.service';
import { MensajesAppService } from 'src/app/services/mensajes-app.service';
import { ToastrService } from 'ngx-toastr';
import { TipoRespuesta } from 'src/app/shared/models/enums/enumTipoRespuesta.model';
import * as fromAdminAction from '../actions/user-admin.action';
@Injectable()
export class UserEffects {
    constructor(private actions$: Actions,
        private loginServices: LoginService,
        private userServices: UsuarioService,
        private perfilServices: PerfilUserService,
        private servicesMessage: MensajesAppService,
        private config: ConfigService,
        private toastr: ToastrService,
        private router: Router) {
        }
    @Effect()
    loginUser$ = this.actions$.pipe(ofType(fromUserActions.LOGIN_USER),
        map((action: fromUserActions.LoginUser) => action.payload),
        switchMap((payload) => this.loginServices.login(payload)
        .pipe(
            map(response => {
                
                if (response) {
                   
                    if (response.tipoRespuesta === TipoRespuesta.Exito) {
                        const token =  response.token;
                        const rol =  response.idRol;
                        const dslogin =  response.login;
                        const idUsuario =  response.id;
                        localStorage.sdk = token;
                        localStorage.rol = rol;
                        localStorage.dslogin = dslogin;
                        localStorage.idUsuario = idUsuario;
                        this.config.setLocal();
                        this.router.navigate(['/bienvenido'], { replaceUrl: true });

                    } else {

                       /*  const payloadCopy = payload;
                        payloadCopy.DSCorreo = payload.Correo;
                        payloadCopy.DSPassword = payload.Password;
                        
                        delete payloadCopy.Correo
                        delete payloadCopy.Password
                        console.log(payloadCopy)
                        this.loginServices.loginOld(payloadCopy).subscribe((data:any)=>{
                            const token =  data.dstoken;
                            const rol =  data.fnidRol;
                            const dslogin =  data.dslogin;
                            const idUsuario = data.pnid;
                            localStorage.sdk = token;
                            localStorage.rol = rol;
                            localStorage.dslogin = dslogin;
                            localStorage.idUsuario = idUsuario;
                            this.config.setLocal();
                            this.router.navigate(['/bienvenido'], { replaceUrl: true });
                            
                        }); */
                        
                        this.servicesMessage.messageBox(response.tipoRespuesta, response.mensaje);

                    }
                    return new fromUserActions.LoginUserSuccess(response);

                } else {
                    const MENSAJE = response.mensaje as any;
                    swal('¡Oops!', MENSAJE , 'error');
                    return new fromUserActions.LoginUserFail(response);
                }
            },
            catchError(error => {  return of(new fromUserActions.LoginUserFail(error))}  )
            )
         )
        )
    );
    @Effect()
    logOffUser$: Observable<Action> = this.actions$.pipe(
        ofType(fromUserActions.LOGOFF_USER),
        switchMap(() => this.loginServices.lofOff().pipe(
            map(response => {
                localStorage.clear();
                this.router.navigate(['/authentication/login'], { replaceUrl: true });
                return new fromUserActions.LogOffSucess();
            },  catchError(error => {  return of(new fromUserActions.LogOffFail(error)); } )
        ))

    ));
   
    @Effect()
    allUsers$: Observable<Action> = this.actions$.pipe(
        ofType(fromAdminAction.USER_LISTADO_LOAD),
        switchMap(() => this.userServices.getAllUsers().pipe(
         map(response => {
            if(response.tipoRespuesta === TipoRespuesta.Error){
                this.servicesMessage.messageBox(response.tipoRespuesta, response.mensaje);
            } 
             return new fromAdminAction.UserListadoSuccess(response);
         }, catchError(error => of(new fromAdminAction.UserListadoFail(error))))
        ))
    );
    @Effect()
    userApp$: Observable<Action> = this.actions$.pipe(
        ofType(fromUserActions.USER_APP_LOAD),
        map((action: fromUserActions.UserAppLoad) => action.payload),
        switchMap( (payload)  => this.perfilServices.getProfile(payload).pipe(
            map(response => {
                if(response.tipoRespuesta === TipoRespuesta.Error){
                    this.servicesMessage.messageBox(response.tipoRespuesta, response.mensaje);
                } 
                return new fromUserActions.UserAppSuccess(response);
            }, catchError(error =>{return of(new fromUserActions.UserAppFail(error))}))
        ))
    );

    @Effect()
    userEditApp$: Observable<Action> = this.actions$.pipe(
        ofType(fromUserActions.USER_EDIT),
        map((action: fromUserActions.UserEdit) => action.payload),
        switchMap( (payload)  => this.userServices.editUserApp(payload).pipe(
            map(response => {
                if(response.tipoRespuesta === TipoRespuesta.Exito){
                    this.servicesMessage.messageBox(response.tipoRespuesta, response.mensaje);
                } else {
                    this.servicesMessage.messageBox(response.tipoRespuesta, response.mensaje);
                }
                return new fromUserActions.UserEditSuccess(response);
            }, catchError(error =>{ return of(new fromUserActions.UserEditFail(error))}))
        ))
    );

    @Effect()
    eliminarUsuarioApp$: Observable<Action> = this.actions$.pipe(
        ofType(fromAdminAction.USER_DELETE),
        map((action: fromAdminAction.UserDelete) => action.payload),
        switchMap( (payload)  => this.userServices.eliminarUsuario(payload).pipe(
            map(response => {
                if(response.tipoRespuesta === TipoRespuesta.Exito){

                    this.toastr.success(response.mensaje);
                    return new fromAdminAction.UserDeleteSuccess(response);
                  
                } else {
                    this.toastr.error(response.mensaje);
                    return new fromAdminAction.UserDeleteFail(response);
                }
               
            }, catchError(error =>{ return of(new fromAdminAction.UserDeleteFail(error))}))
        ))
    );


}
