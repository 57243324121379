import { Component, OnInit } from '@angular/core';
import { QuizzService } from 'src/app/services/quizzSelection.services';
import { Utils } from 'src/app/shared/tools/utils.services';

@Component({
  selector: 'app-middle',
  templateUrl: './middle.component.html',
  styleUrls: ['./middle.component.css']
})
export class MiddleComponent implements OnInit {

  constructor(private serviceQuizz: QuizzService,
    private utils: Utils) { }

  ngOnInit(): void {
    this.redirectUser();
  }
  redirectUser() {
    let rol = this.serviceQuizz.getRole();
   
    switch (rol) {
      case 1:
        this.utils.sendAdmin();
        break;
      case 2:
        this.utils.sendUser();
        break;
    }
  }

}
