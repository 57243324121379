

import { ListadoUsuarioEncuestaResponse } from "src/app/shared/models/response/usuario-encuesta/listado-usuario-encuesta-response.model";
import * as fromUsuarioGrupoAction from "../actions/usuarios-grupos.action";
import { Star } from 'angular-feather/icons';
import { ListadoUsuariosResponse } from '../../shared/models/response/usuario/listado-usuarios-response.model';
import { UsuarioGet } from "src/app/shared/models/response/usuario-encuesta/usuario-get.model";

export interface UsuarioGrupoState {
  loanding: boolean;
  loaded: boolean;
  idUsuario: number;
  error: string;
  ListadoGrupo: ListadoUsuarioEncuestaResponse;
  edito: boolean;
  elimino: boolean;
  inserto: boolean;
  consulta: boolean;
  layout: Blob;
  download : boolean;
  importo: boolean;
}
export const initialState: UsuarioGrupoState = {
  loanding: false,
  loaded: false,
  idUsuario: 0,
  error: "",
  ListadoGrupo: new ListadoUsuarioEncuestaResponse(),
  edito: false,
  elimino: false,
  inserto: false,
  consulta: false,
  layout: null,
  download : false,
  importo : false
};
export function reducer(
  state = initialState,
  action: fromUsuarioGrupoAction.UsuarioGruposAction
): UsuarioGrupoState {
  switch (action.type) {
    case fromUsuarioGrupoAction.USUARIO_GRUPO_LOAD: {
      return {
        ...state,
        loanding: true,
        idUsuario: 0,
        loaded: false,
        ListadoGrupo: { ...state.ListadoGrupo },
        error: "",
        consulta: false,
        edito: false,
        elimino: false,
        inserto: false,
        layout: null,  
        download : false,
        importo : false

      };
    }
    case fromUsuarioGrupoAction.USUARIO_GRUPO_LOAD_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        loanding: false,
        loaded: true,
        idUsuario: 0,
        ListadoGrupo: payload,
        error: "",
        consulta: true,
        edito: false,
        elimino: false,
        inserto: false,
        layout: null,
        download : false,
        importo : false
      };
    }
    case fromUsuarioGrupoAction.USUARIO_GRUPO_LOAD_FAIL: {
      return {
        ...state,
      };
    }
    case fromUsuarioGrupoAction.USUARIO_GRUPO_ADD: {
      return {
        ...state,
        loanding: true,
        loaded: false,
        error: "",
        layout: null,
        download : false,
        importo : false
      };
    }
    case fromUsuarioGrupoAction.USUARIO_GRUPO_ADD_SUCCESS: {
      const payload = action.payload;
      
      let grupos = JSON.parse(JSON.stringify(state.ListadoGrupo)) as ListadoUsuarioEncuestaResponse;

      var user ={
      id : payload.id,
      idUsuarioTemporal : payload.idUsuarioTemporal,
      nombre : payload.nombre,
      paterno : payload.paterno,
      materno : payload.materno,
      nombreCompleto : payload.nombre,
      correo : payload.correo
      }
      grupos.usuariosEncuesta.push(user);
      
      return {
        ...state,
        loaded: true,
        idUsuario: payload.id,
        loanding: false,
        error: "",
        inserto: true,
        edito: false,
        elimino: false,
        consulta: false,
        layout: null,
        download : false,
        importo : false,
        ListadoGrupo : grupos
        

      };
    }
    case fromUsuarioGrupoAction.USUARIO_GRUPO_ADD_FAIL: {
      const payload = action.payload;
      return {
        ...state,
        loanding:false,
        loaded: true,
        error: payload,
        inserto: false,
        edito: false,
        elimino: false,
        consulta: false,
        layout: null,
        download : false,
        importo : false
      };
    }
    case fromUsuarioGrupoAction.USUARIO_GRUPO_EDIT: {
      return {
        ...state,
        loanding: true,
        loaded: false,
        error: "",
        inserto: false,
        edito: false,
        elimino: false,
        consulta: false,
        layout: null,
        download : false,
        importo : false,
        ListadoGrupo : state.ListadoGrupo
      };
    }
    case fromUsuarioGrupoAction.USUARIO_GRUPO_EDIT_SUCCESS: {
      const payload = action.payload;
      let grupos = JSON.parse(JSON.stringify(state.ListadoGrupo)) as ListadoUsuarioEncuestaResponse;

      var user ={
      id : payload.id,
      idUsuarioTemporal : payload.idUsuarioTemporal,
      nombre : payload.nombre,
      paterno : payload.paterno,
      materno : payload.materno,
      nombreCompleto : payload.nombre,
      correo : payload.correo
      }

      let index = grupos.usuariosEncuesta.findIndex(x=> x.id == payload.id);
      grupos.usuariosEncuesta[index] = user;

      return {
        ...state,
        loaded: true,
        idUsuario: payload.id,
        loanding: false,
        error: "",
        inserto: false,
        edito: true,
        elimino: false,
        consulta: false,
        layout: null,
        download : false,
        importo : false,
        ListadoGrupo : grupos
      };
    }
    case fromUsuarioGrupoAction.USUARIO_GRUPO_EDIT_FAIL: {
      return {
        ...state,
        inserto: false,
        edito: false,
        elimino: false,
        consulta: false,
        layout: null,
        download : false,
        importo : false
      };
    }

    case fromUsuarioGrupoAction.USUARIO_GRUPO_DELETE: {
      return {
        ...state,
        loanding: true,
        inserto: false,
        edito: false,
        elimino: false,
        consulta: false,
        layout: null,
        download : false,
        importo : false
      };
    }
    case fromUsuarioGrupoAction.USUARIO_GRUPO_DELETE_SUCCESS: {
      const payload = action.payload;
      let grupos = JSON.parse(JSON.stringify(state.ListadoGrupo)) as ListadoUsuarioEncuestaResponse;
     grupos.usuariosEncuesta =  grupos.usuariosEncuesta.filter(x=> x.id != payload.idUsuarioGrupo);
      return {
        ...state,
        loaded: true,
        idUsuario: payload.idUsuarioGrupo,
        loanding: false,
        error: "",
        inserto: false,
        edito: false,
        elimino: true,
        consulta: false,
        layout: null,
        download : false,
        importo : false,
        ListadoGrupo : grupos
      };
    }
    case fromUsuarioGrupoAction.USUARIO_GRUPO_DELETE_FAIL: {
      return {
        ...state,
      };
    }
    case fromUsuarioGrupoAction.USUARIO_GET_LAYOUT : {
        return {
            ...state,
            loanding : true,
            loaded : false,
            idUsuario : state.idUsuario,
            ListadoGrupo: state.ListadoGrupo,
            error: "",
            consulta: false,
            edito: false,
            elimino: false,
            inserto: false,
            layout: null,
            download : false,
            importo : false
          };
    }
    case fromUsuarioGrupoAction.USUARIO_GET_LAYOUT_SUCCESS : {
        const payload = action.payload;
        return {
            ...state,
            loanding : false,
            loaded : true,
            idUsuario : state.idUsuario,
            ListadoGrupo: state.ListadoGrupo,
            error: "",
            consulta: false,
            edito: false,
            elimino: false,
            inserto: false,
            layout: payload,
            download : true,
            importo : false
          };
    }
    case fromUsuarioGrupoAction.USUARIO_GET_LAYOUT_FAIL : {
        const payload = action.payload;
        return {
            ...state,
            loanding : false,
            loaded : false,
            idUsuario : state.idUsuario,
            ListadoGrupo: state.ListadoGrupo,
            error: "",
            consulta: false,
            edito: false,
            elimino: false,
            inserto: false,
            layout: null,
            download : false,
            importo : false
          };
    }
    case fromUsuarioGrupoAction.USUARIO_IMPORT_LOAD : {
      const payload = action.payload;
      return {
          ...state,
          loanding : true,
          loaded : false,
          idUsuario : state.idUsuario,
          ListadoGrupo: state.ListadoGrupo,
          error: "",
          consulta: false,
          edito: false,
          elimino: false,
          inserto: false,
          layout: null,
          download : false,
          importo : false
        };
    }
    case fromUsuarioGrupoAction.USUARIO_IMPORT_LOAD_SUCESS : {
      const payload = action.payload;
     
      return {
          ...state,
          loanding : false,
          loaded : true,
          idUsuario : state.idUsuario,
          ListadoGrupo: payload,
          error: "",
          consulta: true,
          edito: false,
          elimino: false,
          inserto: false,
          layout: null,
          download : false,
          importo : true
        };
    }
    case fromUsuarioGrupoAction.USUARIO_IMPORT_LOAD_FAIL : {
      const payload = action.payload;
      return {
          ...state,
          loanding : false,
          loaded : false,
          idUsuario : state.idUsuario,
          ListadoGrupo: state.ListadoGrupo,
          error: payload,
          consulta: false,
          edito: false,
          elimino: false,
          inserto: false,
          layout: null,
          download : false,
          importo : false
        };
    }
    
  }
}
export const getState = (state: UsuarioGrupoState) => state;
