import { switchMap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as fromEvaluacionAction from '../../actions/evaluaciones/mis-evaluaciones-action';
import { EvaluacionesService } from '../../../services/evaluaciones/evaluaciones.service';
import { MensajesAppService } from '../../../services/mensajes-app.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class EvaluacionInternaEffects {
    constructor(private actions$: Actions,
        private evaluacionesService: EvaluacionesService,
        private messageServices: MensajesAppService,
        private toastr: ToastrService) {

    }
    @Effect()
    evaluacionConsultar$: Observable<Action> = this.actions$.pipe(
        ofType(fromEvaluacionAction.EVALUACION_LOAD),
        map((action: fromEvaluacionAction.EvaluacionLoad) => action.payload),
        switchMap( (payload)  => this.evaluacionesService.getTiposEvaluacion(payload).pipe(
            map(response => {
               
                return new fromEvaluacionAction.EvaluacionLoadSuccess(response);
            }, catchError(error => of(new fromEvaluacionAction.EvaluacionLoadFail(error))))
        ))
    );

    @Effect()
    tipoSeccion$: Observable<Action> = this.actions$.pipe(
        ofType(fromEvaluacionAction.TIPOSECCION_LOAD),
        map((action: fromEvaluacionAction.TipoSeccionLoad) => action.payload),
        switchMap( (payload)  => this.evaluacionesService.getTipoSeccion(payload).pipe(
            map(response => {
               
                return new fromEvaluacionAction.TipoSeccionLoadSuccess(response);
            }, catchError(error => of(new fromEvaluacionAction.TipoSeccionLoadFail(error))))
            ))
            );

    @Effect()
    evaluacionEliminar: Observable<Action> = this.actions$.pipe(
        ofType(fromEvaluacionAction.DELETE_LOAD),
        map((action: fromEvaluacionAction.EvaluacionDelete) => action.payload),
        switchMap( (payload)  => this.evaluacionesService.eliminarEvaluacion(payload).pipe(
            map(response => {
               
                return new fromEvaluacionAction.EvaluacionDeleteSuccess(response);
            }, catchError(error => of(new fromEvaluacionAction.EvaluacionDeleteFail(error))))
        ))
    );

    @Effect()
   miembrosCuenta: Observable<Action> = this.actions$.pipe(
        ofType(fromEvaluacionAction.MIEMBROS_LOAD),
        map((action: fromEvaluacionAction.MiembroLoad) => action.payload),
        switchMap( (payload)  => this.evaluacionesService.getGruposUsuairosApp(payload).pipe(
            map(response => {
                
                return new fromEvaluacionAction.MiembroSuccess(response);
            }, catchError(error => of(new fromEvaluacionAction.MiembroFail(error))))
        ))
    );
}
