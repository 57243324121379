
import { DetalleEncuesta } from 'src/app/shared/models/response/encuestas/detalle-encuesta.model';
import * as fromEncuestaInterna from '../actions/encuestas-internas.action';
export interface DetalleEncuestaInternaState {
    loanding: boolean;
    loaded: boolean;
    detalleEncuesta: DetalleEncuesta;
    error: any;
}

export const initialState: DetalleEncuestaInternaState = {
    loanding: false,
    loaded : false,
    detalleEncuesta : new DetalleEncuesta(),
    error : ''
}


export function reducer(state = initialState, action: fromEncuestaInterna.EncuestaInternaAction ): DetalleEncuestaInternaState {
    switch (action.type) {
        case fromEncuestaInterna.ENCUESTA_DETALLE_LOAD: {
            return {
                ...state,
                loanding: true,
                loaded : false,
                detalleEncuesta :  new DetalleEncuesta(),
                error: ''
            };
        }
        case fromEncuestaInterna.ENCUESTA_DETALLE_LOAD_SUCCESS: {
            const detalleEncuesta = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                detalleEncuesta,
                error : ''
            };
        }
        case fromEncuestaInterna.ENCUESTA_DETALLE_LOAD_FAIL : {
            const data = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                detalleEncuesta : new DetalleEncuesta(),
                error : data
            };

        }
    }
}

export const getState = (state: DetalleEncuestaInternaState) => state;
