export enum EnumTipoRespuestas {
    CARITAS = 1,
    OPCION_MULTIPLE ,
    SI_NO ,
    ABIERTAS,
    ESTRELLAS ,
    OPCION_SIMPLE,
    DATOS_GENERALES,
    CAT_COLONIAS
}
