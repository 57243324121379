
import { UsuarioResponse } from 'src/app/shared/models/response/usuario/loginResponse.model';
import * as fromUserAction from '../actions/user.action';

export interface LoginState
{
    id: number;
    nombre: string;
    puesto: string;
    logueado: boolean;
    error: string;
    user:  UsuarioResponse;
    loanding : boolean;
}

export const initialState: LoginState = {
    id: 0,
    nombre : '',
    puesto : '',
    logueado : false,
    error: '',
    user : new UsuarioResponse(),
    loanding : false
   };
   export function reducer(state = initialState, action: fromUserAction.UserActions ): LoginState {
    switch (action.type) {
        case fromUserAction.LOGIN_USER: {
            return {
                ...state,
                logueado: false,
                id: 0,
                nombre: '',
                puesto : '',
                user: new UsuarioResponse(),
                loanding : true
            };
        }
        case fromUserAction.LOGIN_USER_SUCCESS: {
            const user = action.payload;
            return {
                ...state,
                logueado: true,
                id: user.id,
                nombre : user.nombreCompleto,
                puesto : user.cargo,
                user: user,
                loanding : false
            };

        }
        case fromUserAction.LOGIN_CURRENT_STATE : {

            return {
                ...state,
                
            }
        }

        case fromUserAction.LOGIN_USER_FAIL: {
            const data = action.payload;
            return {
                ...state,
                logueado: false,
                id: 0,
                nombre: '',
                puesto : '',
                user: new UsuarioResponse(),
                loanding : false
            };

        }
     }
   }

export const getState = (state: LoginState) => state;
export const getUser = ( state: LoginState) => state.user;

