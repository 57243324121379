import { ResponseBase } from '../response-base.model';
import { SeccionesQuizz } from './secciones-quizz';
import { Sucursal } from './sucursal';
export class QuizzResponse extends ResponseBase {
    idEncuesta: number;
    nombre: string;
    codigo: string;
    preview?: boolean;
    usuario: string;
    idMiembro: number;
    idTipoEncuesta: number;
    anonimo: boolean;
    secciones: SeccionesQuizz[] = [];
    respondioEncuesta: boolean;
    sucursales : Sucursal[] = [];
    logo: string;
    fileTerms: string;
    idSucursal : number;
    respondio : boolean;
    urlMiUCL : string;
    idUsuarioMcl? : number;
    niss: string;
    origen: string;
}
