import { ResponseBase } from '../response-base.model';
export class PerfilUsuario extends ResponseBase {
    id: number;
    idUsuario?: number;
    nombre: string;
    apellidoPaterno: string;
    apellidoMaterno: string;
    correo: string;
    habilitado: boolean;
    usuario: string;
    cargo: string = "";
    avatar: string;
    password: string;
    idRol: number;
    codigo : string;
  
}
