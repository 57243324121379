import { ResponseBase } from '../../response-base.model';
export class DashBoardEvalGeneralResponse extends ResponseBase {
    total: number;
    activas: number;
    inactivas: number;
    labelsTipoEncuesta: string [] = [];
    labelsVisitas: string [] = [];
    dataChatTipoEncuesta : number[] = [];
  
    dataChatVisitas : number[] = [];

    
}
