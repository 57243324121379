import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { MensajesAppService } from 'src/app/services/mensajes-app.service';
import * as fromWizardAction from '../actions/wizard-encuesta-action';
import { EncuestaInternaService } from 'src/app/services/encuestas/encuesta-interna.service';
import { ToastrService } from 'ngx-toastr';
import { TipoRespuesta } from 'src/app/shared/models/enums/enumTipoRespuesta.model';


@Injectable()
export class WizardEffects {
    constructor(private actions$: Actions,
                private encuestaServices: EncuestaInternaService,
                private messageServices: MensajesAppService,
                private toastr: ToastrService) {
        }
        @Effect()
        registraEncuesta$: Observable<Action> = this.actions$.pipe(
            ofType(fromWizardAction.REGISTRA_ENCUESTA_SERVER),
            map((action: fromWizardAction.RegistraEncuestaServer) => action.payload),
            switchMap( (payload)  => this.encuestaServices.sendEncuesta(payload).pipe(
                map(response => {
                  
                    if(response.tipoRespuesta == TipoRespuesta.Exito){
                       
                       /*  if(!response.salvoEncuesta){
                            this.messageServices.messageBox(response.tipoRespuesta, response.mensaje);
                            return new fromWizardAction.RegistraEncuestaServerFail(response);
                        } else {
                           
                        } */
                        this.toastr.success(response.mensaje);
                        return new fromWizardAction.RegistraEncuestaServerSuccess(response);
                       
                    } else {
                       // this.messageServices.messageBox(response.tipoRespuesta, response.mensaje);
                       this.toastr.warning(response.mensaje);
                        return new fromWizardAction.RegistraEncuestaServerFail(response);
                    }
                   
                }, catchError(error => of(new fromWizardAction.RegistraEncuestaServerFail(error))))
            ))
        );
        @Effect()
        wizardDetalle$: Observable<Action> = this.actions$.pipe(
            ofType(fromWizardAction.GET_ENCUESTA_WIZARD),
            map((action: fromWizardAction.GetEncuestaWizard) => action.payload),
            switchMap( (payload)  => this.encuestaServices.getEncuestaWizard(payload).pipe(
                map(response => {
                  
                    if(response.tipoRespuesta == TipoRespuesta.Exito){
                        return new fromWizardAction.GetEncuestaWizardSuccess(response);
                    } else {
                        this.messageServices.messageBox(response.tipoRespuesta, response.mensaje);
                        return new fromWizardAction.GetEncuestaWizardFail(response);
                    }
                   
                }, catchError(error => of(new fromWizardAction.GetEncuestaWizardFail(error))))
            ))
        );
        @Effect()
        wizardPublicar$: Observable<Action> = this.actions$.pipe(
            ofType(fromWizardAction.PUBLICA_ENCUESTA_WIZARD_LOAD),
            map((action: fromWizardAction.PublicaEncuestaLoad) => action.payload),
            switchMap( (payload)  => this.encuestaServices.publicaEncuesta(payload).pipe(
                map(response => {
                  
                    if(response.tipoRespuesta == TipoRespuesta.Exito){
                        return new fromWizardAction.PublicaEncuestaSuccess(response);
                    } else {
                        return new fromWizardAction.PublicaEncuestaFail(response);
                    }
                   
                }, catchError(error => of(new fromWizardAction.PublicaEncuestaFail(error))))
            ))
        );
}