// action menu selected user

import { Action } from "@ngrx/store";
import { SidebarNavbar } from "src/app/shared/models/sidebar-navbar";


export const USER_SELECT_OPTION = '[User] Select Option';
export const USER_SELECT_OPTION_SUCCESS = '[User] Select Option Success';
export const USER_SELECT_OPTION_FAIL = '[User] Select Option Fail';

export class UserSelectOption implements Action {
    readonly type = USER_SELECT_OPTION;
    constructor(public payload: SidebarNavbar ) { }
}

export class UserSelectOptionSuccess implements Action {
    readonly type = USER_SELECT_OPTION_SUCCESS;
    constructor(public payload: SidebarNavbar ) { }
}

export class UserSelectOptionSuccessFail implements Action {
    readonly type = USER_SELECT_OPTION_FAIL;
    constructor(public payload: any) { }
}

export type OptionMenuActions = UserSelectOption | UserSelectOptionSuccess |  UserSelectOptionSuccessFail;

