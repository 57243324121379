
import { ConfiguracionBasica } from 'src/app/shared/models/response/encuestas/configuracion-basica';
import { EncuestaGrupos } from 'src/app/shared/models/response/encuestas/encuesta-grupos.model';
import * as fromActionConfiguracion from '../actions/configuracion-encuesta-action';
export interface ConfiguracionState {
    loanding: boolean;
    loaded: boolean;
    error: any;
    nombreEncuesta: string;
    basica: ConfiguracionBasica;
    optionSelected: number;
    selectMenu: boolean;
    gruposConfigurados: EncuestaGrupos[];
    idEncueta: number;
    consultaGrupos: boolean;
}

export const initialState: ConfiguracionState = {
    loanding: false,
    loaded: false,
    basica: new ConfiguracionBasica(),
    error: '',
    nombreEncuesta: '',
    optionSelected: 0,
    selectMenu: false,
    gruposConfigurados: [],
    idEncueta : 0,
    consultaGrupos : false
};


export function reducer(state = initialState, action: fromActionConfiguracion.ConfiguracionAction): ConfiguracionState {
    switch (action.type) {
        case fromActionConfiguracion.GET_CONFIGURACION_BASICA_LOAD: {
            const payload = action.payload;
            return {
                ...state,
                loanding: true,
                error: '',
                optionSelected: state.optionSelected,
                nombreEncuesta: '',
                selectMenu: false,
                gruposConfigurados: [],
                idEncueta : state.idEncueta,
                consultaGrupos : false
            };
        }
        case fromActionConfiguracion.GET_CONFIGURACION_BASICA_LOAD_SUCCESS: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                basica: payload.configuracionBasica,
                nombreEncuesta: payload.configuracionBasica.nombreEncuesta,
                optionSelected: state.optionSelected,
                selectMenu: false,
                gruposConfigurados: [],
                idEncueta : state.idEncueta,
                consultaGrupos : false
            };
        }
        case fromActionConfiguracion.GET_CONFIGURACION_BASICA_LOAD_FAIL: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                error: payload,
                basica: { ...state.basica },
                optionSelected: state.optionSelected,
                nombreEncuesta: state.nombreEncuesta,
                selectMenu: false,
                gruposConfigurados: [],
                idEncueta : state.idEncueta,
                consultaGrupos : false
            };
        }
        case fromActionConfiguracion.GET_CONFIGURACION_GRUPOS: {
            return {
                ...state,
                loanding: true,
                loaded: false,
                error: '',
                basica: { ...state.basica },
                optionSelected: state.optionSelected,
                selectMenu: false,
                gruposConfigurados: [...state.gruposConfigurados],
                nombreEncuesta: '',
                idEncueta : state.idEncueta,
                consultaGrupos : false
            };

        }
        case fromActionConfiguracion.GET_CONFIGURACION_GRUPOS_SUCCESS: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                error: '',
                basica: { ...state.basica },
                optionSelected: state.optionSelected,
                selectMenu: false,
                gruposConfigurados: payload.grupos,
                nombreEncuesta: payload.encuesta,
                idEncueta : payload.idEncuesta,
                consultaGrupos : true
            };

        }
        case fromActionConfiguracion.SEND_CONFIGURACION_BASICA: {
            return {
                ...state,
                loanding: true,
                loaded: false,
                error: '',
                basica: { ...state.basica },
                optionSelected: state.optionSelected,
                selectMenu: false,
                gruposConfigurados: [...state.gruposConfigurados],
                nombreEncuesta: '',
                idEncueta : state.idEncueta,
                consultaGrupos : false
            };

        }
        case fromActionConfiguracion.SEND_CONFIGURACION_BASICA_SUCCESS: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                error: '',
                basica: payload.configuracionBasica,
                optionSelected: state.optionSelected,
                selectMenu: false,
                gruposConfigurados: [...state.gruposConfigurados],
                nombreEncuesta: '',
                idEncueta : state.idEncueta,
                consultaGrupos : false
            };

        }
        case fromActionConfiguracion.SEND_CONFIGURACION_BASICA_FAIL: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                error: payload,
                basica: { ...state.basica },
                optionSelected: state.optionSelected,
                selectMenu: false,
                gruposConfigurados: [...state.gruposConfigurados],
                nombreEncuesta: '',
                idEncueta : state.idEncueta,
                consultaGrupos : false
            };

        }
        case fromActionConfiguracion.SEND_CONFIGURACION_GRUPOS: {
            return {
                ...state,
                loanding: true,
                loaded: false,
                error: '',
                basica: { ...state.basica },
                optionSelected: state.optionSelected,
                selectMenu: false,
                gruposConfigurados: [...state.gruposConfigurados],
                nombreEncuesta: '',
                idEncueta : state.idEncueta,
                consultaGrupos : false
            };

        }
        case fromActionConfiguracion.SEND_CONFIGURACION_GRUPOS_SUCCESS: {
            return {
                ...state,
                loanding: false,
                loaded: true,
                error: '',
                basica: { ...state.basica },
                optionSelected: state.optionSelected,
                selectMenu: false,
                gruposConfigurados: [...state.gruposConfigurados],
                nombreEncuesta: '',
                idEncueta : state.idEncueta,
                consultaGrupos : false
            };

        }
        case fromActionConfiguracion.SEND_CONFIGURACION_GRUPOS_FAIL: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                error: payload,
                basica: { ...state.basica },
                optionSelected: state.optionSelected,
                selectMenu: false,
                gruposConfigurados: [...state.gruposConfigurados],
                nombreEncuesta: '',
                idEncueta : state.idEncueta,
                consultaGrupos : false
            };

        }
        case fromActionConfiguracion.GET_CONFIGURACION_GRUPOS_FAIL: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                error: payload,
                basica: { ...state.basica },
                optionSelected: state.optionSelected,
                selectMenu: false,
                gruposConfigurados: [...state.gruposConfigurados],
                nombreEncuesta: state.nombreEncuesta,
                idEncueta : state.idEncueta,
                consultaGrupos : false
            };

        }

        case fromActionConfiguracion.OPTION_SELECTED: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                error: '',
                basica: { ...state.basica },
                optionSelected: payload,
                selectMenu: true,
                nombreEncuesta: '',
                idEncueta : state.idEncueta,
                consultaGrupos : false
            };
        }
        case fromActionConfiguracion.SELECCIONA_GRUPO: {
            const payload = action.payload;
            const grupos = state.gruposConfigurados.map(g => ({ ...g }));
            const grupo = grupos.find(g => g.idGrupo === payload.idGrupo);
            grupo.seleccionado = !grupo.seleccionado;
            return {
                ...state,
                loanding: false,
                loaded: true,
                error: '',
                basica: { ...state.basica },
                optionSelected: 2,
                selectMenu: false,
                gruposConfigurados: grupos,
                nombreEncuesta: state.nombreEncuesta,
                idEncueta : state.idEncueta,
                consultaGrupos : false
            };


        }
        default:
            return {
                ...state
            }
            break;
    }
}
export const getState = (state: ConfiguracionState) => state;