import { ResponseBase } from '../../response-base.model';
import { SeccionesAnalisis } from './secciones-analisis';
export class AnalisisResultadosResponse extends ResponseBase {
    idEncuesta: number;
    codigo: string;
    encuesta : string;
    logo : string;
    totalRespuestas : number;
    habilitado : boolean
    secciones: SeccionesAnalisis[] = [];
    resultados : boolean;
}
