import { Action } from '@ngrx/store';
import { AsignaGrupoRequest } from 'src/app/shared/models/request/asigna-grupo/asigna-grupo-request.model';
import { Detallegrupo } from 'src/app/shared/models/request/grupo/detallegrupo.model';
import { GrupoRequest } from 'src/app/shared/models/request/grupo/grupo-request.model';
import { AltaGrupoResponse } from 'src/app/shared/models/response/grupo/alta-grupo-response';
import { ListadoGrupoResponse } from 'src/app/shared/models/response/grupo/listado-grupo-response';
import { UsuariosAppDetalle } from 'src/app/shared/models/response/grupo/usuarios-app';
import { MiembrosResponse } from 'src/app/shared/models/response/miembros-response.model';
import { ResponseBase } from 'src/app/shared/models/response/response-base.model';


export const GET_MIEMBROS_GRUPOS = '[Get Miembros Grupo]';
export const GET_MIEMBROS_GRUPOS_SUCCESS = '[Get Miembros]  Success';
export const GET_MIEMBROS_GRUPOS_FAIL = '[Get Miembros]  Fail';

export class GetMiembros implements Action {
    readonly type = GET_MIEMBROS_GRUPOS;
    constructor(public payload: Detallegrupo) { }
}

export class GetMiembrosSucess implements Action {
    readonly type = GET_MIEMBROS_GRUPOS_SUCCESS;
    constructor(public payload: MiembrosResponse) { }
}

export class GetMiembrosFail implements Action {
    readonly type = GET_MIEMBROS_GRUPOS_FAIL;
    constructor(public payload: any) { }
}


export const EDITA_GRUPO = '[Edit Grupo]';
export const EDITA_GRUPO_SUCCESS = '[Edit Grupo]  Success';
export const EDITA_GRUPO_FAIL = '[Edit Grupo]  Fail';

export class EditaGrupo implements Action {
    readonly type = EDITA_GRUPO;
    constructor(public payload: GrupoRequest) { }
}

export class EditaGrupoSuccess implements Action {
    readonly type = EDITA_GRUPO_SUCCESS;
    constructor(public payload: ListadoGrupoResponse) { }
}

export class EditaGrupoFail implements Action {
    readonly type = EDITA_GRUPO_FAIL;
    constructor(public payload: any) { }
}


export const ADD_MIEMBRO = '[MIEMBRO] ADD';
export const REMOVE_MIEMBRO = '[MIEMBRO] REMOVE';

export class AddMiembro implements Action {
    readonly type = ADD_MIEMBRO;
    constructor(public payload: UsuariosAppDetalle) { }
}

export class RemoveMiembro implements Action {
    readonly type = REMOVE_MIEMBRO;
    constructor(public payload: UsuariosAppDetalle) { }
}


export const DELETE_GRUPO = '[GRUPO] Delete';
export const DELETE_GRUPO_SUCCESS = '[GRUPO] Delete Success';
export const DELETE_GRUPO_FAIL = '[GRUPO] Delete Fail';

export class DeleteGrupo implements Action {
    readonly type = DELETE_GRUPO;
    constructor(public payload: Detallegrupo) { }
}

export class DeleteGrupoSuccess implements Action {
    readonly type = DELETE_GRUPO_SUCCESS;
    constructor(public payload: ListadoGrupoResponse) { }
}

export class DeleteGrupoFail implements Action {
    readonly type = DELETE_GRUPO_FAIL;
    constructor(public payload: any) { }
}


export const ASIGNA_GRUPOS_LOAD = '[Asigna Grupo] Load';
export const ASIGNA_GRUPOS_LOAD_SUCCESS = '[Asigna Grupo] Load Success';
export const ASIGNA_GRUPOS_LOAD_FAIL = '[Asigna Grupo] Load Fail';

export class AsignaGrupoLoad implements Action {
    readonly type = ASIGNA_GRUPOS_LOAD;
    constructor(public payload: AsignaGrupoRequest[]) { }
}

export class AsignaGrupoSuccess implements Action {
    readonly type = ASIGNA_GRUPOS_LOAD_SUCCESS;
    constructor(public payload: AltaGrupoResponse) { }
}

export class AsignaGrupoFail implements Action {
    readonly type = ASIGNA_GRUPOS_LOAD_FAIL;
    constructor(public payload: any) { }
}

export const DELETE_MIEMBRO_ASIGNADO = '[Delete Miembro Asignado]';
export const DELETE_MIEMBRO_ASIGNADO_SUCCESS = '[Delete Miembro Asignado] Success';
export const DELETE_MIEMBRO_ASIGNADO_FAIL = '[Delete Miembro Asignado] Fail';

export class DeleteMiembroAsignado implements Action {
    readonly type = DELETE_MIEMBRO_ASIGNADO;
    constructor(public payload: number) { }
}

export class DeleteMiembroAsignadoSuccess implements Action {
    readonly type = DELETE_MIEMBRO_ASIGNADO_SUCCESS;
    constructor(public payload: ResponseBase) { }
}

export class DeleteMiembroAsignadoFail implements Action {
    readonly type = DELETE_MIEMBRO_ASIGNADO_FAIL;
    constructor(public payload: any) { }
}




export type DetalleGrupoAction = AsignaGrupoLoad |
AsignaGrupoSuccess|
AsignaGrupoFail |
GetMiembros |
GetMiembrosSucess |
GetMiembrosFail |
EditaGrupo |
EditaGrupoSuccess |
EditaGrupoFail |
AddMiembro |
RemoveMiembro |
DeleteGrupo |
DeleteGrupoSuccess |
DeleteGrupoFail |
DeleteMiembroAsignado |
DeleteMiembroAsignadoSuccess |
DeleteMiembroAsignadoFail ;
