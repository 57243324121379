import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/config/config.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../error-handler.service';
import { catchError } from 'rxjs/operators';
import { Departamento } from 'src/app/shared/models/response/departamentos/departamento.model';


@Injectable({
    providedIn: 'root'
  })

  export class DepartamentoServices {
    headers: any;
    constructor(private config: ConfigService,
                private http: HttpClient,
                private servicesError: ErrorHandlerService, ) {
                this.config.headers.subscribe((headers) => {
        if (headers) {
          this.headers = headers;
        }
      });
     }

     getDepartamentos(): Observable<Departamento[]> {
        return this.http.get<Departamento[]>(`${environment.baseUrl}04/01`, { headers: this.headers })
        .pipe(catchError(this.servicesError.erroHandler));
     }
  }
