
import { EnumEstatusEV } from 'src/app/shared/models/enums/evaluaciones/enum-estatus-ev.model';
import { PreguntasEV } from 'src/app/shared/models/response/evaluaciones/preguntas-ev.model';
import { SeccionesWizardEV } from 'src/app/shared/models/response/evaluaciones/wizard/secciones-wizard-ev';
import * as fromWizarEVAction from '../../actions/evaluaciones/wizard-evaluaciones-action';
import { UsuarioGet } from '../../../shared/models/response/usuario-encuesta/usuario-get.model';
import { EncuestaSimple } from 'src/app/shared/models/response/evaluaciones/wizard/encuesta-simple.model';
import { number } from 'ngx-custom-validators/src/app/number/validator';


export interface WizardEVState {
    id: number;
    nombre: string;
    descripcion: string;
    ponderacion: number;
    idTipoEncuesta: number;
    idEstatusEncuesta: number;
    loaded: boolean;
    loading: boolean;
    inserta: boolean;
    edita: boolean;
    secciones: SeccionesWizardEV[];
    seccion: boolean,
    indexSeccion: number;
    addPregunta: boolean;
    deletePregunta : boolean;
    editoPregunta : boolean;
    editPregunta: boolean;
    error: string;
    preguntaEdit: PreguntasEV;
    logo: string;
    fileTerms: string;
    sendServer: boolean;
    saveServer: boolean;
    consulto: boolean,
    asigna: boolean,
    publicada : boolean,
    editoState : boolean,
    codigoEncuesta : string,
    failSave : boolean,
    responsables : UsuarioGet [],
    encuestas : EncuestaSimple [],
    addSeccion : boolean
}
export const initialState: WizardEVState = {
    id: 0,
    nombre: '',
    descripcion: '',
    ponderacion: 100,
    idTipoEncuesta: 0,
    idEstatusEncuesta : 0,
    loaded: false,
    loading: false,
    inserta: false,
    edita: false,
    secciones: [],
    seccion: false,
    indexSeccion: undefined,
    addPregunta: false,
    deletePregunta :false,
    editPregunta: false,
    editoPregunta : false,
    preguntaEdit: new PreguntasEV(),
    error: '',
    logo: '',
    fileTerms: '',
    sendServer: false,
    saveServer: false,
    consulto: false,
    asigna: false,
    publicada : false,
    editoState : false,
    codigoEncuesta : '',
    failSave : false,
    responsables : [],
    encuestas:[],
    addSeccion : false

};
export function reducer(state = initialState, action: fromWizarEVAction.WizardEvaluacionAction): WizardEVState {
    switch (action.type) {

        case fromWizarEVAction.SECCION_EVALUACION_ADD: {

            const payload = { ...action.payload };
            let currentIndex = state.indexSeccion == undefined ? 0 : state.indexSeccion;
            payload.nombre = action.payload.nombre;
            payload.ponderacion = action.payload.ponderacion;
            payload.idTipoSeccion = action.payload.idTipoSeccion;
            payload.index = state.secciones.length;
            payload.encuestaLigada = action.payload.encuestaLigada;
            let sumaSecciones = 0;
            if(state.secciones.length > 0){
                state.secciones.forEach((item) => {
                    sumaSecciones = (sumaSecciones + item.ponderacion);
                });
            }
            sumaSecciones = (sumaSecciones + payload.ponderacion);
            
            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                editPregunta: false,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                secciones: [...state.secciones, payload],
                ponderacion: (100 - sumaSecciones),
                error: '',
                indexSeccion: currentIndex,
                addPregunta: false,
                deletePregunta: false,
                editoPregunta : false,
                preguntaEdit: null,
                logo: state.logo,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                publicada : false,
                editoState : payload.agregoUsuario,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : state.responsables,
                encuestas: state.encuestas,
                addSeccion : true

            };

        };
        case fromWizarEVAction.RESET_EVALUACION: {
            return {
                ...state,
                id: 0,
                nombre: '',
                descripcion: '',
                ponderacion: 100,
                editPregunta: false,
                idTipoEncuesta: 0,
                loaded: true,
                loading: false,
                inserta: false,
                idEstatusEncuesta : undefined,
                edita: false,
                secciones: [],
                seccion: false,
                indexSeccion: undefined,
                error: '',
                addPregunta: false,
                deletePregunta: false,
                editoPregunta : false,
                preguntaEdit: null,
                logo: '',
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                publicada : false,
                editoState: false,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : state.responsables,
                encuestas: state.encuestas, 
                addSeccion : false

            }
        }
        case fromWizarEVAction.SECCION_EVALUACION_SELECTED: {
            const payload = action.payload;
        
            let secciones = [...state.secciones];


            for (let i = 0; i < secciones.length; i++) {
                if (i == payload) {
                    let item = { ...secciones[payload] };
                    item.selected = !item.selected;
                    secciones.splice(payload, 1, item);
                } else {
                    let itemNew = { ...secciones[i] };
                    itemNew.selected = false;
                    secciones.splice(i, 1, itemNew);
                }
            }

            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                editPregunta: false,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                idTipoEncuesta: state.idTipoEncuesta,
                secciones: secciones,
                error: '',
                indexSeccion: payload,
                preguntaEdit: null,
                logo: state.logo,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                addPregunta: false,
                deletePregunta: false,
                editoPregunta : false,
                publicada : false,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : state.responsables,
                encuestas: state.encuestas,
                addSeccion : false

            };

        }
        case fromWizarEVAction.SET_DATOS_GENERALES_EV: {
            const payload = action.payload;
            let editoState = false;
            if(state.nombre.trim() != payload.nombre.trim()){
                editoState = true;
            } else if(editoState == false) {
                editoState = state.editoState
            }
            if(state.descripcion.trim() != payload.descripcion.trim()){
                editoState = true;
            } else if(editoState == false) {
                editoState = state.editoState
            }
            if(state.logo.trim() != payload.foto.trim()){
                editoState = true;
            } else if(editoState == false) {
                editoState = state.editoState
            }

            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: state.seccion,
                inserta: payload.id == 0 ? true : false,
                edita: payload.id > 0 ? true : false,
                id: state.id,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: payload.nombre,
                descripcion: payload.descripcion,
                idTipoEncuesta: payload.idTipoEvaluacion,
                secciones: state.secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: null,
                logo: payload.foto,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                deletePregunta :false,
                editoPregunta : false,
                publicada : false,
                editoState : editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : state.responsables,
                encuestas: state.encuestas,
                addSeccion : false

            };

        }
        case fromWizarEVAction.PREGUNTA_ADD_EV: {
            const payload = { ...action.payload };
            let secciones = JSON.parse(JSON.stringify([...state.secciones])) as SeccionesWizardEV[];
            let preguntas: PreguntasEV[] = [];
            if (secciones[state.indexSeccion].preguntas.length > 0) {
                payload.idPregunta = 0;
                payload.indexPregunta = secciones[state.indexSeccion].preguntas.length;
                preguntas = [...secciones[state.indexSeccion].preguntas, payload];
            } else {
                const preguntaParse: any = {
                    id: 0,
                    indexPregunta: 0,
                    indexSeccion: state.indexSeccion,
                    descripcion: payload.descripcion,
                    idSeccion: payload.idSeccion,
                    idRespuesta: payload.idRespuesta,
                    tipoRespuesta: payload.tipoRespuesta,
                    respuestas: payload.respuestas,
                    solImage: payload.solImage,
                    solComent: payload.solComent,
                    ponderacion: payload.ponderacion,
                    objeto: payload.objeto,
                    opciones: payload.opciones,
                    correctas: payload.correctas,
                    comentarios: payload.comentarios,
                    responsables : payload.responsables,
                    abiertas : payload.abiertas
                }

                preguntas = [preguntaParse];
            }
            

            secciones[state.indexSeccion].preguntas = preguntas;


            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                idTipoEncuesta: state.idTipoEncuesta,
                secciones: secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: true,
                editPregunta: false,
                preguntaEdit: null,
                logo: state.logo,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                deletePregunta : false,
                editoPregunta : false,
                publicada : false,
                editoState : true,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : state.responsables,
                encuestas: state.encuestas,
                addSeccion : false


            };
        }
        case fromWizarEVAction.PREGUNTA_DELETE_EV: {
            const payload = action.payload;
            let secciones = JSON.parse(JSON.stringify([...state.secciones])) as SeccionesWizardEV[];

            secciones[state.indexSeccion].preguntas = secciones[state.indexSeccion].preguntas.filter(x => x.indexPregunta !== payload.indexPregunta);
            let preguntas =  JSON.parse(JSON.stringify(secciones[state.indexSeccion].preguntas)) as PreguntasEV[];

            for(let i = 0; i < preguntas.length; i++){
                preguntas[i].indexPregunta = i;
            }
            secciones[state.indexSeccion].preguntas = preguntas;
            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                idTipoEncuesta: state.idTipoEncuesta,
                secciones: secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: null,
                logo: state.logo,
                saveServer: false,
                consulto: false,
                asigna : false,
                deletePregunta : true,
                editoPregunta : false,
                publicada : false,
                editoState : true,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : state.responsables,
                encuestas: state.encuestas,
                addSeccion : false
            };
        }

        case fromWizarEVAction.PREGUNTA_COPIAR_EV: {
            let payload = { ...action.payload };
            let secciones = JSON.parse(JSON.stringify([...state.secciones])) as SeccionesWizardEV[];
            payload.indexPregunta = secciones[state.indexSeccion].preguntas.length;
            payload.idPregunta = 0;
            
            secciones[state.indexSeccion].preguntas = [...secciones[state.indexSeccion].preguntas, payload]
            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                nombre: state.nombre,
                descripcion: state.descripcion,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                secciones: secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: true,
                editPregunta: false,
                preguntaEdit: null,
                logo: state.logo,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                deletePregunta : false,
                editoPregunta : false,
                publicada : false,
                editoState : true,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : state.responsables,
                encuestas: state.encuestas,
                addSeccion : false
            };

        }
        case fromWizarEVAction.SELECT_EDIT_EV: {
            let payload = action.payload;
            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                nombre: state.nombre,
                descripcion: state.descripcion,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                secciones: state.secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                editPregunta: true,
                preguntaEdit: payload,
                logo: state.logo,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                deletePregunta : false,
                editoPregunta : false,
                publicada : false,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : state.responsables,
                encuestas: state.encuestas,
                addSeccion : false


            };
        }

        case fromWizarEVAction.CLOSE_EDIT_EV: {
            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                nombre: state.nombre,
                descripcion: state.descripcion,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                secciones: state.secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: undefined,
                logo: state.logo,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                deletePregunta : false,
                editoPregunta : false,
                publicada : false,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : state.responsables,
                encuestas: state.encuestas,
                addSeccion : false
            };
        }
        case fromWizarEVAction.EDIT_PREGUNTA_EV: {
            let payload = JSON.parse(JSON.stringify(action.payload));
            let secciones = JSON.parse(JSON.stringify([...state.secciones])) as SeccionesWizardEV[];
            let pregunta =  secciones[state.indexSeccion].preguntas[payload.indexPregunta] as PreguntasEV;

            pregunta.descripcion = payload.descripcion;
            pregunta.tipoRespuesta = payload.tipoRespuesta;
            pregunta.respuestas = payload.respuestas;
            pregunta.solImage = payload.solImage;
            pregunta.solComent = payload.solComent;
            pregunta.ponderacion = payload.ponderacion;
            pregunta.opciones = payload.opciones;
            pregunta.correctas = payload.correctas;
            pregunta.objeto = payload.objeto;
            pregunta.comentarios = payload.comentarios;
            pregunta.responsables = payload.responsables;

            secciones[state.indexSeccion].preguntas[payload.indexPregunta] = pregunta;

            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                nombre: state.nombre,
                idEstatusEncuesta : state.idEstatusEncuesta,
                descripcion: state.descripcion,
                idTipoEncuesta: state.idTipoEncuesta,
                secciones: secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: payload,
                logo: state.logo,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                deletePregunta :false,
                editoPregunta : true,
                publicada : false,
                editoState : true,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                encuestas: state.encuestas,
                addSeccion : false
            };
        }
        case fromWizarEVAction.CREAR_EVALUACION: {
            return {
                ...state,
                loading: false,
                loaded: false,
                seccion: true,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                nombre: state.nombre,
                descripcion: state.descripcion,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                secciones: state.secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: undefined,
                logo: state.logo,
                sendServer: true,
                saveServer: false,
                consulto: false,
                asigna : false,
                deletePregunta : false,
                editoPregunta : false,
                publicada : false,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : state.responsables,
                encuestas: state.encuestas,
                addSeccion : false
            }
        }
        case fromWizarEVAction.REGISTRA_EVALUACION_SERVER: {
            return {
                ...state,
                loading: true,
                loaded: false,
                seccion: true,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                nombre: state.nombre,
                descripcion: state.descripcion,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                secciones: state.secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: undefined,
                logo: state.logo,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                deletePregunta : false,
                editoPregunta : false,
                publicada : false,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : state.responsables,
                encuestas: state.encuestas,
                addSeccion : false
            };
        }
        case fromWizarEVAction.REGISTRA_EVALUACION_SERVER_SUCCESS: {
            let payload = action.payload;
            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                inserta: false,
                edita: true,
                id: payload.id,
                nombre: payload.nombre,
                idEstatusEncuesta : payload.idEstatus ,
                descripcion: payload.descripcion,
                idTipoEncuesta: payload.idTipoEncuesta,
                secciones: payload.secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: undefined,
                logo: payload.logo,
                sendServer: false,
                saveServer: true,
                consulto: false,
                asigna : false,
                deletePregunta : false,
                editoPregunta : false,
                publicada : false,
                editoState : false,
                codigoEncuesta : payload.codigo,
                failSave : false,
                responsables : state.responsables,
                encuestas: state.encuestas,
                addSeccion : false
            };
        }
        case fromWizarEVAction.REGISTRA_EVALUACION_SERVER_FAIL: {
            let payload = action.payload;
            return {
                ...state,
                loading: false,
                loaded: false,
                seccion: true,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                nombre: state.nombre,
                idEstatusEncuesta : state.idEstatusEncuesta,
                descripcion: state.descripcion,
                idTipoEncuesta: state.idTipoEncuesta,
                secciones: state.secciones,
                error: payload,
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: undefined,
                logo: state.logo,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                deletePregunta : false,
                editoPregunta : false,
                publicada : false,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : true,
                responsables : state.responsables,
                encuestas: state.encuestas,
                addSeccion : false
            };

        }
        case fromWizarEVAction.GET_EVALUACION_WIZARD: {
            return {
                ...state,
                loading: true,
                loaded: false,
                id: 0,
                nombre: '',
                descripcion: '',
                seccion: false,
                inserta: false,
                edita: false,
                idTipoEncuesta: 0,
                secciones: [],
                error: '',
                indexSeccion: 0,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: undefined,
                logo: '',
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                deletePregunta : false,
                editoPregunta : false,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : state.responsables,
                encuestas: state.encuestas,
                addSeccion : false
            }
        }
        case fromWizarEVAction.GET_EVALUACION_WIZARD_SUCCESS: {
            let payload = action.payload;
            return {
                ...state,
                loading: false,
                loaded: true,
                id: payload.id,
                idEstatusEncuesta : payload.idEstatus,
                nombre: payload.nombre,
                descripcion: payload.descripcion,
                seccion: true,
                inserta: false,
                edita: false,
                idTipoEncuesta: payload.idTipoEncuesta,
                secciones: payload.secciones,
                error: '',
                indexSeccion: 0,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: undefined,
                logo: payload.logo,
                sendServer: false,
                saveServer: false,
                consulto: true,
                asigna : false,
                deletePregunta : false,
                editoPregunta : false,
                publicada : false,
                editoState : state.editoState,
                codigoEncuesta : payload.codigo,
                failSave : false,
                responsables : state.responsables,
                encuestas: state.encuestas,
                addSeccion : false
            }
        }
        case fromWizarEVAction.GET_EVALUACION_WIZARD_FAIL: {
            let payload = action.payload;
            return {
                ...state,
                error: payload,
                loading: false,
                loaded: false,
                responsables : state.responsables,
                encuestas: state.encuestas,
                addSeccion : false
            }
        }
        case fromWizarEVAction.SET_TIPO_EVALUACION:{
            let payload = action.payload;
            return {
                ...state,
                idTipoEncuesta : payload,
                idEstatusEncuesta : EnumEstatusEV.Borrador,
                loading:false,
                loaded: true,
                id: state.id,
                nombre: state.nombre,
                descripcion: state.descripcion,
                seccion : state.seccion,
                inserta : state.inserta,
                edita: state.edita,
                secciones:state.secciones,
                error:'',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: undefined,
                logo: state.logo,
                sendServer: false,
                saveServer: false,
                consulto: state.consulto,
                asigna : true,
                deletePregunta : false,
                editoPregunta : false,
                publicada : false,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : state.responsables,
                encuestas: state.encuestas,
                addSeccion : false
            }
        }
        case fromWizarEVAction.PUBLICA_EVALUACION_WIZARD_LOAD : {
            return {
                ...state
            }
        }
        case fromWizarEVAction.PUBLICA_EVALUACION_WIZARD_SUCCESS : {
            return {
                ...state,
                idTipoEncuesta : state.idTipoEncuesta,
                loading:false,
                loaded: true,
                id: state.id,
                idEstatusEncuesta : EnumEstatusEV.Publicada,
                nombre: state.nombre,
                descripcion: state.descripcion,
                seccion : state.seccion,
                inserta : state.inserta,
                edita: state.edita,
                secciones:state.secciones,
                error:'',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: undefined,
                logo: state.logo,
                sendServer: false,
                saveServer: false,
                consulto: state.consulto,
                asigna : false,
                deletePregunta : false,
                editoPregunta : false,
                publicada : true,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : state.responsables,
                encuestas: state.encuestas,
                addSeccion : false
            }
        }
        case fromWizarEVAction.SET_SEND_SERVER_EV : {
            let payload = action.payload;
            return {
                ...state,
                idTipoEncuesta : state.idTipoEncuesta,
                loading:false,
                loaded: false,
                id: state.id,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                seccion : state.seccion,
                inserta : state.inserta,
                edita: state.edita,
                secciones:state.secciones,
                error:'',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: undefined,
                logo: state.logo,
                sendServer: payload,
                saveServer: false,
                consulto: state.consulto,
                asigna : false,
                deletePregunta : false,
                editoPregunta : false,
                publicada : true,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : state.responsables,
                encuestas: state.encuestas,
                addSeccion : false
            }
        }
        case fromWizarEVAction.SET_CONSULTO_SERVER_EV : {
            return {
                ...state,
                idTipoEncuesta : state.idTipoEncuesta,
                loading:false,
                loaded: false,
                id: state.id,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                seccion : state.seccion,
                inserta : state.inserta,
                edita: state.edita,
                secciones:state.secciones,
                error:'',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: undefined,
                logo: state.logo,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : state.asigna,
                deletePregunta : state.deletePregunta,
                editoPregunta : state.editPregunta,
                publicada : state.publicada,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : state.responsables,
                encuestas: state.encuestas,
                addSeccion : false
            }
        }
        case fromWizarEVAction.SELECT_SECCION_CONFIGURACION_EV : {
            
            let payload = JSON.parse(JSON.stringify(action.payload)) as SeccionesWizardEV;
            let secciones = JSON.parse(JSON.stringify(state.secciones)) as SeccionesWizardEV[];

            for(var i = 0; i < secciones.length; i++){
                if(secciones[i].index == payload.index) {
                    secciones[i].selected = true;
                    secciones[i].selectConfiguration = true;
                } else {
                    secciones[i].selected = false;
                    secciones[i].selectConfiguration = false;
                }
            }
            
            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                editPregunta: false,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                secciones: secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                deletePregunta: false,
                editoPregunta : false,
                preguntaEdit: null,
                logo: state.logo,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                publicada : false,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : state.responsables,
                encuestas: state.encuestas,
                addSeccion : false
            };
        }
        case fromWizarEVAction.CLOSE_SECCION_CONFIGURACION_EV : {
            let payload = JSON.parse(JSON.stringify(action.payload)) as SeccionesWizardEV;
            let secciones = JSON.parse(JSON.stringify(state.secciones)) as SeccionesWizardEV[];
            secciones[payload.index].selectConfiguration = false;

            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                editPregunta: false,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                secciones: secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                deletePregunta: false,
                editoPregunta : false,
                preguntaEdit: null,
                logo: state.logo,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                publicada : false,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : state.responsables,
                encuestas: state.encuestas,
                addSeccion : false

            };
        }
        case fromWizarEVAction.CLOSE_ALL_SECCION_CONFIGURACION_EV : {
            
            let secciones = JSON.parse(JSON.stringify(state.secciones)) as SeccionesWizardEV[];
            for(var i = 0; i < secciones.length;i++){
                secciones[i].selectConfiguration = false;
            }
            
            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                editPregunta: false,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                secciones: secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                deletePregunta: false,
                editoPregunta : false,
                preguntaEdit: null,
                logo: state.logo,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                publicada : false,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : state.responsables,
                encuestas: state.encuestas,
                addSeccion : false

            };
        }
        case fromWizarEVAction.CHANGE_ENCUESTA_SECCION_EV : {

            let payload = action.payload;
            let secciones = JSON.parse(JSON.stringify(state.secciones)) as SeccionesWizardEV[];

            let seccion = secciones[payload.indexSeccion];
            seccion.encuestaLigada = Number.parseFloat(payload.idEncuesta);


            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                editPregunta: false,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                ponderacion: state.ponderacion,
                secciones: secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                deletePregunta: false,
                editoPregunta : false,
                preguntaEdit: null,
                logo: state.logo,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                publicada : false,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : state.responsables,
                encuestas: state.encuestas,
                addSeccion : false
            };

        }
        case fromWizarEVAction.CHANGE_NOMBRE_SECCION_EV : {
            let payload = action.payload;
            
            let secciones = JSON.parse(JSON.stringify(state.secciones)) as SeccionesWizardEV[];
            let nombreActual =  secciones[payload.indexSeccion].nombre;
            let poderacionActual =  secciones[payload.indexSeccion].ponderacion;
            let nuevaPonderacion =  payload.ponderacion;
            let nuevoNombre =  payload.nombre;
            let seccion = secciones[payload.indexSeccion];
            seccion.ponderacion = Number.parseFloat(payload.ponderacion);
            let editoState : boolean = false;
            let sumaSecciones = 0;
            secciones.forEach((item) => {
                sumaSecciones = (sumaSecciones + item.ponderacion);
            });
            
            if( poderacionActual !=  parseInt(nuevaPonderacion)) {
                editoState = true;
            }

            if(nuevoNombre.trim() !=  nombreActual.trim()) {
                editoState = true;
            }
            if(nuevoNombre.trim().length > 0){
                seccion.nombre = payload.nombre;
                seccion.valid = true;
                seccion.selectConfiguration = false;
            } else {
                seccion.valid = false;
                seccion.selectConfiguration = true;
            }
            

            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                editPregunta: false,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                ponderacion: (100 - sumaSecciones),
                secciones: secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                deletePregunta: false,
                editoPregunta : false,
                preguntaEdit: null,
                logo: state.logo,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                publicada : false,
                editoState : editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : state.responsables,
                encuestas: state.encuestas,
                addSeccion : false
            };
        }


        
      
        case fromWizarEVAction.ELIMINAR_SECCION_EV : {
            let payload = action.payload;
           
            let indexSeccion = 0;
            let secciones = JSON.parse(JSON.stringify(state.secciones)) as SeccionesWizardEV[];
            secciones = secciones.filter(x=> x.index != payload);
            
            let sumaSecciones = 0;
            secciones.forEach((item,index) => {
                item.index = index;
                sumaSecciones = (sumaSecciones + item.ponderacion);
            });
            if(secciones.length > 0){
                secciones[indexSeccion].selected = true;
            }
          

            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                editPregunta: false,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                secciones: secciones,
                ponderacion: (100 - sumaSecciones),
                error: '',
                indexSeccion: indexSeccion,
                addPregunta: false,
                deletePregunta: false,
                editoPregunta : false,
                preguntaEdit: null,
                logo: state.logo,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                publicada : false,
                editoState : true,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : state.responsables,
                encuestas: state.encuestas,
                addSeccion : false
            };
        }

        case fromWizarEVAction.RESPONSABLES_LOAD :{
            let payload = action.payload;
            
            return {
                ...state ,
                loading: true,
                loaded: false,
                seccion: false,
                editPregunta: false,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                secciones: state.secciones,
                ponderacion: state.ponderacion,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                deletePregunta: false,
                editoPregunta : false,
                preguntaEdit: null,
                logo: state.logo,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                publicada : false,
                editoState : false,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : [],
                encuestas: state.encuestas,
                addSeccion : false
            }
        }
        case fromWizarEVAction.RESPONSABLES_LOAD_SUCCESS :{
            
            let payload = action.payload;
            
            return {
                ...state ,
                loading: false,
                loaded: true,
                seccion: false,
                editPregunta: false,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                secciones: state.secciones,
                ponderacion: state.ponderacion,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                deletePregunta: false,
                editoPregunta : false,
                preguntaEdit: null,
                logo: state.logo,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                publicada : false,
                editoState : false,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : payload,
                encuestas: state.encuestas,
                addSeccion : false
            }
        }
        case fromWizarEVAction.ENCUESTAS_SIMPLE_LOAD :{
            let payload = action.payload;

            return {
                ...state ,
                loading: true,
                loaded: false,
                seccion: false,
                editPregunta: false,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                secciones: state.secciones,
                ponderacion: state.ponderacion,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                deletePregunta: false,
                editoPregunta : false,
                preguntaEdit: null,
                logo: state.logo,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                publicada : false,
                editoState : false,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : state.responsables,
                encuestas: [],
                addSeccion : false
            }

        }
        case fromWizarEVAction.ENCUESTAS_SIMPLE_LOAD_SUCCESS :{
            let payload = action.payload;
            return {
                ...state ,
                loading: true,
                loaded: false,
                seccion: false,
                editPregunta: false,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                secciones: state.secciones,
                ponderacion: state.ponderacion,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                deletePregunta: false,
                editoPregunta : false,
                preguntaEdit: null,
                logo: state.logo,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                publicada : false,
                editoState : false,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : state.responsables,
                encuestas: payload,
                addSeccion : false
            }
           
        }
        case fromWizarEVAction.ENCUESTAS_SIMPLE_LOAD_FAIL :{
            let payload = action.payload;
           
            return {
                ...state ,
                loading: true,
                loaded: false,
                seccion: false,
                editPregunta: false,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                secciones: state.secciones,
                ponderacion: state.ponderacion,
                error: payload,
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                deletePregunta: false,
                editoPregunta : false,
                preguntaEdit: null,
                logo: state.logo,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                publicada : false,
                editoState : false,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false,
                responsables : state.responsables,
                encuestas: state.encuestas,
                addSeccion : false
                
            }
        }
        default:
            return {
                ...state
            }
            break;
    }
}

export const getStateEV = (state: WizardEVState) => state;
export const getEditoStateWizardEV = (state: WizardEVState) => state.editoState;
export const getEditoFailSaveEV = (state: WizardEVState) => state.failSave;