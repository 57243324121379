import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/config/config.service';
import { ErrorHandlerService } from '../error-handler.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ListadoUsuariosResponse } from 'src/app/shared/models/response/usuario/listado-usuarios-response.model';
import { UserProfileEdit } from 'src/app/shared/models/request/usuario/user-profile-edit';
import { UsuarioEditResponse } from 'src/app/shared/models/response/usuario/usuario-edit-response.model';
import { ResponseBase } from 'src/app/shared/models/response/response-base.model';
import { ResponseDeleteUser } from 'src/app/shared/models/response/usuario/responseDeleteUser.model';


@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  headers: any;
  listadoUsuario: ListadoUsuariosResponse;
  constructor(private http: HttpClient,
    private config: ConfigService,
    private servicesError: ErrorHandlerService) {
    this.config.headers.subscribe((headers) => {
      if (headers) {
        this.headers = headers;
      }
    });
  }
  getAllUsers(): Observable<ListadoUsuariosResponse> {
    return this.http.get<ListadoUsuariosResponse>(`${environment.baseUrl}01/00`, { headers: this.headers })
    .pipe(catchError(this.servicesError.erroHandler));
  }

  editUserApp(request: UserProfileEdit): Observable<UsuarioEditResponse> {
   
    return this.http.put<UsuarioEditResponse>(`${environment.baseUrl}00/05`, request,  { headers: this.headers })
    .pipe(catchError(this.servicesError.erroHandler));
  }

  eliminarUsuario(id: number): Observable<ResponseDeleteUser> {
    return this.http.delete<ResponseDeleteUser>(`${environment.baseUrl}00/01?idUsuario=${id}`,  { headers: this.headers })
    .pipe(catchError(this.servicesError.erroHandler));
  }
}
