import { switchMap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as fromEncuestasAction from '../actions/encuestas-internas.action';
import { EncuestaInternaService } from '../../services/encuestas/encuesta-interna.service';

import { MensajesAppService } from '../../services/mensajes-app.service';

import { ToastrService } from 'ngx-toastr';
import { TipoRespuesta } from 'src/app/shared/models/enums/enumTipoRespuesta.model';
@Injectable()
export class EncuestaInternaEffects {
    constructor(private actions$: Actions,
        private encuestasService: EncuestaInternaService,
        private messageServices: MensajesAppService,
        private toastr: ToastrService) {

    }
    @Effect()
    encuestaInterna$: Observable<Action> = this.actions$.pipe(
        ofType(fromEncuestasAction.ENCUESTAS_LOAD),
        map((action: fromEncuestasAction.EncuestasLoad) => action.payload),
        switchMap( (payload)  => this.encuestasService.getTiposEncuestas(payload).pipe(
            map(response => {
                return new fromEncuestasAction.EncuestasLoadSuccess(response);
            }, catchError(error => of(new fromEncuestasAction.EncuestasLoadFail(error))))
        ))
      
    );
    @Effect()
    detalleEncuestaInterna$: Observable<Action> = this.actions$.pipe(
        ofType(fromEncuestasAction.ENCUESTA_DETALLE_LOAD),
        map((action: fromEncuestasAction.EncuestaDetalleLoad) => action.payload),
        switchMap( (payload)  => this.encuestasService.getDetalleEncuesta(payload).pipe(
            map(response => {
                return new fromEncuestasAction.EncuestaDetalleLoadSuccess(response);
            }, catchError(error => of(new fromEncuestasAction.EncuestaDetalleLoadFail(error))))
        ))
    );
    @Effect()
    eliminarEncuestaLoad$: Observable<Action> = this.actions$.pipe(
        ofType(fromEncuestasAction.ENCUESTA_DELETE),
        map((action: fromEncuestasAction.DeleteEncuesta) => action.payload),
        switchMap( (payload)  => this.encuestasService.eliminarEncuesta(payload).pipe(
            map(response => {

                if(response.tipoRespuesta == TipoRespuesta.Exito) {
                    this.toastr.success(response.mensaje);
                    return new fromEncuestasAction.DeleteEncuestaSuccess(response);
                } else
                {
                    this.toastr.error(response.mensaje);
                   return new fromEncuestasAction.DeleteEncuestaFail(response)
                } 
                
            }, catchError(error => of(new fromEncuestasAction.DeleteEncuestaFail(error))))
        ))
      
    );
    @Effect()
    seccionesEncuestas$: Observable<Action> = this.actions$.pipe(
        ofType(fromEncuestasAction.SECCIONES_LOAD),
        map((action: fromEncuestasAction.SeccionesLoad) => action.payload),
        switchMap( (payload)  => this.encuestasService.getSecciones(payload).pipe(
            map(response => {
                if (response.tipoRespuesta === TipoRespuesta.Error) {
                    this.messageServices.messageBox(response.tipoRespuesta, response.mensaje);
                    return new fromEncuestasAction.SeccionesFail(response);
                }
                return new fromEncuestasAction.SeccionesSuccess(response);
            }, catchError(error => of(new fromEncuestasAction.SeccionesFail(error))))
        ))
    );

    @Effect()
    PreguntasSeccion$: Observable<Action> = this.actions$.pipe(
        ofType(fromEncuestasAction.PREGUNTAS_LOAD),
        map((action: fromEncuestasAction.PreguntasLoad) => action.payload),
        switchMap( (payload)  => this.encuestasService.getPreguntas(payload).pipe(
            map(response => {
                if (response.tipoRespuesta === TipoRespuesta.Error) {
                    this.messageServices.messageBox(response.tipoRespuesta, response.mensaje);
                    return new fromEncuestasAction.PreguntasFail(response);
                }
                return new fromEncuestasAction.PreguntasSuccess(response);
            }, catchError(error => of(new fromEncuestasAction.SeccionesFail(error))))
        ))
    );

    @Effect()
    AddPregunta$: Observable<Action> = this.actions$.pipe(
        ofType(fromEncuestasAction.PREGUNTAS_ADD),
        map((action: fromEncuestasAction.PreguntasAddLoad) => action.payload),
        switchMap( (payload)  => this.encuestasService.AddPregunta(payload).pipe(
            map(response => {
                if (response.tipoRespuesta === TipoRespuesta.Error) {
                    this.messageServices.messageBox(response.tipoRespuesta, response.mensaje);
                    return new fromEncuestasAction.PreguntasAddFail(response);
                } else {
                    this.messageServices.messageBox(response.tipoRespuesta, response.mensaje);
                    return new fromEncuestasAction.PreguntasAddSuccess(response);
                }
            }, catchError(error => of(new fromEncuestasAction.PreguntasAddFail(error))))
        ))
    );
    @Effect()
    SelectedPregunta$: Observable<Action> = this.actions$.pipe(
        ofType(fromEncuestasAction.PREGUNTAS_SELECTED),
        map((action: fromEncuestasAction.PreguntaSelected) => action.payload),
        switchMap( (payload)  => this.encuestasService.preguntaSelected(payload).pipe(
            map(response => {
                return new fromEncuestasAction.PreguntaSelectedSuccesss(response);
            }, catchError(error => of(new fromEncuestasAction.PreguntaSelectedFail(error))))
        ))
    );
    @Effect()
    DeletePregunta$: Observable<Action> = this.actions$.pipe(
        ofType(fromEncuestasAction.PREGUNTAS_DELETED),
        map((action: fromEncuestasAction.PreguntaDeleted) => action.payload),
        switchMap( (payload)  => this.encuestasService.deletePregunta(payload).pipe(
            map(response => {
                if (response.tipoRespuesta === TipoRespuesta.Error) {
                    this.messageServices.messageBox(response.tipoRespuesta, response.mensaje);
                    return new fromEncuestasAction.PreguntaDeletedFail(response);
                } else {
                    this.messageServices.messageBox(response.tipoRespuesta, response.mensaje);
                    return new fromEncuestasAction.PreguntaDeletedSuccesss(response);
                }
            }, catchError(error => of(new fromEncuestasAction.PreguntaDeleted(error))))
        ))
    );
    @Effect()
    copyPregunta$: Observable<Action> = this.actions$.pipe(
        ofType(fromEncuestasAction.PREGUNTA_COPY),
        map((action: fromEncuestasAction.PreguntaCopy) => action.payload),
        switchMap( (payload)  => this.encuestasService.copyPregunta(payload).pipe(
            map(response => {
                if (response.tipoRespuesta === TipoRespuesta.Error) {
                    this.messageServices.messageBox(response.tipoRespuesta, response.mensaje);
                    return new fromEncuestasAction.PreguntaCopyFail(response);
                } else {
                    this.messageServices.messageBox(response.tipoRespuesta, response.mensaje);
                    return new fromEncuestasAction.PreguntaCopySuccess(response);
                }
            }, catchError(error => of(new fromEncuestasAction.PreguntaCopyFail(error))))
        ))
    );
    
}
