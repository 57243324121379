
import { TipoRespuesta } from 'src/app/shared/models/enums/enumTipoRespuesta.model';
import { ListadoEncuestaInterna } from 'src/app/shared/models/response/encuestas/listado-encuesta-interna.model';
import * as fromEncuestaInterna from '../actions/encuestas-internas.action';



export interface EncuestaInternaState {
    agregar : boolean;
    loanding: boolean;
    loaded: boolean;
    encuestaInterna: ListadoEncuestaInterna;
    error: string;
}
export const initialState: EncuestaInternaState = {
    loanding: false,
    loaded : false,
    encuestaInterna : new ListadoEncuestaInterna(),
    error : '',
    agregar : false
};

export function reducer(state = initialState, action: fromEncuestaInterna.EncuestaInternaAction ): EncuestaInternaState {
    switch (action.type) {
        case fromEncuestaInterna.ENCUESTAS_LOAD: {
            return {
                ...state,
                loanding: true,
                loaded : false,
                encuestaInterna :  new ListadoEncuestaInterna(),
                error: '',
                agregar : false
            };
        }
        case fromEncuestaInterna.ENCUESTAS_LOAD_SUCCESS: {
            const encuestaInterna = action.payload;
           
            return {
                ...state,
                loanding: false,
                loaded: true,
                encuestaInterna,
                error : '',
                agregar : false
            };
        }
        case fromEncuestaInterna.ENCUESTAS_LOAD_FAIL : {
            const data = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                encuestaInterna : new ListadoEncuestaInterna(),
                error : data,
                agregar : false
            };

        }
        case fromEncuestaInterna.ADD_ENCUESTA : {
            return {
                ...state,
                loanding: false,
                loaded: false,
                encuestaInterna : state.encuestaInterna,
                error : '',
                agregar : true

            
            };

        }

        case fromEncuestaInterna.ENCUESTA_DELETE: {
            return {
                ...state,
                loanding: true,
                loaded : false,
                encuestaInterna : state.encuestaInterna,
                error: '',
                agregar : false
            };
        }
        case fromEncuestaInterna.ENCUESTA_DELETE_SUCCESS: {
            const payload = action.payload;
            let listado = JSON.parse(JSON.stringify(state.encuestaInterna)) as ListadoEncuestaInterna; 
            
            if(payload.tipoRespuesta == TipoRespuesta.Exito){
                let encuestas = listado.encuestaInterna.filter(x=> x.id != payload.idEncuesta);
                listado.encuestaInterna = encuestas;
            }
            
            return {
                ...state,
                loanding: false,
                loaded: true,
                encuestaInterna : listado,
                error : '',
                agregar : false
            };
        }
        case fromEncuestaInterna.ENCUESTA_DELETE_FAIL : {
            const data = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                encuestaInterna : state.encuestaInterna,
                error : data,
                agregar : false
            };

        }

        
    }
}

export const getState = (state: EncuestaInternaState) => state;
export const getEncuestas = (state: EncuestaInternaState) => state != null ? state.encuestaInterna : new ListadoEncuestaInterna();
