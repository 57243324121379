
import * as fromResponderEvaluacionV2 from '../../actions/evaluaciones/responder-evaluacionV2.action';
import { ResponderEvaluacion } from '../../../shared/models/response/evaluaciones/responderEvaluacion/responderEvaluaciones';
import { PreguntasEvaluaciones } from '../../../shared/models/response/evaluaciones/responderEvaluacion/preguntasEvaluaciones';
import { EnumTipoRespuestas } from 'src/app/shared/models/enums/enum-tipo-respuestas.model';


export interface EvaluacionState {
    loanding : boolean;
    loaded: boolean
    indexSeccion: number;
    evaluacion : ResponderEvaluacion,
    error: any,
    inicia: boolean,
    agregoMiembro: boolean,
    FinalizoEvaluacion: number
   
}
export const initialState: EvaluacionState = {
    indexSeccion: 0,
    loanding:false,
    loaded: false,
    evaluacion :  new ResponderEvaluacion(),
    error:'',
    inicia: false,
    agregoMiembro: false,
    FinalizoEvaluacion : null

};
export function reducer(state = initialState, action: fromResponderEvaluacionV2.EvaluacionActionV2): EvaluacionState {
    switch (action.type) {

        case fromResponderEvaluacionV2.GET_EVALUACION_LOAD_V2: {
            
            return {
                ...state,
                indexSeccion: 0,
                loanding: true,
                loaded : false,
                evaluacion : new ResponderEvaluacion(),
                error: '',
                agregoMiembro: false,
                FinalizoEvaluacion : null

            };

        };
        case fromResponderEvaluacionV2.GET_EVALUACION_SUCCESS_V2: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                indexSeccion: 0,
                loaded: true,
                evaluacion : payload,
                error: '',
                agregoMiembro: false,
                FinalizoEvaluacion : null
            };
        }
        case fromResponderEvaluacionV2.GET_EVALUACION_FAIL_V2: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                indexSeccion: 0,
                evaluacion : new ResponderEvaluacion(),
                error: payload,
                agregoMiembro: false,
                FinalizoEvaluacion : null
            };
        }

        case fromResponderEvaluacionV2.SELECT_SUCURSAL_V2: {
            const payload = action.payload;
            let evaluacion = JSON.parse(JSON.stringify(state.evaluacion)) as ResponderEvaluacion;
            evaluacion.idSucursal = payload
           
            return {
                ...state,
                indexSeccion: state.indexSeccion,
                loanding: false,
                loaded: false,
                evaluacion : evaluacion,
                error: payload,
                agregoMiembro: false,
                FinalizoEvaluacion : null
            };
        }
        case fromResponderEvaluacionV2.INICIAR_EVALUACION_V2: {
           
            return {
                ...state,
                loanding: false,
                indexSeccion: state.indexSeccion,
                loaded: true,
                evaluacion : state.evaluacion,
                error: '',
                inicia: true,
                agregoMiembro: false,
                FinalizoEvaluacion : null
            };
        }
        case fromResponderEvaluacionV2.AGREGA_COMENTARIO_V2:{

            const payload = action.payload;
            

            let evaluacion = JSON.parse(JSON.stringify(state.evaluacion)) as ResponderEvaluacion;
            let indexSeccion = evaluacion.secciones.findIndex(x=> x.id == payload.idSeccion);
            let indexPregunta = 0;
              
          
           
            let idMiembro : number  = payload.idMiembro;
            let pregunta = new PreguntasEvaluaciones();
          
            if(idMiembro != null){
                let preguntas = [...evaluacion.secciones.find(x=> x.id == payload.idSeccion).preguntasMiembros];
                pregunta =    {...preguntas.find(x=> x.id == payload.id && x.idMiembro == idMiembro)};
                indexPregunta = evaluacion.secciones[indexSeccion].preguntasMiembros.findIndex(x=> x.id == payload.id && x.idMiembro == idMiembro);
                pregunta.comentario = payload.comentario;
                evaluacion.secciones[indexSeccion].preguntasMiembros[indexPregunta] = pregunta;
              
              
            } else {
                let preguntas = [...evaluacion.secciones.find(x=> x.id == payload.idSeccion).preguntas];
                pregunta=    {...preguntas.find(x=> x.id == payload.id)};
                indexPregunta = evaluacion.secciones[indexSeccion].preguntas.findIndex(x=> x.id == payload.id );
                pregunta.comentario = payload.comentario;
                evaluacion.secciones[indexSeccion].preguntas[indexPregunta] = pregunta;
             
              
            }
            
            
           
          //  evaluacion.secciones[indexSeccion].preguntas[indexPregunta] = pregunta;
           
            return {
                ...state,
                loanding: false,
                indexSeccion: state.indexSeccion,
                loaded: false,
                evaluacion : evaluacion,
                error: '',
                inicia: true,
                agregoMiembro: false,
                FinalizoEvaluacion : null
            };

        }
        case fromResponderEvaluacionV2.AGREGA_IMAGEN_V2:{

            const payload = action.payload;
            let evaluacion = JSON.parse(JSON.stringify(state.evaluacion)) as ResponderEvaluacion;
            
            let indexSeccion = evaluacion.secciones.findIndex(x=> x.id == payload.idSeccion);
            let indexPregunta = evaluacion.secciones[indexSeccion].preguntas.findIndex(x=> x.id == payload.id);

           
           
            let idMiembro : number  = payload.idMiembro;
            let pregunta = new PreguntasEvaluaciones();
          
            if(idMiembro != null){
                let preguntas = [...evaluacion.secciones.find(x=> x.id == payload.idSeccion).preguntasMiembros];
                pregunta =    {...preguntas.find(x=> x.id == payload.id && x.idMiembro == idMiembro)};
                indexPregunta = evaluacion.secciones[indexSeccion].preguntasMiembros.findIndex(x=> x.id == payload.id && x.idMiembro == idMiembro);
                pregunta.imagenes = payload.imagenes;
                evaluacion.secciones[indexSeccion].preguntasMiembros[indexPregunta] = pregunta;
                
            } else {
                let preguntas = [...evaluacion.secciones.find(x=> x.id == payload.idSeccion).preguntas];
                pregunta=    {...preguntas.find(x=> x.id == payload.id)};
                indexPregunta = evaluacion.secciones[indexSeccion].preguntas.findIndex(x=> x.id == payload.id );
                pregunta.imagenes = payload.imagenes;
                evaluacion.secciones[indexSeccion].preguntas[indexPregunta] = pregunta;
               
            }
            
            
            
           // evaluacion.secciones[indexSeccion].preguntas[indexPregunta] = pregunta;
           
            return {
                ...state,
                loanding: false,
                indexSeccion: state.indexSeccion,
                loaded: true,
                evaluacion : evaluacion,
                error: '',
                inicia: true,
                agregoMiembro: false,
                FinalizoEvaluacion : null
            };

        }
        case fromResponderEvaluacionV2.RESPONDER_SIMPLE:{

            const payload = action.payload;
            let evaluacion = JSON.parse(JSON.stringify(state.evaluacion)) as ResponderEvaluacion;
            let indexSeccion = evaluacion.secciones.findIndex(x=> x.id == payload.idSeccion);
            let indexPregunta = evaluacion.secciones[indexSeccion].preguntas.findIndex(x=> x.id == payload.id);
            payload.respuestasUsuario[0] = payload.tipoRespuesta == EnumTipoRespuestas.DATOS_GENERALES ? payload.respuestasUsuario[0].trim() : payload.respuestasUsuario[0];
          
           let idMiembro : number  = payload.idMiembro;
           let pregunta = new PreguntasEvaluaciones();
         
           if(idMiembro != null){
               let preguntas = [...evaluacion.secciones.find(x=> x.id == payload.idSeccion).preguntasMiembros];
               pregunta =    {...preguntas.find(x=> x.id == payload.id && x.idMiembro == idMiembro)};
               indexPregunta = evaluacion.secciones[indexSeccion].preguntasMiembros.findIndex(x=> x.id == payload.id && x.idMiembro == idMiembro);
               pregunta.respuestasUsuario = payload.respuestasUsuario;
               if(payload.tipoRespuesta == EnumTipoRespuestas.DATOS_GENERALES){
                if(pregunta.respuestasUsuario == null){
                    pregunta.respuestasUsuario = [];
                  }
                pregunta.respuestasUsuario[0] = payload.respuestasUsuario[0].length === 0 ? "" : payload.respuestasUsuario[0];
               }
               evaluacion.secciones[indexSeccion].preguntasMiembros[indexPregunta] = pregunta;
           } else {
               let preguntas = [...evaluacion.secciones.find(x=> x.id == payload.idSeccion).preguntas];
               pregunta=    {...preguntas.find(x=> x.id == payload.id)};
               indexPregunta = evaluacion.secciones[indexSeccion].preguntas.findIndex(x=> x.id == payload.id );
               if(payload.tipoRespuesta == EnumTipoRespuestas.DATOS_GENERALES){
                if(pregunta.respuestasUsuario == null){
                    pregunta.respuestasUsuario = [];
                }
                pregunta.respuestasUsuario[0] = payload.respuestasUsuario[0].length === 0 ? "" : payload.respuestasUsuario[0];
               }
               pregunta.respuestasUsuario = payload.respuestasUsuario;
               evaluacion.secciones[indexSeccion].preguntas[indexPregunta] = pregunta;
           }
           
           
            return {
                ...state,
                loanding: false,
                indexSeccion: state.indexSeccion,
                loaded: false,
                evaluacion : evaluacion,
                error: '',
                inicia: true,
                agregoMiembro: false,
                FinalizoEvaluacion : null
            };

        }
        case fromResponderEvaluacionV2.SET_INDEX_SECCION:{
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                indexSeccion: payload,
                loaded: true,
                evaluacion : state.evaluacion,
                error: '',
                inicia: true,
                agregoMiembro: false,
                FinalizoEvaluacion : null
            };
        }
        case fromResponderEvaluacionV2.ADD_PREGUNTAS_MIEMBRO:{
            const payload = action.payload;
            let evaluacion = JSON.parse(JSON.stringify(state.evaluacion)) as ResponderEvaluacion;
            payload.forEach(pregunta=> {
                if(evaluacion.secciones[state.indexSeccion].preguntasMiembros == null){
                    evaluacion.secciones[state.indexSeccion].preguntasMiembros = [];
                    evaluacion.secciones[state.indexSeccion].preguntasMiembros.push(pregunta);
                } else {
                    evaluacion.secciones[state.indexSeccion].preguntasMiembros.push(pregunta);
                }
             
            })
           
            return {
                ...state,
                loanding: false,
                indexSeccion: state.indexSeccion,
                loaded: true,
                evaluacion : evaluacion,
                error: '',
                inicia: true,
                agregoMiembro: true,
                FinalizoEvaluacion : null
            };
        }
        case fromResponderEvaluacionV2.REMOVE_PREGUNTAS_MIEMBRO:{
            const payload = action.payload;
            let evaluacion = JSON.parse(JSON.stringify(state.evaluacion)) as ResponderEvaluacion;
            let preguntas = evaluacion.secciones[state.indexSeccion].preguntasMiembros;
            evaluacion.secciones[state.indexSeccion].preguntasMiembros = preguntas.filter(x=> x.idMiembro != payload);
            return {
                ...state,
                loanding: false,
                indexSeccion: state.indexSeccion,
                loaded: true,
                evaluacion : evaluacion,
                error: '',
                inicia: true,
                agregoMiembro: false,
                FinalizoEvaluacion : null
            };
        }
        case fromResponderEvaluacionV2.ADD_EVALUADOS_ENCUESTA:{
            const payload = action.payload;
            let evaluacion = JSON.parse(JSON.stringify(state.evaluacion)) as ResponderEvaluacion;
            let indexSeccion = evaluacion.secciones.findIndex(x=> x.id == payload.id);
            evaluacion.secciones[indexSeccion].encuestados = payload.encuestados;

            return {
                ...state,
                loanding: false,
                indexSeccion: state.indexSeccion,
                loaded: false,
                evaluacion : evaluacion,
                error: '',
                inicia: true,
                agregoMiembro: false,
                FinalizoEvaluacion : null
            };

        }
        case fromResponderEvaluacionV2.FINALIZO_EVALUACION:{
            const payload = action.payload;

            return {
                ...state,
                loanding: false,
                indexSeccion: state.indexSeccion,
                loaded: true,
                evaluacion : state.evaluacion,
                error: '',
                inicia: false,
                agregoMiembro: false,
                FinalizoEvaluacion : payload
            };

        }

    }
}

export const getStateEvaluacion = (state: EvaluacionState) => state;
