
import { DashBoardGeneralResponse } from 'src/app/shared/models/response/dashboard/general/dash-board-general-response.model';
import * as fromDashBoardAction from '../actions/dashboard-genera.action';
export interface DashBoardState {
    loanding: boolean;
    loaded: boolean;
    error: any;
    dashBoardGeneral: DashBoardGeneralResponse;
 }
 export const initialState: DashBoardState = {
     loanding:false,
     loaded:false,
     error:'',
     dashBoardGeneral: new DashBoardGeneralResponse()
 }
 export function reducer(state = initialState, action: fromDashBoardAction.DashBoardGeneralAction ): DashBoardState {
    switch (action.type) {
        case fromDashBoardAction.DASHBOARD_GENERAL_LOAD: {

            return {
                ...state,
                loanding: true,
                loaded: false,
                dashBoardGeneral :  {...state.dashBoardGeneral},
                error: ''
            };
        }
        case fromDashBoardAction.DASHBOARD_GENERAL_LOAD_SUCCESS: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                dashBoardGeneral :  payload,
                error: ''
            };
        }
        case fromDashBoardAction.DASHBOARD_GENERAL_LOAD_FAIL: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                dashBoardGeneral :  {...state.dashBoardGeneral},
                error: payload
            };
        }
    }
}

export const getState = (state: DashBoardState) => state;
