import { MiembrosEvaluaciones } from "./miebrosEvaluaciones";
import { SeccionEvaluacion } from "./seccionEvaluaciones";
import { SucursalesData } from "./sucursalData";
import { ResponseBase } from '../../response-base.model';

export class ResponderEvaluacion extends ResponseBase{
    id : number;
    nombre : string;
    logo: string;
    descripcion: string;
    idTipoEvaluacion : number;
    idSucursal : number;
    idrespuesta : number;
    secciones: SeccionEvaluacion[] = [];
    miembros: MiembrosEvaluaciones[] = [];
    sucursales: SucursalesData[] =[];
}
