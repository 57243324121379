export class PreguntasEV {
    idPregunta: number;
    indexPregunta : number;
    indexSeccion : number;
    descripcion: string;
    idSeccion: number;
    idRespuesta: number;
    tipoRespuesta: number;
    respuestas: string;
    ponderacion : number;
    solImage: boolean;
    solComent: boolean;
    opciones: object[] = [];
    correctas: object[] = [];
    objeto: any;
    idResponsable? : number;
    responsable : string;
    responsables :number[];
    abiertas : string [] = [];
    comentarios: string;
}