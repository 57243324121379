import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfigService } from 'src/app/config/config.service';
import { DetalleRespuesta } from 'src/app/shared/models/request/encuesta/detalle-respuesta.model';
import { AnalisisResultadosResponse } from 'src/app/shared/models/response/dashboard/analisis/analisis-resultados-response';
import { DetalleRespuestaResponse } from 'src/app/shared/models/response/encuestas/detalle-respuesta-response.model';
import { ListadoRespuestasResponse } from 'src/app/shared/models/response/encuestas/listado-respuestas-response.model';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ResultadosService {
  headers: any;
  constructor(private config: ConfigService,
    private http: HttpClient,
    private servicesError: ErrorHandlerService) {
    this.config.headers.subscribe((headers) => {
      if (headers) {
        this.headers = headers;
      }
    });
  }

  getAnalisisResultado(codigo: string): Observable<AnalisisResultadosResponse> {
    return this.http.get<AnalisisResultadosResponse>(`${environment.baseUrl}07/08?codigo=${codigo}`, { headers: this.headers })
    .pipe(catchError(this.servicesError.erroHandler));
  }
  getEncuestaRespuestas(codigo : string) : Observable<ListadoRespuestasResponse> {
    return this.http.get<ListadoRespuestasResponse>(`${environment.baseUrl}07/10?codigo=${codigo}`,  { headers:  this.headers } )
    .pipe(catchError(this.servicesError.erroHandler));
   }
   getDetalleRespuesta(model : DetalleRespuesta ) : Observable<DetalleRespuestaResponse> {
     let url = `${environment.baseUrl}07/11?codigo=${model.codigo}&idRespuesta=${model.idRespuesta}`
    return this.http.get<DetalleRespuestaResponse>(url,  { headers:  this.headers } )
    .pipe(catchError(this.servicesError.erroHandler));
   }

   getAnalisisAnonimo(codigo: string): Observable<AnalisisResultadosResponse> {
    return this.http.get<AnalisisResultadosResponse>(`${environment.baseUrl}07/13?codigoLink=${codigo}`, { headers: this.headers })
    .pipe(catchError(this.servicesError.erroHandler));
  }

  
  
}
