import { Component, AfterViewInit, EventEmitter, Output, OnDestroy, OnInit } from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;
import * as fromStore from '../../store';
import { Store } from '@ngrx/store';
import { PerfilUsuario } from '../models/response/perfil/perfil-usuario.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-vertical-navigation',
  templateUrl: './vertical-navigation.component.html'
})
export class VerticalNavigationComponent implements AfterViewInit, OnDestroy, OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;
  closeResult: string;
  storeProfile: Subscription;
  perfilUsuario: PerfilUsuario = new PerfilUsuario();
 


  constructor(private modalService: NgbModal,  private store: Store<fromStore.AppState>) {
   
  }
  ngOnInit(): void {
    this.store.dispatch(new fromStore.ProfileUser(localStorage.idUsuario));
    this.initSubcriptions();
  }
  initSubcriptions() {
    this.storeProfile =  this.store.select('profile').subscribe(rs => {
      if (rs) {
        this.perfilUsuario = {...rs.profile};
      }
    });
  }
  ngOnDestroy(): void {
     this.storeProfile.unsubscribe();
  }

  changeLanguage(lang: any) {
   
    
  }
  logOff() {
    this.store.dispatch(new fromStore.LogOff());
  }
  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  copyCodigo(codigo: string){
    
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = codigo;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    
  }

  ngAfterViewInit() { }
}
