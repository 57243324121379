import { Action } from '@ngrx/store';
import { ImportaMiembrosRequest } from 'src/app/shared/models/request/grupo/importa-miembros-request.model';
import { UsuarioEncuesta } from 'src/app/shared/models/request/usuario-encuesta/usuario-encuesta.model';
import { ListadoUsuarioEncuestaResponse } from 'src/app/shared/models/response/usuario-encuesta/listado-usuario-encuesta-response.model';
import { UsuarioDeleteResponse } from 'src/app/shared/models/response/usuario-encuesta/usuario-delete-response.model';
import { UsuarioEncuestaResponse } from 'src/app/shared/models/response/usuario-encuesta/usuario-encuesta-response.model';


export const USUARIO_GRUPO_LOAD = '[UsuarioGrupo] Load';
export const USUARIO_GRUPO_LOAD_SUCCESS = '[UsuarioGrupo] Load Success';
export const USUARIO_GRUPO_LOAD_FAIL = '[UsuarioGrupo] Load Fail';

export class UsuarioGruposLoad implements Action {
    readonly type = USUARIO_GRUPO_LOAD;
    constructor(public payload: number) { }
}

export class UsuarioGruposSuccess implements Action {
    readonly type = USUARIO_GRUPO_LOAD_SUCCESS;
    constructor(public payload: ListadoUsuarioEncuestaResponse) { }
}

export class UsuarioGruposFail implements Action {
    readonly type = USUARIO_GRUPO_LOAD_FAIL;
    constructor(public payload: any) { }
}

/* ******************************** ALTA DE USUARIO GRUPO ACTION ****************************** */

export const USUARIO_GRUPO_ADD = '[UsuarioGrupo] Add';
export const USUARIO_GRUPO_ADD_SUCCESS = '[UsuarioGrupo] Add Success';
export const USUARIO_GRUPO_ADD_FAIL = '[UsuarioGrupo] Add Fail';

export class UsuarioGruposAdd implements Action {
    readonly type = USUARIO_GRUPO_ADD;
    constructor(public payload: UsuarioEncuesta) { }
}

export class UsuarioGruposAddSuccess implements Action {
    readonly type = USUARIO_GRUPO_ADD_SUCCESS;
    constructor(public payload: UsuarioEncuestaResponse) { }
}

export class UsuarioGruposAddFail implements Action {
    readonly type = USUARIO_GRUPO_ADD_FAIL;
    constructor(public payload: any) { }
}
/* ******************************** FIN ALTA DE USUARIO GRUPO ACTION ****************************** */

/* ******************************** EDIT DE USUARIO GRUPO ACTION ****************************** */

export const USUARIO_GRUPO_EDIT = '[UsuarioGrupo] Edit';
export const USUARIO_GRUPO_EDIT_SUCCESS = '[UsuarioGrupo] Edit Success';
export const USUARIO_GRUPO_EDIT_FAIL = '[UsuarioGrupo] Edit Fail';

export class UsuarioGruposEdit implements Action {
    readonly type = USUARIO_GRUPO_EDIT;
    constructor(public payload: UsuarioEncuesta) { }
}

export class UsuarioGruposEditSuccess implements Action {
    readonly type = USUARIO_GRUPO_EDIT_SUCCESS;
    constructor(public payload: UsuarioEncuestaResponse) { }
}

export class UsuarioGruposEditFail implements Action {
    readonly type = USUARIO_GRUPO_EDIT_FAIL;
    constructor(public payload: any) { }
}
/* ******************************** EDIT ALTA DE USUARIO GRUPO ACTION ****************************** */

/* ******************************** DELETE DE USUARIO GRUPO ACTION ****************************** */

export const USUARIO_GRUPO_DELETE = '[UsuarioGrupo] Delete';
export const USUARIO_GRUPO_DELETE_SUCCESS = '[UsuarioGrupo] Delete Success';
export const USUARIO_GRUPO_DELETE_FAIL = '[UsuarioGrupo] Delete Fail';

export class UsuarioGruposDelete implements Action {
    readonly type = USUARIO_GRUPO_DELETE;
    constructor(public payload: number) { }
}

export class UsuarioGruposDeleteSuccess implements Action {
    readonly type = USUARIO_GRUPO_DELETE_SUCCESS;
    constructor(public payload: UsuarioDeleteResponse) { }
}

export class UsuarioGruposDeleteFail implements Action {
    readonly type = USUARIO_GRUPO_DELETE_FAIL;
    constructor(public payload: any) { }
}
/* ******************************** EDIT ALTA DE USUARIO GRUPO ACTION ****************************** */


export const USUARIO_GET_LAYOUT = '[UsuarioGrupo] Get Layout';
export class UsuarioGetLayout implements Action {
    readonly type = USUARIO_GET_LAYOUT;
    constructor() { }
}
export const USUARIO_GET_LAYOUT_SUCCESS = '[UsuarioGrupo] Get Layout Success';
export class UsuarioGetLayoutSuccess implements Action {
    readonly type = USUARIO_GET_LAYOUT_SUCCESS;
    constructor(public payload: Blob) { }
}

export const USUARIO_GET_LAYOUT_FAIL = '[UsuarioGrupo] Get Layout Fial';
export class UsuarioGetLayoutFail implements Action {
    readonly type = USUARIO_GET_LAYOUT_FAIL;
    constructor(public payload: any) { }
}




export const USUARIO_IMPORT_LOAD = '[UsuarioImport] Load';
export class UsuarioImportLoad implements Action {
    readonly type = USUARIO_IMPORT_LOAD;
    constructor(public payload: ImportaMiembrosRequest) { }
}
export const USUARIO_IMPORT_LOAD_SUCESS = '[UsuarioImport] Success';
export class UsuarioImportLoadSuccess implements Action {
    readonly type = USUARIO_IMPORT_LOAD_SUCESS;
    constructor(public payload: ListadoUsuarioEncuestaResponse) { }
}

export const USUARIO_IMPORT_LOAD_FAIL = '[UsuarioImport] Fail';
export class UsuarioImportLoadFail implements Action {
    readonly type = USUARIO_IMPORT_LOAD_FAIL;
    constructor(public payload: any) { }
}

export type UsuarioGruposAction =
UsuarioGruposLoad |
UsuarioGruposSuccess |
UsuarioGruposFail |
UsuarioGruposAdd |
UsuarioGruposAddSuccess |
UsuarioGruposAddFail |
UsuarioGruposEdit |
UsuarioGruposEditSuccess |
UsuarioGruposEditFail |
UsuarioGruposDelete |
UsuarioGruposDeleteSuccess |
UsuarioGruposDeleteFail |
UsuarioGetLayoutSuccess |
UsuarioGetLayout  |
UsuarioGetLayoutFail |
UsuarioImportLoad |
UsuarioImportLoadSuccess |
UsuarioImportLoadFail ;

