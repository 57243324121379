
import * as fromNotificacion from '../actions/notificacion-grupo.selected.action';


export interface NotificacionState {

    loaded: boolean;
    grupo: any;
    error: string;
}
export const initialState: NotificacionState = {
    loaded : false,
    grupo: {},
    error : ''
};

export function reducer(state = initialState, action: fromNotificacion.NotificacionesAction ): NotificacionState {
    switch (action.type) {
        case fromNotificacion.SELECTED_GRUPO: {
            const grupo = action.payload;
            return {
                ...state,
                loaded : true,
                grupo :  grupo,
                error: ''
            };
        }
    }
}

export const getState = (state: NotificacionState) => state;

