import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/config/config.service';
import { ErrorHandlerService } from '../error-handler.service';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ListadoGrupoResponse } from 'src/app/shared/models/response/grupo/listado-grupo-response';
import { Detallegrupo } from 'src/app/shared/models/request/grupo/detallegrupo.model';
import { MiembrosResponse } from 'src/app/shared/models/response/miembros-response.model';
import { GrupoRequest } from 'src/app/shared/models/request/grupo/grupo-request.model';
import { AltaGrupoResponse } from 'src/app/shared/models/response/grupo/alta-grupo-response';
import { AsignaGrupoRequest } from 'src/app/shared/models/request/asigna-grupo/asigna-grupo-request.model';
import { UsuarioAppResponse } from 'src/app/shared/models/response/grupo/usuario-app-response';
import { ResponseBase } from 'src/app/shared/models/response/response-base.model';


@Injectable({
  providedIn: 'root'
})
export class GruposService {
  headers: any;
  constructor(private config: ConfigService,
    private servicesError: ErrorHandlerService,
    private http: HttpClient) {
      this.config.headers.subscribe((headers) => {
        if (headers) {
          this.headers = headers;
        }
      });
    }
    getGruposByUsuarios(idUsuario: number): Observable<ListadoGrupoResponse> {
      return this.http.get<ListadoGrupoResponse>(`${environment.baseUrl}08/00?IdUsuario=${idUsuario}`, { headers: this.headers })
      .pipe(catchError(this.servicesError.erroHandler));
    }
    getMiembrosByGrupo(model: Detallegrupo): Observable<MiembrosResponse> {
     const url =  `${environment.baseUrl}08/01?IdGrupo=${model.idGrupo}&idUsuario=${model.idUsuario}`;
      return this.http.get<MiembrosResponse>(url, { headers: this.headers })
      .pipe(catchError(this.servicesError.erroHandler));
    }
    altaGrupo(model: GrupoRequest): Observable<AltaGrupoResponse> {
      return this.http.post<AltaGrupoResponse>(`${environment.baseUrl}08/00`, model, { headers: this.headers })
      .pipe(catchError(this.servicesError.erroHandler));
    }
    asignaGrupos(model: AsignaGrupoRequest[]): Observable<AltaGrupoResponse> {
      return this.http.post<AltaGrupoResponse>(`${environment.baseUrl}08/01`, model, { headers: this.headers })
      .pipe(catchError(this.servicesError.erroHandler));
    }
    editaGrupo(model: GrupoRequest): Observable<ListadoGrupoResponse> {
      return this.http.put<ListadoGrupoResponse>(`${environment.baseUrl}08/00`, model, { headers: this.headers })
      .pipe(catchError(this.servicesError.erroHandler));
    }

    getGruposUsuairosApp(idUsuario: number): Observable<UsuarioAppResponse> {
      return this.http.get<UsuarioAppResponse>(`${environment.baseUrl}01/04?IdUsuario=${idUsuario}`, { headers: this.headers })
      .pipe(catchError(this.servicesError.erroHandler));
    }
    deleteGrupo(model: Detallegrupo): Observable<ListadoGrupoResponse> {
       const url = `${environment.baseUrl}08/00?IdGrupo=${model.idGrupo}&IdUsuario=${model.idUsuario}`;
      return this.http.delete<ListadoGrupoResponse>(url, { headers: this.headers })
      .pipe(catchError(this.servicesError.erroHandler));
    }
    deleteMiembro(id: number): Observable<ResponseBase> {
      const url = `${environment.baseUrl}08/01?Id=${id}`;
     return this.http.delete<ResponseBase>(url, { headers: this.headers })
     .pipe(catchError(this.servicesError.erroHandler));
   }
}
