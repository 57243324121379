import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { of } from "rxjs";
import { Observable } from "rxjs/observable";
import { catchError, map, switchMap } from "rxjs/operators";
import { MensajesAppService } from "src/app/services/mensajes-app.service";
import { SucuralesService } from "src/app/services/sucursales/sucursales.services";
import { TipoRespuesta } from "src/app/shared/models/enums/enumTipoRespuesta.model";
import * as fromSucursales from '../actions/sucursales.action';

@Injectable()
export class SucursalesEffects {
    constructor(private actions$: Actions,
        private sucursalesServices: SucuralesService,
        private messageServices: MensajesAppService) {
    }

    @Effect()
    sucursales$: Observable<Action> = this.actions$.pipe(
        ofType(fromSucursales.GET_SUCURSALES_LOAD),
        map((action: fromSucursales.SucursalesLoad) => action.payload),
        switchMap((payload) => this.sucursalesServices.getSucursales(payload).pipe(
            map(response => {
                return new fromSucursales.SucursalesLoadSuccess(response);
            }, catchError(error => of(new fromSucursales.SucursalesLoadFail(error))))
        ))
    );
    @Effect()
    addSucursales$: Observable<Action> = this.actions$.pipe(
        ofType(fromSucursales.ADD_SUCURSAL),
        map((action: fromSucursales.SucursalesAdd) => action.payload),
        switchMap((payload) => this.sucursalesServices.addSucursal(payload).pipe(
            map(response => {
                this.messageServices.messageBox(response.tipoRespuesta, response.mensaje);
                if(response.tipoRespuesta == TipoRespuesta.Exito) {
                    return new fromSucursales.SucursalesAddSuccess(response);
                } else {
                    return new fromSucursales.SucursalesAddFail(response);
                }
               
            }, catchError(error => of(new fromSucursales.SucursalesAddFail(error))))
        ))
    );

    @Effect()
    deleteSucursal$: Observable<Action> = this.actions$.pipe(
        ofType(fromSucursales.DELETE_SUCURSAL),
        map((action: fromSucursales.SucursalDelete) => action.payload),
        switchMap((payload) => this.sucursalesServices.deleteSucursal(payload).pipe(
            map(response => {
                this.messageServices.messageBox(response.tipoRespuesta, response.mensaje);
                if(response.tipoRespuesta == TipoRespuesta.Exito) {
                    return new fromSucursales.SucursalDeleteSuccess(response);
                } else {
                    return new fromSucursales.SucursalDeleteFail(response);
                }
               
            }, catchError(error => of(new fromSucursales.SucursalDeleteFail(error))))
        ))
    );
    @Effect()
    editSucursales$: Observable<Action> = this.actions$.pipe(
        ofType(fromSucursales.EDIT_SUCURSAL),
        map((action: fromSucursales.SucursalEdit) => action.payload),
        switchMap((payload) => this.sucursalesServices.editSucursal(payload).pipe(
            map(response => {
                this.messageServices.messageBox(response.tipoRespuesta, response.mensaje);
                if(response.tipoRespuesta == TipoRespuesta.Exito) {
                    return new fromSucursales.SucursalEditSuccess(response);
                } else {
                    return new fromSucursales.SucursalEditFail(response);
                }
               
            }, catchError(error => of(new fromSucursales.SucursalEditFail(error))))
        ))
    );
}