import { Action } from '@ngrx/store';
import { DashBoardGeneralResponse } from 'src/app/shared/models/response/dashboard/general/dash-board-general-response.model';


export const DASHBOARD_GENERAL_LOAD = '[Dashboard General] Load';
export const DASHBOARD_GENERAL_LOAD_SUCCESS = '[Dashboard General] Load Success';
export const DASHBOARD_GENERAL_LOAD_FAIL = '[Dashboard General] Load Fail';
export class DashBoardGeneral implements Action {
    readonly type = DASHBOARD_GENERAL_LOAD;
    constructor() {
    }
}
export class DashBoardSuccess implements Action {
    readonly type = DASHBOARD_GENERAL_LOAD_SUCCESS;
    constructor(public payload: DashBoardGeneralResponse) {
    }
}

export class DashBoardFail implements Action {
    readonly type = DASHBOARD_GENERAL_LOAD_FAIL;
    constructor(public payload: any) {
    }
}
export type DashBoardGeneralAction = 
DashBoardGeneral | 
DashBoardSuccess | 
DashBoardFail ;

