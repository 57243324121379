import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfigService } from 'src/app/config/config.service';
import { DashBoardEvalGeneralResponse } from 'src/app/shared/models/response/dashboard/general/dash-board-eval-general-response.model';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../error-handler.service';

@Injectable({
  providedIn: 'root'
})

export class DashBoardEvalServices {
  headers: any;
  constructor(private config: ConfigService,
    private http: HttpClient,
    private servicesError: ErrorHandlerService) {
    this.config.headers.subscribe((headers) => {
      if (headers) {
        this.headers = headers;
      }
    });
  }

  getDashBoardEvalGeneral(idUsuario: number): Observable<DashBoardEvalGeneralResponse> {
    return this.http.get<DashBoardEvalGeneralResponse>(`${environment.baseUrl}04/03?idUsuario=${idUsuario}`, { headers: this.headers })
      .pipe(catchError(this.servicesError.erroHandler));
  }
}
