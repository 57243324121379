
import { TipoRespuesta } from 'src/app/shared/models/enums/enumTipoRespuesta.model';
import { SucursalesConfiguradas } from 'src/app/shared/models/response/sucursales/sucursales-configuradas.model';
import * as fromSucursalesCofiguradasAction from '../actions/configuracion-sucursales.action';


export interface SucursalesConfiguradasState {
    loaded: boolean;
    loanding: boolean
    sucursales: SucursalesConfiguradas[],
    error: any,
    idTipoRespuesta : number,
    consulto : boolean,
    configuro: boolean,
   
}

export const initialState: SucursalesConfiguradasState = {
    loanding: false,
    loaded: false,
    sucursales: [],
    error: '',
    idTipoRespuesta : null,
    consulto : false,
    configuro : false
    
};

export function reducer(state = initialState, action: fromSucursalesCofiguradasAction.ConfiguracionSucursalesAction): SucursalesConfiguradasState {
    switch (action.type) {
        case fromSucursalesCofiguradasAction.GET_SUCURSALES_CONFIGURACION_LOAD: {
            return {
                ...state,
                loanding: true,
                loaded: false,
                sucursales: [],
                error: '',
                idTipoRespuesta : null,
                consulto : false,
                configuro : false
            };
        }
        case fromSucursalesCofiguradasAction.GET_SUCURSALES_CONFIGURACION_LOAD_SUCCESS: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                sucursales: payload.sucursales,
                error: '',
                idTipoRespuesta : TipoRespuesta.Exito,
                consulto : true,
                configuro : false
            };
        }
        case fromSucursalesCofiguradasAction.GET_SUCURSALES_CONFIGURACION_LOAD_FAIL: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                sucursales: payload.sucursales,
                error: '',
                idTipoRespuesta : TipoRespuesta.Error,
                consulto : false,
                configuro : false
            };
        }
        case fromSucursalesCofiguradasAction.SELECT_SUCURSAL: {
            const payload = action.payload;

            let sucursalSeleccionada = {...action.payload}
            sucursalSeleccionada.seleccionado = !sucursalSeleccionada.seleccionado;
            let sucursales = JSON.parse(JSON.stringify(state.sucursales)) as SucursalesConfiguradas[];
            let indexSucursal = sucursales.findIndex(x=> x.index == sucursalSeleccionada.index );
            sucursales[indexSucursal] = sucursalSeleccionada;
            return {
                ...state,
                loanding: false,
                loaded: true,
                sucursales: sucursales,
                error: '',
                idTipoRespuesta : TipoRespuesta.Exito,
                consulto : false,
                configuro : true
            };
        }

        case fromSucursalesCofiguradasAction.ASIGNA_SUCURSAL_LOAD: {
          
            return {
                ...state,
                loanding: true,
                loaded: false,
                sucursales: state.sucursales,
                error: '',
                idTipoRespuesta : TipoRespuesta.Exito,
                consulto : false,
                configuro : true
            };
        }

        case fromSucursalesCofiguradasAction.ASIGNA_SUCURSAL_LOAD_SUCCESS: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                sucursales: payload.sucursales,
                error: '',
                idTipoRespuesta : TipoRespuesta.Exito,
                consulto : false,
                configuro : false
            };
        }
        case fromSucursalesCofiguradasAction.ASIGNA_SUCURSAL_LOAD_FAIL: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                sucursales: payload.sucursales,
                error: 'error',
                idTipoRespuesta : TipoRespuesta.Error,
                consulto : false,
                configuro : false
            };
        }
       

       
    }
}

export const getState = (state: SucursalesConfiguradasState) => state;

