import { Action } from "@ngrx/store";
import { PreguntasEvaluaciones } from "src/app/shared/models/response/evaluaciones/responderEvaluacion/preguntasEvaluaciones";
import { SeccionEvaluacion } from "src/app/shared/models/response/evaluaciones/responderEvaluacion/seccionEvaluaciones";
import { ResponderEvaluacion } from '../../../shared/models/response/evaluaciones/responderEvaluacion/responderEvaluaciones';

export const GET_EVALUACION_LOAD_V2 = '[Get Evaluacion V2] Load';
export const GET_EVALUACION_SUCCESS_V2 = '[Get Evaluacion V2] Success';
export const GET_EVALUACION_FAIL_V2 = '[Get Evaluacion V2] Fail';

export class GetEvaluacionLoadV2 implements Action {
    readonly type = GET_EVALUACION_LOAD_V2;
    // codigo de la evaluación
    constructor(public payload: string) {
    }
}
export class GetEvaluacionSuccessV2 implements Action {
    readonly type = GET_EVALUACION_SUCCESS_V2;
    // respuesa del web api
    constructor(public payload: ResponderEvaluacion) {
    }
}
export class GetEvaluacionFailV2 implements Action {
    readonly type = GET_EVALUACION_FAIL_V2;

    constructor(public payload: any) {
    }
}

export const SELECT_SUCURSAL_V2 = '[Evaluacion] Select Sucursal V2';

export class SelectSucursalEvaluacion implements Action {
    readonly type = SELECT_SUCURSAL_V2;

    constructor(public payload: number) {
    }
}

export const INICIAR_EVALUACION_V2 = '[Evaluacion] Iniciar V2';

export class IniciarEvaluacionV2 implements Action {
    readonly type = INICIAR_EVALUACION_V2;

    constructor() {
    }
}

export const AGREGA_COMENTARIO_V2 = '[Evaluacion] Add Comentario V2';

export class AgregaComentarioV2 implements Action {
    readonly type = AGREGA_COMENTARIO_V2;

    constructor(public payload: PreguntasEvaluaciones) {
    }
}

export const AGREGA_IMAGEN_V2 = '[Evaluacion] Add Image V2';
export class AgrearImagenV2 implements Action {
    readonly type = AGREGA_IMAGEN_V2;
    constructor(public payload: PreguntasEvaluaciones) {
    }
}

export const AGREGA_RESPONDER_V2 = '[Evaluacion] Responder V2';
export class ResponderPreguntaV2 implements Action {
    readonly type = AGREGA_RESPONDER_V2;
    constructor(public payload: PreguntasEvaluaciones) {
    }
}

export const RESPONDER_SIMPLE = '[Evaluacion] Responder SImple V2';
export class ResponderPreguntaSimpleV2 implements Action {
    readonly type = RESPONDER_SIMPLE;
    constructor(public payload: PreguntasEvaluaciones) {
    }
}



export const SET_INDEX_SECCION = '[Evaluacion] Set Index V2';
export class SetIndexSeccionV2 implements Action {
    readonly type = SET_INDEX_SECCION;
    constructor(public payload: number) {
    }
}

export const ADD_PREGUNTAS_MIEMBRO = '[Evaluacion] Add Preguntas Miembro SImple V2';
export class AddPreguntasMIembroV2 implements Action {
    readonly type = ADD_PREGUNTAS_MIEMBRO;
    constructor(public payload: PreguntasEvaluaciones []) {
    }
}

export const REMOVE_PREGUNTAS_MIEMBRO = '[Evaluacion] Remove Preguntas Miembro SImple V2';
export class RemovePreguntasMIembroV2 implements Action {
    readonly type = REMOVE_PREGUNTAS_MIEMBRO;
    constructor(public payload: number) {
    }
}

export const ADD_EVALUADOS_ENCUESTA = '[Evaluacion] Add Seccion Evaluados V2';
export class AgregarEvaluados implements Action {
    readonly type = ADD_EVALUADOS_ENCUESTA;
    constructor(public payload: SeccionEvaluacion) {
    }
}

export const FINALIZO_EVALUACION = '[Evaluacion] Finalizo V2 ';
export class FinalizoEvaluacion implements Action {
    readonly type = FINALIZO_EVALUACION;
    constructor(public payload: number) {
    }
}





export type EvaluacionActionV2 =
GetEvaluacionLoadV2 |
GetEvaluacionSuccessV2 |
GetEvaluacionFailV2  |
SelectSucursalEvaluacion |
IniciarEvaluacionV2 |
AgregaComentarioV2 |
AgrearImagenV2 |
ResponderPreguntaV2 |
ResponderPreguntaSimpleV2 |
SetIndexSeccionV2 |
AddPreguntasMIembroV2 |
RemovePreguntasMIembroV2 |
AgregarEvaluados |
FinalizoEvaluacion;