import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/config/config.service';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from '../error-handler.service';
import { MisEncuestasRequest } from 'src/app/shared/models/request/encuesta/mis-encuestas.request';
import { MisEvaluacionesRequest } from 'src/app/shared/models/request/evaluacion/mis-evaluaciones.request';
import { ListadoEncuestaInterna } from 'src/app/shared/models/response/encuestas/listado-encuesta-interna.model';
import { ListadoEvaluacionInterna } from 'src/app/shared/models/response/evaluaciones/listado-evaluacion-interna.model';
import { AddEncuestaRespone } from 'src/app/shared/models/response/encuestas/add-encuesta-response.model';
import { AddEncuesta } from 'src/app/shared/models/request/encuesta/addEncuesta.model';
import { DetalleEncuesta } from 'src/app/shared/models/response/encuestas/detalle-encuesta.model';
import { ListadoSecciones } from 'src/app/shared/models/response/encuestas/listado-secciones.model';
import { TipoSeccion } from 'src/app/shared/models/response/evaluaciones/Tipo-seccion.model';
import { PreguntasRequest } from 'src/app/shared/models/request/encuesta/preguntas-request.model';
import { ListadoPreguntas } from 'src/app/shared/models/response/encuestas/listado-preguntas.model';
import { RegistroPregunta } from 'src/app/shared/models/request/encuesta/registro-pregunta.model';
import { PreguntaResponse } from 'src/app/shared/models/response/encuestas/pregunta-response.model';
import { Preguntas } from 'src/app/shared/models/response/encuestas/preguntas.model';
import { ResponseBase } from 'src/app/shared/models/response/response-base.model';
import { ObtenerQuizzResponder } from 'src/app/shared/models/request/encuesta/obtener-quizz-responder.request';
import { QuizzResponse } from 'src/app/shared/models/response/encuestas/quizz-response';
import { ConfiguracionResponse } from 'src/app/shared/models/response/encuestas/configuracion-response';
import { EncuestaGruposResponse } from 'src/app/shared/models/response/encuestas/encuesta-grupos-response.model';
import { AsignarGrupoRequest } from 'src/app/shared/models/request/encuesta/asignar-grupo-request.model';
import { ConfiguracionBasicaRequest } from 'src/app/shared/models/request/encuesta/configuracion-basica.model';
import { EncuestaRequest } from 'src/app/shared/models/request/encuesta/encuesta-request.model';
import { EncuestaResponse } from 'src/app/shared/models/response/encuestas/encuesta-response';
import { PublicaEncuestaRequest } from 'src/app/shared/models/request/encuesta/publica-encuesta-request';
import { EncuestaEstadisticaResponse } from 'src/app/shared/models/response/encuestas/encuesta-estadistica-response.model';
import { EliminaEncuestaResponse } from 'src/app/shared/models/response/encuestas/encuesta-eliminada.model';
import { AddEvaluacion } from 'src/app/shared/models/request/evaluacion/add-evaluacion.model';
import { AddEvaluacionResponse } from 'src/app/shared/models/response/evaluaciones/add-evaluacion-response';
import { EvaluacionRequest } from 'src/app/shared/models/request/evaluacion/evaluacion-request.model';
import { EvaluacionResponse } from 'src/app/shared/models/response/evaluaciones/evaluacion-response.model';
import { PublicaEvaluacionRequest } from 'src/app/shared/models/request/evaluacion/publica-evaluacion-request.model';
import { EliminarEvaluacionResponse} from 'src/app/shared/models/response/evaluaciones/eliminar-evaluacion.models';
import { UsuarioAppResponse } from 'src/app/shared/models/response/grupo/usuario-app-response';
import { ListadoUsuarioEncuestaResponse } from 'src/app/shared/models/response/usuario-encuesta/listado-usuario-encuesta-response.model';
import { ListadoEncuestaSimple } from 'src/app/shared/models/response/evaluaciones/wizard/listado-encuesta-simple-response.model';
import { ResponderEvaluacion } from '../../shared/models/response/evaluaciones/responderEvaluacion/responderEvaluaciones';
import { Imagenes } from 'src/app/shared/models/response/evaluaciones/imagenes';


@Injectable({
  providedIn: 'root'
})
export class EvaluacionesService {

  headers: any;
  constructor(private config: ConfigService,
              private http: HttpClient,
              private servicesError: ErrorHandlerService
              ) {
    this.config.headers.subscribe((headers) => {
      if (headers) {
        this.headers = headers;
      }
    });
   }
   getTiposEvaluacion(model : MisEvaluacionesRequest): Observable<ListadoEvaluacionInterna> {
    return this.http.post<ListadoEvaluacionInterna>(`${environment.baseUrl}07/08`,model, { headers: this.headers })
    //.pipe(catchError(this.servicesError.erroHandler));
  }

  getTipoSeccion(idEncuesta: number): Observable<TipoSeccion> {
    return this.http.get<TipoSeccion>(`${environment.baseUrl}11/01?idEval=${idEncuesta}`, { headers: this.headers })
    .pipe(catchError(this.servicesError.erroHandler));
  }
  getResponsables(idUsuario: number): Observable<ListadoUsuarioEncuestaResponse> {
  
    return this.http.get<ListadoUsuarioEncuestaResponse>(`${environment.baseUrl}09/00?IdUsuario=${idUsuario}`, { headers: this.headers })
      .pipe(catchError(this.servicesError.erroHandler));
  }


   addEvaluacion(model: AddEvaluacion): Observable<AddEvaluacionResponse>  {
     return this.http.post<AddEvaluacionResponse>(`${environment.baseUrl}03/02`, model , { headers: this.headers } )
     .pipe(catchError(this.servicesError.erroHandler));
   }

   eliminarEvaluacion(idEncuesta : number) : Observable<EliminarEvaluacionResponse> {
    return this.http.delete<EliminarEvaluacionResponse>(`${environment.baseUrl}11/01?idEvaluacion=${idEncuesta}`,  { headers:  this.headers } )
    .pipe(catchError(this.servicesError.erroHandler));
   }
   
   getGruposUsuairosApp(idUsuario: number): Observable<UsuarioAppResponse> {
    return this.http.get<UsuarioAppResponse>(`${environment.baseUrl}01/04?IdUsuario=${idUsuario}`, { headers: this.headers })
    .pipe(catchError(this.servicesError.erroHandler));
  }
  getEncuestaSimple(idUsuario: number): Observable<ListadoEncuestaSimple>{
    return this.http.get<ListadoEncuestaSimple>(`${environment.baseUrl}07/01?idUsuario=${idUsuario}`, { headers: this.headers })
    .pipe(catchError(this.servicesError.erroHandler));
   }


   
   getDetalleEncuesta(codigo: string): Observable<DetalleEncuesta> {
    return this.http.get<DetalleEncuesta>(`${environment.baseUrl}07/02?codigo=${codigo}`, { headers: this.headers })
    .pipe(catchError(this.servicesError.erroHandler));
  }

  getSecciones(idEncuesta: number): Observable<ListadoSecciones> {
    return this.http.get<ListadoSecciones>(`${environment.baseUrl}03/04?idEncuesta=${idEncuesta}`, { headers: this.headers })
    .pipe(catchError(this.servicesError.erroHandler));
  }
  getPreguntas(request: PreguntasRequest): Observable<ListadoPreguntas> {
    const url = `${environment.baseUrl}03/05?idEncuesta=${request.idEncuesta}&idSeccion=${request.idSeccion}`;
    return this.http.get<ListadoPreguntas>(url, { headers: this.headers })
    .pipe(catchError(this.servicesError.erroHandler));
  }
  AddPregunta(request: RegistroPregunta): Observable<PreguntaResponse> {

  return this.http.post<PreguntaResponse>(`${environment.baseUrl}03/04`, request, { headers:  this.headers })
  .pipe(catchError(this.servicesError.erroHandler));
  }

  preguntaSelected(model: Preguntas): Observable<Preguntas> {
    return of(model).pipe(catchError(this.servicesError.erroHandler));
  }
  deletePregunta(idPregunta: number): Observable<ResponseBase> {
    return this.http.delete<ResponseBase>(`${environment.baseUrl}05/01?idPregunta=${idPregunta}`, { headers:  this.headers })
    .pipe(catchError(this.servicesError.erroHandler));
  }
  copyPregunta(idPregunta: number): Observable<ResponseBase> {
  return this.http.post<ResponseBase>(`${environment.baseUrl}05/00?idPregunta=${idPregunta}`, idPregunta , { headers:  this.headers })
    .pipe(catchError(this.servicesError.erroHandler));
  }
  getQuizz(model: ObtenerQuizzResponder): Observable<QuizzResponse> {
    let url = `${environment.baseUrl}07/04?codigo=${model.codigo}&idMiembro=${model.idMiembro}&preview=${model.preview}`
    return this.http.get<QuizzResponse>(url)
    .pipe(catchError(this.servicesError.erroHandler));
  }

  responderQuizz(model: QuizzResponse): Observable<QuizzResponse> {
    return this.http.post<QuizzResponse>(`${environment.baseUrl}05/01`, model,  { headers:  this.headers } )
    .pipe(catchError(this.servicesError.erroHandler));
  }
  configuracionBasica(codigo: string): Observable<ConfiguracionResponse> {
    return this.http.get<ConfiguracionResponse>(`${environment.baseUrl}07/05?codigo=${codigo}`,  { headers:  this.headers })
    .pipe(catchError(this.servicesError.erroHandler));
  }
  grupoEncuestas(request: any): Observable<EncuestaGruposResponse> {
    const url = `${environment.baseUrl}07/06?codigo=${request.codigo}&idUsuarioTemporal=${request.idUsuario}`;
    return this.http.get<EncuestaGruposResponse>(url,  { headers:  this.headers })
    .pipe(catchError(this.servicesError.erroHandler));

  }
  asignaGruposEncuesta(request: AsignarGrupoRequest): Observable<ResponseBase> {
    return this.http.post<ResponseBase>(`${environment.baseUrl}07/02`, request,  { headers:  this.headers } )
    .pipe(catchError(this.servicesError.erroHandler));
  }

  enviaConfiguracionBasica(request: ConfiguracionBasicaRequest): Observable<ConfiguracionResponse> {
    return this.http.put<ConfiguracionResponse>(`${environment.baseUrl}07/01`, request,  { headers:  this.headers } )
    .pipe(catchError(this.servicesError.erroHandler));
  }
  sendEvaluacion(request : EvaluacionRequest) : Observable<EvaluacionResponse> {
    return this.http.post<EvaluacionResponse>(`${environment.baseUrl}07/03`, request,  { headers:  this.headers } )
    .pipe(catchError(this.servicesError.erroHandler));
  }
  publicaEvaluacion(request : PublicaEvaluacionRequest) : Observable<ResponseBase> {
    return this.http.post<ResponseBase>(`${environment.baseUrl}07/04`, request,  { headers:  this.headers } )
    .pipe(catchError(this.servicesError.erroHandler));
  }
  getEvaluacionWizard(codigo : string) : Observable<EvaluacionResponse> {
    return this.http.get<EvaluacionResponse>(`${environment.baseUrl}07/09?codigo=${codigo}`,  { headers:  this.headers } )
    .pipe(catchError(this.servicesError.erroHandler));
  }
  getEncuestaEstatisticas(codigo : string) : Observable<EncuestaEstadisticaResponse> {
    return this.http.get<EncuestaEstadisticaResponse>(`${environment.baseUrl}03/06?codigo=${codigo}`,  { headers:  this.headers } )
    .pipe(catchError(this.servicesError.erroHandler));
  }
  eliminarEncuesta(idEncuesta : number) : Observable<EliminaEncuestaResponse> {
    return this.http.delete<EliminaEncuestaResponse>(`${environment.baseUrl}07/02?idEncuesta=${idEncuesta}`,  { headers:  this.headers } )
    .pipe(catchError(this.servicesError.erroHandler));
  }

  getEvaluacionLoadV2(codigo : string): Observable<ResponderEvaluacion> {
    let url = `${environment.baseUrl}11/01?codigo=${codigo}`
    return this.http.get<ResponderEvaluacion>(url).pipe(catchError(this.servicesError.erroHandler));
  }

  deleteImg(idimagen: number): Observable<any> {
    let url = `${environment.baseUrl}11/02?idimg=${idimagen}`
    return this.http.delete<any>(url).pipe(catchError(this.servicesError.erroHandler));
  }

  getRespuesta(idEvaluacion : number, idRespuesta : number, idResponsable : number): Observable<any> {
    let url = `${environment.baseUrl}11/04?idEvaluacion=${idEvaluacion}&idRespuesta=${idRespuesta}&idResponsable=${idResponsable}`
    return this.http.get<ResponderEvaluacion>(url).pipe(catchError(this.servicesError.erroHandler));
  }

  respuestaPreguntaEvaluacion(request : ResponderEvaluacion) : Observable<ResponderEvaluacion> {
    return this.http.post<ResponderEvaluacion>(`${environment.baseUrl}11/06`, request,  { headers:  this.headers } )
    .pipe(catchError(this.servicesError.erroHandler));
  }

  enviarEmailResponsables(request : ResponderEvaluacion) : Observable<ResponderEvaluacion> {
    return this.http.post<ResponderEvaluacion>(`${environment.baseUrl}11/07`, request,  { headers:  this.headers } )
    .pipe(catchError(this.servicesError.erroHandler));
  }

  postImg(model: Imagenes): Observable<Imagenes> {
    return this.http.post<Imagenes>(`${environment.baseUrl}11/08`, model, { headers:  this.headers } )
    .pipe(catchError(this.servicesError.erroHandler));
  }
}
