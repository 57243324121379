import { ResponseBase } from '../response-base.model';
export class UsuarioCompartirResponse extends ResponseBase {
    id : number;
    nombre : string;
    paterno : string;
    materno : string;
    codigo : string;
    email : string;
    cargo : string;
    
}
