// Developer
export const environment1 = {
  production: true,
  baseUrl: 'http://localhost:55683/',
  baseUrlOld: 'http://apiquizz.aguapuebla.mx/',
  urlQuizz : 'http://localhost:4200/',
  urlMICL : 'https://micrositio.aguapuebla.mx/api/Encuestas/ActualizarEstadoEncuesta'
};
//QA
export const environment0 = {
  production: true,
  baseUrl: 'http://newapiquizz.aguapuebla.mx/',
  baseUrlOld: 'http://apiquizz.hi2o.mx/',
  urlQuizz : 'http://newfrontendquizz.aguapuebla.mx/',
  urlMICL : 'https://micrositio.aguapuebla.mx/api/Encuestas/ActualizarEstadoEncuesta'
};
// Pro
export const environment = {
  production: true,
  baseUrl: 'https://apiquizz.aguapuebla.mx/',
  baseUrlOld: 'http://apiquizz.hi2o.mx/',
  urlQuizz : 'https://quizz.aguapuebla.mx/',
  urlMICL : 'https://micrositio.aguapuebla.mx/api/Encuestas/ActualizarEstadoEncuesta'
};
