
import { NavbarSidebarService } from '../../services/navbar-sidebar/navbar-sidebar.service';

import { MensajesAppService } from 'src/app/services/mensajes-app.service';
import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/config/config.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/observable';
import { Action } from '@ngrx/store';
import * as fromOptionSelect from '../actions/option.select-action';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
@Injectable()
export class OptionSelectEffects {
    constructor(private actions$: Actions,
       
        private userSelectOption: NavbarSidebarService,
       
        private servicesMessage: MensajesAppService,
        private config: ConfigService,
        ) {
        }
    @Effect()
    userSelectOption$: Observable<Action> = this.actions$.pipe(
         ofType(fromOptionSelect.USER_SELECT_OPTION),
         map((action: fromOptionSelect.UserSelectOption) => action.payload),
         switchMap( (payload)  => this.userSelectOption.getSeleccionUser(payload).pipe(
             map(response => {
                 return new fromOptionSelect.UserSelectOptionSuccess(response);
             }, catchError(error => of(new fromOptionSelect.UserSelectOptionSuccessFail(error))))
         ))
     );
}