import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ConfigService } from 'src/app/config/config.service';
import { MensajesAppService } from 'src/app/services/mensajes-app.service';
import * as fromUsuarioGruposActions from '../actions/usuarios-grupos.action';
import { UsuarioGrupoService } from '../../services/usuario-grupos/usuario-grupo.service';
import { Observable } from 'rxjs/observable';
import { Action } from '@ngrx/store';
import { TipoRespuesta } from 'src/app/shared/models/enums/enumTipoRespuesta.model';

@Injectable()
export class UsuarioGruposEffects {
    constructor(private actions$: Actions,
        private servicesMessage: MensajesAppService,
        private config: ConfigService,
        private servicesUsuarioGrupo: UsuarioGrupoService
        ) {
        }
    @Effect()
    usuariosGrupos$ = this.actions$.pipe(ofType(fromUsuarioGruposActions.USUARIO_GRUPO_LOAD),
        map((action: fromUsuarioGruposActions.UsuarioGruposLoad) => action.payload),
        switchMap((payload) => this.servicesUsuarioGrupo.getListadoUsuariosGrupos(payload)
        .pipe(
            map(response => {
                 if (response.tipoRespuesta === TipoRespuesta.Error
                    || response.tipoRespuesta === TipoRespuesta.Advertencia) {
                        return new fromUsuarioGruposActions.UsuarioGruposFail(response);
                    } else {
                        return new fromUsuarioGruposActions.UsuarioGruposSuccess(response);
                    }
            },
            catchError(error => {return of(new fromUsuarioGruposActions.UsuarioGruposFail(error)); }  )
            )
         )
        )
    );
    @Effect()
    usuariosGruposInsert$ = this.actions$.pipe(ofType(fromUsuarioGruposActions.USUARIO_GRUPO_ADD),
        map((action: fromUsuarioGruposActions.UsuarioGruposAdd) => action.payload),
        switchMap((payload) => this.servicesUsuarioGrupo.insertUsuarioGrupo(payload)
        .pipe(
            map(response => {
                this.servicesMessage.messageBox(response.tipoRespuesta, response.mensaje);
                 if (response.tipoRespuesta !== TipoRespuesta.Exito) {
                        return new fromUsuarioGruposActions.UsuarioGruposAddFail(response);
                    } else {
                        return new fromUsuarioGruposActions.UsuarioGruposAddSuccess(response);
                    }
            },
            catchError(error => {return of(new fromUsuarioGruposActions.UsuarioGruposAddFail(error)); }  )
            )
         )
        )
    );
    @Effect()
    usuariosGruposUpdate$ = this.actions$.pipe(ofType(fromUsuarioGruposActions.USUARIO_GRUPO_EDIT),
        map((action: fromUsuarioGruposActions.UsuarioGruposEdit) => action.payload),
        switchMap((payload) => this.servicesUsuarioGrupo.updateUsuarioGrupo(payload)
        .pipe(
            map(response => {
                this.servicesMessage.messageBox(response.tipoRespuesta, response.mensaje);
                 if (response.tipoRespuesta !== TipoRespuesta.Exito) {
                        return new fromUsuarioGruposActions.UsuarioGruposEditFail(response);
                    } else {
                        return new fromUsuarioGruposActions.UsuarioGruposEditSuccess(response);
                    }
            },
            catchError(error => {return of(new fromUsuarioGruposActions.UsuarioGruposEditFail(error)); }  )
            )
         )
        )
    );
    @Effect()
    usuariosGruposDelete$ = this.actions$.pipe(ofType(fromUsuarioGruposActions.USUARIO_GRUPO_DELETE),
        map((action: fromUsuarioGruposActions.UsuarioGruposDelete) => action.payload),
        switchMap((payload) => this.servicesUsuarioGrupo.deleteUsuarioGrupo(payload)
        .pipe(
            map(response => {
                this.servicesMessage.messageBox(response.tipoRespuesta, response.mensaje);
                 if (response.tipoRespuesta !== TipoRespuesta.Exito) {
                        return new fromUsuarioGruposActions.UsuarioGruposDeleteFail(response);
                    } else {
                        return new fromUsuarioGruposActions.UsuarioGruposDeleteSuccess(response);
                    }
            },
            catchError(error => {return of(new fromUsuarioGruposActions.UsuarioGruposDeleteFail(error)); }  )
            )
         )
        )
    );
    @Effect()
    getLayoutMiembros$ : Observable<Action> = this.actions$.pipe(
        ofType(fromUsuarioGruposActions.USUARIO_GET_LAYOUT),
        switchMap(() => this.servicesUsuarioGrupo.getFileLayout()
        .pipe(map(response => {
           
            return new fromUsuarioGruposActions.UsuarioGetLayoutSuccess(response);
        }), catchError(error => of(new fromUsuarioGruposActions.UsuarioGetLayoutFail(error))))
    ));
    @Effect()
    importUsuario$ = this.actions$.pipe(ofType(fromUsuarioGruposActions.USUARIO_IMPORT_LOAD),
        map((action: fromUsuarioGruposActions.UsuarioImportLoad) => action.payload),
        switchMap((payload) => this.servicesUsuarioGrupo.importaMiembros(payload)
        .pipe(
            map(response => {
                this.servicesMessage.messageBox(response.tipoRespuesta, response.mensaje);
                 if (response.tipoRespuesta !== TipoRespuesta.Exito) {
                        return new fromUsuarioGruposActions.UsuarioImportLoadFail(response);
                    } else {
                        return new fromUsuarioGruposActions.UsuarioImportLoadSuccess(response);
                    }
            },
            catchError(error => {return of(new fromUsuarioGruposActions.UsuarioImportLoadFail(error)); }  )
            )
         )
        )
    );

    
    


}
