import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MensajesAppService } from 'src/app/services/mensajes-app.service';
import { TipoRespuesta } from 'src/app/shared/models/enums/enumTipoRespuesta.model';
import { EncuestaInternaService } from '../../services/encuestas/encuesta-interna.service';
import * as fromConfiguracion from '../actions/configuracion-encuesta-action';

@Injectable()
export class ConfiguracionEffects {
    constructor(private actions$: Actions,
                private encuestaServices: EncuestaInternaService,
                private messageServices: MensajesAppService,
                private toastr: ToastrService) {
        }
        @Effect()
        configuracionEncuesta$: Observable<Action> = this.actions$.pipe(
            ofType(fromConfiguracion.GET_CONFIGURACION_BASICA_LOAD),
            map((action: fromConfiguracion.ConfiguracionBasicaLoad) => action.payload),
            switchMap( (payload)  => this.encuestaServices.configuracionBasica(payload).pipe(
                map(response => {
                    return new fromConfiguracion.ConfiguracionBasicaSuccess(response);
                }, catchError(error => of(new fromConfiguracion.ConfiguracionBasicaFail(error))))
            ))
        );
        @Effect()
        configuracionGrupos$: Observable<Action> = this.actions$.pipe(
            ofType(fromConfiguracion.GET_CONFIGURACION_GRUPOS),
            map((action: fromConfiguracion.ConfiguracionGrupos) => action.payload),
            switchMap( (payload)  => this.encuestaServices.grupoEncuestas(payload).pipe(
                map(response => {
                    return new fromConfiguracion.ConfiguracionGruposSuccess(response);
                }, catchError(error => of(new fromConfiguracion.ConfiguracionGruposFail(error))))
            ))
        );
        @Effect()
        sendGruposEncuesta$: Observable<Action> = this.actions$.pipe(
            ofType(fromConfiguracion.SEND_CONFIGURACION_GRUPOS),
            map((action: fromConfiguracion.SendGrupoEncuesta) => action.payload),
            switchMap( (payload)  => this.encuestaServices.asignaGruposEncuesta(payload).pipe(
                map(response => {
                    this.messageServices.messageBox( response.tipoRespuesta, response.mensaje);
                    return new fromConfiguracion.SendGrupoEncuestaSuccess(response);
                }, catchError(error => of(new fromConfiguracion.SendGrupoEncuestaFail(error))))
            ))
        );

        @Effect()
        sendConfiguracionBasica$: Observable<Action> = this.actions$.pipe(
            ofType(fromConfiguracion.SEND_CONFIGURACION_BASICA),
            map((action: fromConfiguracion.SendConfiguracionBasica) => action.payload),
            switchMap( (payload)  => this.encuestaServices.enviaConfiguracionBasica(payload).pipe(
                map(response => {
                    let tipoEncuesta ='Encuesta';
                   if(response.configuracionBasica.idTipoEncuesta > 2){
                       tipoEncuesta = 'Evaluación'
                   }
                   if(response.tipoRespuesta === TipoRespuesta.Exito){
                 

                   if(!response.configuracionBasica.configuraFecha) {
                       if(response.configuracionBasica.habilitado) {
                        this.toastr.success(tipoEncuesta + ' publicada', '¡Proceso exitoso!');
                       } else {
                        this.toastr.warning(tipoEncuesta + ' no publicada', '¡Proceso exitoso!');
                       }
                   
                   } else {
                       if(response.configuracionBasica.fechaExpiracion == null) {
                        this.toastr.success('Fecha vencimiento no configurada', '¡Proceso exitoso!');
                       } else {
                        this.toastr.success('Fecha vencimiento configurada', '¡Proceso exitoso!');
                       }
                    
                   }

                    return new fromConfiguracion.SendConfiguracionBasicaSuccess(response);
                   } else {
                    this.toastr.error(response.mensaje, 'Error');
                    return new fromConfiguracion.SendConfiguracionBasicaFail(response);
                   }
                   
                }, catchError(error => of(new fromConfiguracion.SendConfiguracionBasicaFail(error))))
            ))
        );
}
