import { Action } from "@ngrx/store";

export const OPEN_MODAL_PERFIL= '[Modal] Perfil Open';
export const CLOSE_MODAL_PERFIL= '[Modal] Perfil Close';


export class OpenModalPerfil implements Action {
    readonly type = OPEN_MODAL_PERFIL;
    constructor() { }
}

export class CloseModalPerfil implements Action {
    readonly type = CLOSE_MODAL_PERFIL;
    constructor() { }
}

export type ModalPerfilAction =
OpenModalPerfil | CloseModalPerfil;