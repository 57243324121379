import * as fromCollapseCompartir from '../actions/collapse-compartir.action';


export interface OpcionCollpaseCompartirState {
    opcionCollapse : number;
 }

 export const initialState: OpcionCollpaseCompartirState = {
    opcionCollapse : 0
 }
 
 export function reducer(state = initialState, action: fromCollapseCompartir.SelectOpcionCollapseAction ): OpcionCollpaseCompartirState {
    switch (action.type) {

        case fromCollapseCompartir.COLLAPSE_SELECT_COMPARTIR :
            const payload = action.payload;

            return {
                ...state,
                opcionCollapse : payload
            }

            break;

      
    }
}