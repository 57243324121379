
import { ListadoPreguntas } from 'src/app/shared/models/response/encuestas/listado-preguntas.model';
import * as fromActionEncuestas from '../actions/encuestas-internas.action';


export interface PreguntasState {
    loanding: boolean;
    loaded: boolean;
    listado:  ListadoPreguntas;
    error: string;
}
export const initialState: PreguntasState = {
    loanding: false,
    loaded : false,
    listado : new ListadoPreguntas(),
    error: ''
};


export function reducer(state = initialState, action: fromActionEncuestas.EncuestaInternaAction ): PreguntasState {
    switch (action.type) {
        case fromActionEncuestas.PREGUNTAS_LOAD :
             return {
                 ...state,
                 loanding: true,
                 loaded : false,
                 listado : new ListadoPreguntas(),
                 error : ''
             };
             break;
             case fromActionEncuestas.PREGUNTAS_LOAD_SUCCESS :
                const payload = action.payload;
                return {
                    ...state,
                    loanding: false,
                    loaded : true,
                    listado : payload ,
                    error : ''
                };
            break;
            case fromActionEncuestas.PREGUNTAS_LOAD_FAIL :
                const error = action.payload;
                return {
                    ...state,
                    loanding: false,
                    loaded : false,
                    listado: new ListadoPreguntas(),
                    error : error
                };

                break;
    }
}

export const getState = (state: PreguntasState) => state;


