import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "src/app/config/config.service";
import { ErrorHandlerService } from "../error-handler.service";

import { environment } from "src/environments/environment";
import { Observable } from "rxjs/observable";
import { catchError } from "rxjs/operators";
import { ListadoSucursalResponse } from "src/app/shared/models/response/sucursales/listado-sucursal-response";
import { ListadoSucursalesConfiguracionResponse } from "src/app/shared/models/response/sucursales/listado-sucursales-configuracion.model";
import { SucursalResponse } from "src/app/shared/models/response/sucursales/sucursal-response";
import { SucursalRequest } from "src/app/shared/models/request/sucursal/sucursal-request.model";
import { EliminaSucursalResponse } from "src/app/shared/models/response/sucursales/elimina-sucursal-response.model";
import { SucursalesConfiguradas } from "src/app/shared/models/response/sucursales/sucursales-configuradas.model";
import { AnalisisGraficasSucursalesResponse } from "src/app/shared/models/response/sucursales/analisis-graficas-sucursales-response.model";
import { VerificaRespuetasCarruselResponse } from "src/app/shared/models/response/sucursales/verifica-respuestas-carusel.response";


@Injectable({
  providedIn: 'root'
})
export class SucuralesService {
  headers: any;
  constructor(private config: ConfigService,
    private http: HttpClient,
    private servicesError: ErrorHandlerService) {
    this.config.headers.subscribe((headers) => {
      if (headers) {
        this.headers = headers;
      }
    });
  }

  getSucursales(idUsuario: number): Observable<ListadoSucursalResponse> {
    return this.http.get<ListadoSucursalResponse>(`${environment.baseUrl}10/00?idUsuario=${idUsuario}`, { headers: this.headers })
      .pipe(catchError(this.servicesError.erroHandler));
  }
  getSucursalesConfiguradas(request: any): Observable<ListadoSucursalesConfiguracionResponse> {
    let url = `${environment.baseUrl}10/01?idUsuario=${request.idUsuario}&idEncuesta=${request.idEncuesta}`;
    return this.http.get<ListadoSucursalesConfiguracionResponse>(url, { headers: this.headers })
      .pipe(catchError(this.servicesError.erroHandler));
  }
  addSucursal(model: SucursalRequest): Observable<SucursalResponse> {
    return this.http.post<SucursalResponse>(`${environment.baseUrl}10/00`, model, { headers: this.headers })
      .pipe(catchError(this.servicesError.erroHandler));
  }

  deleteSucursal(idSucursal: number): Observable<EliminaSucursalResponse> {
    return this.http.delete<EliminaSucursalResponse>(`${environment.baseUrl}10/00?idSucursal=${idSucursal}`, { headers: this.headers })
      .pipe(catchError(this.servicesError.erroHandler));
  }
  editSucursal(model: SucursalRequest): Observable<SucursalResponse> {
    return this.http.put<SucursalResponse>(`${environment.baseUrl}10/00`, model, { headers: this.headers })
      .pipe(catchError(this.servicesError.erroHandler));
  }
  asignarSucursales(model: SucursalesConfiguradas []): Observable<ListadoSucursalesConfiguracionResponse> {
    return this.http.post<ListadoSucursalesConfiguracionResponse>(`${environment.baseUrl}10/01`, model, { headers: this.headers })
      .pipe(catchError(this.servicesError.erroHandler));
  }

  getAnalisisSucursales(request: any): Observable<any> {
    let url = `${environment.baseUrl}10/02?codigo=${request.codigo}&idSucursal=${request.idSucursal}`;
    return this.http.get<any>(url, { headers: this.headers })
      .pipe(catchError(this.servicesError.erroHandler));
  }

  getVerificaRespuestasSucursales(idEncuesta: number): Observable<VerificaRespuetasCarruselResponse> {
    let url = `${environment.baseUrl}10/03?idEncuesta=${idEncuesta}`;
    return this.http.get<VerificaRespuetasCarruselResponse>(url, { headers: this.headers })
      .pipe(catchError(this.servicesError.erroHandler));
  }


}