import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DashBoardServices } from 'src/app/services/dashboard/dashboard.services';
import { MensajesAppService } from 'src/app/services/mensajes-app.service';
import * as fromDashBoard from '../actions/dashboard-genera.action';


@Injectable()
export class DashBoardEffects {
    constructor(private actions$: Actions,
                private dashBoardServices: DashBoardServices,
                private messageServices: MensajesAppService) {
        }
        @Effect()
        dashBoardGeneral$: Observable<Action> = this.actions$.pipe(
            ofType(fromDashBoard.DASHBOARD_GENERAL_LOAD),
            switchMap(() => this.dashBoardServices.getDashBoardGeneral(localStorage.idUsuario)
            .pipe(map(response => {
                return new fromDashBoard.DashBoardSuccess(response);
            }), catchError(error => of(new fromDashBoard.DashBoardFail(error))))
        ));
}