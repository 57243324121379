import { Action } from '@ngrx/store';
import { UsuarioEvaluado } from 'src/app/shared/models/response/evaluaciones/usurioEvaluado';

export const USUARIO_EVALUADO_ADD = '[Usuario Evaluado] Evaluado Add';

export class AgregarUserEValuado implements Action {

    readonly type = USUARIO_EVALUADO_ADD;
   
    constructor(public payload: UsuarioEvaluado) { }
}

export const SELECCIONA_SUCURSAL_A_EVALUAR = '[Usuario Evaluado] Elige Sucursal';

export class EligeSucursalaEvaluar implements Action {
    readonly type = SELECCIONA_SUCURSAL_A_EVALUAR;
    constructor(public payload: number) {
    }
}

// export const REGISTRA_EVALUADOS_SERVER = '[Evaluado Wizard EV] Send Server';
// export class RegistraEvaluacionServer implements Action{
//     readonly type = REGISTRA_EVALUADOS_SERVER;
//     //constructor(public payload: EvaluacionRequest) { }
// }



export type UserEvaluacionAction = 
AgregarUserEValuado |
EligeSucursalaEvaluar;