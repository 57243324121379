import { Action } from '@ngrx/store';
import { EvaluacionRequest } from 'src/app/shared/models/request/evaluacion/evaluacion-request.model';
import { PublicaEvaluacionRequest } from 'src/app/shared/models/request/evaluacion/publica-evaluacion-request.model';
import { EvaluacionResponse } from 'src/app/shared/models/response/evaluaciones/evaluacion-response.model';
import { PreguntasEV } from 'src/app/shared/models/response/evaluaciones/preguntas-ev.model';
import { DatosGeneralesEV } from 'src/app/shared/models/response/evaluaciones/wizard/datos-generales-ev.model';
import { EncuestaSimple } from 'src/app/shared/models/response/evaluaciones/wizard/encuesta-simple.model';
import { SeccionesWizardEV } from 'src/app/shared/models/response/evaluaciones/wizard/secciones-wizard-ev';
import { ResponseBase } from 'src/app/shared/models/response/response-base.model';
import { UsuarioGet } from 'src/app/shared/models/response/usuario-encuesta/usuario-get.model';


export const SECCION_EVALUACION_ADD = '[Seccion Wizard EV] Evaluación Add';
export const SECCION_EVALUACION_SELECTED = '[Seccion Wizard EV] Evaluación Selected';
export const RESET_EVALUACION = '[Reset Wizard EV] Encuesta';
export const SET_DATOS_GENERALES_EV = '[Set Datos Wizard EV] Generales';
export const PREGUNTA_ADD_EV = '[Pregunta Wizard EV] Evaluación Add';
export const PREGUNTA_DELETE_EV = '[Pregunta Wizard EV] Evaluación Delete';
export const PREGUNTA_COPIAR_EV = '[Pregunta Wizard EV] Evaluación Copy';
export const SELECT_EDIT_EV = '[Pregunta Wizard EV] Evaluación Select Edit';
export const CLOSE_EDIT_EV = '[Pregunta Wizard EV] Evaluación Close Edit';
export const EDIT_PREGUNTA_EV = '[Pregunta Wizard EV] Edit';
export const CREAR_EVALUACION = '[Pregunta Wizard EV] state';
export const SET_TIPO_EVALUACION = '[Pregunta Wizard EV] Set Tipo Evaluación';

export const SELECT_SECCION_CONFIGURACION_EV = '[Seccion Wizard EV] Seccion Configuracion';

export class SetTipoEvaluacion implements Action{
    readonly type = SET_TIPO_EVALUACION;
    constructor(public payload: number) { }
    
}

export class CloseEditEV implements Action{
    readonly type = CLOSE_EDIT_EV;
    constructor() { }

    
}


export const REGISTRA_EVALUACION_SERVER = '[Pregunt Wizard EV] Send Server';
export class RegistraEvaluacionServer implements Action{
    readonly type = REGISTRA_EVALUACION_SERVER;
    constructor(public payload: EvaluacionRequest) { }
}

export const REGISTRA_EVALUACION_SERVER_SUCCESS = '[Pregunt Wizard EV] Send Server Success';
export class RegistraEvaluacionServerSuccess implements Action{
    readonly type = REGISTRA_EVALUACION_SERVER_SUCCESS;
    constructor(public payload: EvaluacionResponse) { }
}

export const REGISTRA_EVALUACION_SERVER_FAIL = '[Pregunt Wizard EV] Send Server Fail';
export class RegistraEvaluacionServerFail implements Action{
    readonly type = REGISTRA_EVALUACION_SERVER_FAIL;
    constructor(public payload: any) { }
}


export class SetDatosGeneralesEvaluacion implements Action {
    readonly type = SET_DATOS_GENERALES_EV;
    constructor(public payload: DatosGeneralesEV) { }
}
export class CreaEvaluacion implements Action {
    readonly type = CREAR_EVALUACION;
    constructor() { }
}

export class SeccionAgregarEV implements Action {
    readonly type = SECCION_EVALUACION_ADD;
    constructor(public payload: SeccionesWizardEV) { }
}
export class SeccionSelectedEV implements Action {
    readonly type = SECCION_EVALUACION_SELECTED;
    constructor(public payload: number) { }
}
export class ResetEvaluacion implements Action {
    readonly type = RESET_EVALUACION;
    constructor() { }
}
export class PreguntaAddWizardEV implements Action {
    readonly type = PREGUNTA_ADD_EV;
    constructor(public payload: PreguntasEV) { }
}

export class DeletePreguntaEV implements Action {
    readonly type = PREGUNTA_DELETE_EV;
    constructor(public payload: PreguntasEV) { }
}

export class CopiarPreguntaEV implements Action {
    readonly type = PREGUNTA_COPIAR_EV;
    constructor(public payload: PreguntasEV) { }
}

export class SelectEditEV implements Action {
    readonly type = SELECT_EDIT_EV;
    constructor(public payload: PreguntasEV) { }
}
export class EditPreguntaEV implements Action {
    readonly type = EDIT_PREGUNTA_EV;
    constructor(public payload: PreguntasEV) { }
}

export const GET_EVALUACION_WIZARD = '[Evaluación Wizard] Get';
export const GET_EVALUACION_WIZARD_SUCCESS = '[Evaluación Wizard] Get Success';
export const GET_EVALUACION_WIZARD_FAIL = '[Evaluación Wizard] Get Fail';

export class GetEvaluacionWizard implements Action {
    readonly type = GET_EVALUACION_WIZARD;
    constructor(public payload: string) { }
}

export class GetEvaluacionWizardSuccess implements Action {
    readonly type = GET_EVALUACION_WIZARD_SUCCESS;
    constructor(public payload: EvaluacionResponse) { }
}

export class GetEvaluacionWizardFail implements Action {
    readonly type = GET_EVALUACION_WIZARD_FAIL;
    constructor(public payload: any) { }
}

export const PUBLICA_EVALUACION_WIZARD_LOAD = '[Publica Wizard] Load';
export const PUBLICA_EVALUACION_WIZARD_SUCCESS = '[Publica Evaluación Wizard] Success';
export const PUBLICA_EVALUACION_WIZARD_FAIL = '[Publica Evaluación Wizard] Fail';

export class PublicaEvaluacionLoad implements Action {
    readonly type = PUBLICA_EVALUACION_WIZARD_LOAD;
    constructor(public payload: PublicaEvaluacionRequest) { }
}

export class PublicaEvaluacionSuccess implements Action {
    readonly type = PUBLICA_EVALUACION_WIZARD_SUCCESS;
    constructor(public payload: ResponseBase) { }
}

export class PublicaEvaluacionFail implements Action {
    readonly type = PUBLICA_EVALUACION_WIZARD_FAIL;
    constructor(public payload: any) { }
}

export const SET_SEND_SERVER_EV = '[Publicar Wizard EV] Set Send Server';

export class SetSendServerWizardEV implements Action {
    readonly type = SET_SEND_SERVER_EV;
    constructor(public payload: boolean) { }
}
export const SET_CONSULTO_SERVER_EV = '[Publicar Wizard EV] Consulta server';

export class SetConsultaServerEV implements Action {
    readonly type = SET_CONSULTO_SERVER_EV;
    constructor(public payload: boolean) { }
}

export class SelectSeccionConfiguracionEV implements Action {
    readonly type = SELECT_SECCION_CONFIGURACION_EV;
    constructor(public payload: SeccionesWizardEV) { }
}

export const CLOSE_SECCION_CONFIGURACION_EV = '[Seccion Wizard EV] Close Seccion Configuracion';

export class CloseSeccionConfigutacionEV implements Action{
    readonly type = CLOSE_SECCION_CONFIGURACION_EV;
    constructor(public payload: SeccionesWizardEV) { }

}


export const CLOSE_ALL_SECCION_CONFIGURACION_EV = '[Seccion Wizard EV] Close All Seccion Configuracion';

export class CloseAllSeccionConfigutacionEV implements Action{
    readonly type = CLOSE_ALL_SECCION_CONFIGURACION_EV;
    constructor() { }

}

export const CHANGE_NOMBRE_SECCION_EV = '[Seccion Wizard EV] Change Nombre';

export class ChangeNombreSeccionEV implements Action{
    readonly type = CHANGE_NOMBRE_SECCION_EV;
    constructor(public payload: any) {  }
}

export const CHANGE_ENCUESTA_SECCION_EV = '[Seccion Wizard EV] Change Encuesta';

export class ChangeEncuestaSeccionEV implements Action{
    readonly type = CHANGE_ENCUESTA_SECCION_EV;
    constructor(public payload: any) {  }
}

export const ELIMINAR_SECCION_EV = '[Seccion EV] Eliminar';

export class EliminarSeccionEV implements Action{
    readonly type = ELIMINAR_SECCION_EV;
    constructor(public payload: number) {  }

}

export const RESPONSABLES_LOAD = '[Responsable] Load ';
export const RESPONSABLES_LOAD_SUCCESS = '[Responsable] Success';
export const RESPONSABLES_LOAD_FAIL = '[Responsable] Fail';
export class ResponsablesLoad implements Action{
    readonly type = RESPONSABLES_LOAD;
    constructor(public payload: number) {  }

}
export class ResponsablesLoadSuccess implements Action{
    readonly type = RESPONSABLES_LOAD_SUCCESS;
    constructor(public payload: UsuarioGet[]) {  }
}

export class ResponsablesLoadFail implements Action{
    readonly type = RESPONSABLES_LOAD_FAIL;
    constructor(public payload: any) {  }
}


export const ENCUESTAS_SIMPLE_LOAD = '[Encuesta] Simple Load ';
export const ENCUESTAS_SIMPLE_LOAD_SUCCESS = '[Encuesta] Simple Success';
export const ENCUESTAS_SIMPLE_LOAD_FAIL = '[Encuesta] Simple Fail';
export class EncuestaSimpleLoad implements Action{
    readonly type = ENCUESTAS_SIMPLE_LOAD;
    constructor(public payload: number) {  }

}
export class EncuestaSimpleLoadSuccess implements Action{
    readonly type = ENCUESTAS_SIMPLE_LOAD_SUCCESS;
    constructor(public payload: Array<EncuestaSimple>) {  }
}

export class EncuestaSimpleLoadFail implements Action{
    readonly type = ENCUESTAS_SIMPLE_LOAD_FAIL;
    constructor(public payload: any) {  }
}





export type WizardEvaluacionAction = 
SeccionAgregarEV |
SeccionSelectedEV |
ResetEvaluacion |
SetDatosGeneralesEvaluacion |
PreguntaAddWizardEV |
DeletePreguntaEV |
CopiarPreguntaEV |
SelectEditEV |
EditPreguntaEV |
CreaEvaluacion |
RegistraEvaluacionServer |
RegistraEvaluacionServerSuccess |
RegistraEvaluacionServerFail |
GetEvaluacionWizard |
GetEvaluacionWizardSuccess |
GetEvaluacionWizardFail |
SetTipoEvaluacion  |
PublicaEvaluacionLoad |
PublicaEvaluacionSuccess |
PublicaEvaluacionFail |
SetSendServerWizardEV |
SetConsultaServerEV |
SelectSeccionConfiguracionEV |
CloseSeccionConfigutacionEV  |
CloseAllSeccionConfigutacionEV |
ChangeNombreSeccionEV |
EliminarSeccionEV | 
CloseEditEV |
ResponsablesLoad |
ResponsablesLoadSuccess |
ResponsablesLoadFail |
EncuestaSimpleLoad |
EncuestaSimpleLoadSuccess |
EncuestaSimpleLoadFail |
ChangeEncuestaSeccionEV ;
