import { Action } from '@ngrx/store';
import { AddEvaluacion } from 'src/app/shared/models/request/evaluacion/add-evaluacion.model';
import { AddEvaluacionResponse } from 'src/app/shared/models/response/evaluaciones/add-evaluacion-response';

// Filtro Evaluaciones
export const EVALUACION_ADD = '[Evaluacion] Add';
export const EVALUACION_ADD_SUCCESS = '[Evaluacion] Add Success';
export const EVALUACION_ADD_FAIL = '[Evaluacion] Add Fail';

export class EvaluacionAdd implements Action {
    readonly type = EVALUACION_ADD;
    constructor(public payload: AddEvaluacion) { }
}

export class EvaluacionAddSuccess implements Action {
    readonly type = EVALUACION_ADD_SUCCESS;
    constructor(public payload: AddEvaluacionResponse) { }
}

export class EvaluacionAddFail implements Action {
    readonly type = EVALUACION_ADD_FAIL;
    constructor(public payload: any) { }
}


export type EvaluacionAltaAction =
EvaluacionAdd |
EvaluacionAddSuccess |
EvaluacionAddFail ;

