import { Action } from '@ngrx/store';
import { EncuestaRequest } from 'src/app/shared/models/request/encuesta/encuesta-request.model';
import { PublicaEncuestaRequest } from 'src/app/shared/models/request/encuesta/publica-encuesta-request';
import { EncuestaResponse } from 'src/app/shared/models/response/encuestas/encuesta-response';
import { Preguntas } from 'src/app/shared/models/response/encuestas/preguntas.model';
import { DatosGenerales } from 'src/app/shared/models/response/encuestas/wizard/datos-generales.model';
import { SeccionesWizard } from 'src/app/shared/models/response/encuestas/wizard/secciones-wizard';
import { ResponseBase } from 'src/app/shared/models/response/response-base.model';


export const SECCION_ENCUESTA_ADD = '[Seccion Wizard] Encuesta Add';
export const SECCION_ENCUESTA_SELECTED = '[Seccion Wizard] Encuesta Selected';
export const RESET_ENCUESTA = '[Reset Wizard] Encuesta';
export const SET_DATOS_GENERALES = '[Set Datos Wizard] Generales';
export const PREGUNTA_ADD = '[Pregunta Wizard] Encuesta Add';
export const PREGUNTA_DELETE = '[Pregunta Wizard] Encuesta Delete';
export const PREGUNTA_COPIAR = '[Pregunta Wizard] Encuesta Copy';
export const SELECT_EDIT = '[Pregunta Wizard] Encuesta Select Edit';
export const CLOSE_EDIT = '[Pregunta Wizard] Encuesta Close Edit';
export const EDIT_PREGUNTA = '[Pregunta Wizard] Edit';
export const CREAR_ENCUESTA = '[Pregunta Wizard] state';
export const SET_TIPO_ENCUESTA = '[Pregunta Wizard] Set Tipo Encuesta';

export const SELECT_SECCION_CONFIGURACION = '[Seccion Wizard] Seccion Configuracion';

export class SetTipoEncuesta implements Action{
    readonly type = SET_TIPO_ENCUESTA;
    constructor(public payload: number) { }

    
}

export class CloseEdit implements Action{
    readonly type = CLOSE_EDIT;
    constructor() { }

    
}






export const REGISTRA_ENCUESTA_SERVER = '[Pregunt Wizard] Send Server';
export class RegistraEncuestaServer implements Action{
    readonly type = REGISTRA_ENCUESTA_SERVER;
    constructor(public payload: EncuestaRequest) { }

    
}

export const REGISTRA_ENCUESTA_SERVER_SUCCESS = '[Pregunt Wizard] Send Server Success';
export class RegistraEncuestaServerSuccess implements Action{
    readonly type = REGISTRA_ENCUESTA_SERVER_SUCCESS;
    constructor(public payload: EncuestaResponse) { }

    
}

export const REGISTRA_ENCUESTA_SERVER_FAIL = '[Pregunt Wizard] Send Server Fail';
export class RegistraEncuestaServerFail implements Action{
    readonly type = REGISTRA_ENCUESTA_SERVER_FAIL;
    constructor(public payload: any) { }

    
}




export class SetDatosGeneralesEncuesta implements Action {
    readonly type = SET_DATOS_GENERALES;
    constructor(public payload: DatosGenerales) { }
}
export class CreaEncuesta implements Action {
    readonly type = CREAR_ENCUESTA;
    constructor() { }
}

export class SeccionAgregar implements Action {
    readonly type = SECCION_ENCUESTA_ADD;
    constructor(public payload: SeccionesWizard) { }
}
export class SeccionSelected implements Action {
    readonly type = SECCION_ENCUESTA_SELECTED;
    constructor(public payload: number) { }
}
export class ResetEncuesta implements Action {
    readonly type = RESET_ENCUESTA;
    constructor() { }
}
export class PreguntaAddWizard implements Action {
    readonly type = PREGUNTA_ADD;
    constructor(public payload: Preguntas) { }
}

export class DeletePregunta implements Action {
    readonly type = PREGUNTA_DELETE;
    constructor(public payload: Preguntas) { }
}

export class CopiarPregunta implements Action {
    readonly type = PREGUNTA_COPIAR;
    constructor(public payload: Preguntas) { }
}

export class SelectEdit implements Action {
    readonly type = SELECT_EDIT;
    constructor(public payload: Preguntas) { }
}
export class EditPregunta implements Action {
    readonly type = EDIT_PREGUNTA;
    constructor(public payload: Preguntas) { }
}

export const GET_ENCUESTA_WIZARD = '[Encuesta Wizard] Get';
export const GET_ENCUESTA_WIZARD_SUCCESS = '[Encuesta Wizard] Get Success';
export const GET_ENCUESTA_WIZARD_FAIL = '[Encuesta Wizard] Get Fail';

export class GetEncuestaWizard implements Action {
    readonly type = GET_ENCUESTA_WIZARD;
    constructor(public payload: string) { }
}

export class GetEncuestaWizardSuccess implements Action {
    readonly type = GET_ENCUESTA_WIZARD_SUCCESS;
    constructor(public payload: EncuestaResponse) { }
}

export class GetEncuestaWizardFail implements Action {
    readonly type = GET_ENCUESTA_WIZARD_FAIL;
    constructor(public payload: any) { }
}

export const PUBLICA_ENCUESTA_WIZARD_LOAD = '[Publica Wizard] Load';
export const PUBLICA_ENCUESTA_WIZARD_SUCCESS = '[Publica Encuesta Wizard] Success';
export const PUBLICA_ENCUESTA_WIZARD_FAIL = '[Publica Encuesta Wizard] Fail';

export class PublicaEncuestaLoad implements Action {
    readonly type = PUBLICA_ENCUESTA_WIZARD_LOAD;
    constructor(public payload: PublicaEncuestaRequest) { }
}

export class PublicaEncuestaSuccess implements Action {
    readonly type = PUBLICA_ENCUESTA_WIZARD_SUCCESS;
    constructor(public payload: ResponseBase) { }
}

export class PublicaEncuestaFail implements Action {
    readonly type = PUBLICA_ENCUESTA_WIZARD_FAIL;
    constructor(public payload: any) { }
}

export const SET_SEND_SERVER = '[Publicar Wizard] Set Send Server';

export class SetSendServerWizard implements Action {
    readonly type = SET_SEND_SERVER;
    constructor(public payload: boolean) { }
}
export const SET_CONSULTO_SERVER = '[Publicar Wizard] Consulta server';

export class SetConsultaServer implements Action {
    readonly type = SET_CONSULTO_SERVER;
    constructor(public payload: boolean) { }
}

export class SelectSeccionConfiguracion implements Action {
    readonly type = SELECT_SECCION_CONFIGURACION;
    constructor(public payload: SeccionesWizard) { }
}

export const CLOSE_SECCION_CONFIGURACION = '[Seccion Wizard] Close Seccion Configuracion';

export class CloseSeccionConfigutacion implements Action{
    readonly type = CLOSE_SECCION_CONFIGURACION;
    constructor(public payload: SeccionesWizard) { }

}


export const CLOSE_ALL_SECCION_CONFIGURACION = '[Seccion Wizard] Close All Seccion Configuracion';

export class CloseAllSeccionConfigutacion implements Action{
    readonly type = CLOSE_ALL_SECCION_CONFIGURACION;
    constructor() { }

}

export const CHANGE_NOMBRE_SECCION = '[Seccion Wizard] Change Nombre';

export class ChangeNombreSeccion implements Action{
    readonly type = CHANGE_NOMBRE_SECCION;
    constructor(public payload: any) {  }

}

export const ELIMINAR_SECCION = '[Seccion] Eliminar';

export class EliminarSeccion implements Action{
    readonly type = ELIMINAR_SECCION;
    constructor(public payload: number) {  }

}





export type WizardEncuestaAction = 
SeccionAgregar |
SeccionSelected |
ResetEncuesta |
SetDatosGeneralesEncuesta |
PreguntaAddWizard |
DeletePregunta |
CopiarPregunta |
SelectEdit |
EditPregunta |
CreaEncuesta |
RegistraEncuestaServer |
RegistraEncuestaServerSuccess |
RegistraEncuestaServerFail |
GetEncuestaWizard |
GetEncuestaWizardSuccess |
GetEncuestaWizardFail |
SetTipoEncuesta  |
PublicaEncuestaLoad |
PublicaEncuestaSuccess |
PublicaEncuestaFail |
SetSendServerWizard |
SetConsultaServer |
SelectSeccionConfiguracion |
CloseSeccionConfigutacion  |
CloseAllSeccionConfigutacion |
ChangeNombreSeccion |
EliminarSeccion | 
CloseEdit
