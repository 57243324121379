import { GrupoQuizz } from './response/grupo/grupo-quizz';
export class SidebarNavbar {
    idEncuesta: number;
    codigo: string;
    eligioEncuesta: boolean;
    eligioGrupo: boolean;
    IdGrupo: number;
    moduloActivo: number;
    grupo: GrupoQuizz;
    idTipoEncuesta : number;
    idEstatusEncuesta : number;
}

