
import { AltaGrupoResponse } from 'src/app/shared/models/response/grupo/alta-grupo-response';
import { GrupoQuizz } from 'src/app/shared/models/response/grupo/grupo-quizz';
import { ListadoGrupoResponse } from 'src/app/shared/models/response/grupo/listado-grupo-response';
import * as fromGruposAction from '../actions/grupos-actions';
export interface GruposState {
    loanding: boolean;
    asigno: boolean;
    inserto: boolean;
    edito: boolean;
    elimino: boolean;
    loaded: boolean;
    listado: ListadoGrupoResponse;
    altaGrupo: AltaGrupoResponse;
    añadirMiebros: AltaGrupoResponse;
    error: any;
    operacioGrupo: any;
    grupoDetalle: boolean;
}


export const initialState: GruposState = {
    loanding: false,
    asigno: false,
    inserto: false,
    edito: false,
    elimino: false,
    loaded: false,
    listado: new ListadoGrupoResponse(),
    altaGrupo: new AltaGrupoResponse(),
    añadirMiebros: new AltaGrupoResponse(),
    error: '',
    operacioGrupo: '',
    grupoDetalle: false
};

export function reducer(state = initialState, action: fromGruposAction.GruposAction): GruposState {
    switch (action.type) {
        case fromGruposAction.GRUPO_LOAD: {
            const payload = action.payload;
            return {
                ...state,
                loanding: true,
                asigno: false,
                loaded: false,
                inserto: false,
                edito: false,
                elimino: false,
                listado: { ...state.listado },
                error: ''
            };
        }
        case fromGruposAction.GRUPO_LOAD_SUCCESS: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                asigno: false,
                loaded: true,
                inserto: false,
                edito: false,
                elimino: false,
                listado: payload,
                error: ''
            };

        }

        case fromGruposAction.GRUPO_LOAD_FAIL: {
            const data = action.payload;
            return {
                ...state,
                loanding: false,
                asigno: false,
                loaded: false,
                inserto: false,
                edito: false,
                elimino: false,
                listado: { ...state.listado },
                error: data,
            };

        }

        case fromGruposAction.ALTA_GRUPOS: {
            const data = action.payload;
            return {
                ...state,
                loanding: true,
                loaded: false,
                listado: { ...state.listado },
                error: '',
            };

        }
        case fromGruposAction.ALTA_GRUPOS_SUCCESS: {
            const payload = action.payload;
            let stateCopy = JSON.parse(JSON.stringify(state.listado)) as ListadoGrupoResponse;
            

            stateCopy.grupos.push(payload.grupo)

            return {
                ...state,
                loanding: false,
                loaded: true,
                inserto: true,
                listado: stateCopy,
                error: '',
                altaGrupo: payload,
            };

        }
        case fromGruposAction.ALTA_GRUPOS_FAIL: {
            const data = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                inserto: false,
                listado: { ...state.listado },
                error: data,
            };

        }
        case fromGruposAction.SEND_DATA_GRUPO_DELETE: {
            const payload = action.payload;
            return {
                ...state,
                operacioGrupo: payload,
                loaded: true,
                inserto: false,
                grupoDetalle: true,
                listado: payload
            };
        }
        case fromGruposAction.SEND_DATA_GRUPO_EDITO: {
            const payload = action.payload;
            return {
                ...state,
                operacioGrupo: payload,
                inserto: false,
                grupoDetalle: true,
                listado: { ...state.listado }
            };
        }
        default:
            return {
                ...state,

                listado: { ...state.listado }
            };
            break;
    }
}
export const getState = (state: GruposState) => state;
