
export class PreguntasEvaluaciones {

    index: number;
    id : number;
    
    idMiembro? : number;
    idSeccion: number;
    nombre : string; 
    tipoRespuesta: number;
    ponderacion: number;
    respondida: boolean;
    responsables :number[];
    opcionesPreguntas: object[] = [];
    respuestasUsuario: string[] = [];
    respuestasCorrectas: object[] = [];
    solicitaComentarios: boolean;
    solicitaImagen: boolean;
    imagenes?: string[] = [];
    comentario?: string;
    comentarioAbierta : string;
    showValidacion: boolean;
}
