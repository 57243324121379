import { Action } from '@ngrx/store';
import { EnumOpcionCompartir } from 'src/app/shared/models/enums/enum-opcion-compartir.model';


export const COLLAPSE_SELECT_COMPARTIR = '[Collapse Select] Compartir';
export class SelectOpcionCollapseCompartir implements Action {
    readonly type = COLLAPSE_SELECT_COMPARTIR;
    constructor(public payload: EnumOpcionCompartir) {
    }
}

export type SelectOpcionCollapseAction =
SelectOpcionCollapseCompartir ;