import { ListadoUsuariosResponse } from 'src/app/shared/models/response/usuario/listado-usuarios-response.model';
import * as fromUserAction from '../actions/user-admin.action';



export interface ListadoUsuariosState {
    loanding: boolean;
    loaded: boolean;
    listado: ListadoUsuariosResponse;
    error: string;
}
export const initialState: ListadoUsuariosState = {
    loanding: false,
    loaded: false,
    listado: new ListadoUsuariosResponse(),
    error: ''
};


export function reducer(state = initialState, action: fromUserAction.AdminAction): ListadoUsuariosState {
    switch (action.type) {
        case fromUserAction.USER_LISTADO_LOAD:{
            return {
                ...state,
                loanding: true,
                loaded: false,
                listado: new ListadoUsuariosResponse(),
                error: ''
            };
            break;
        }
        case fromUserAction.USER_LISTADO_LOAD_SUCCESS:{
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                listado: payload,
                error: ''
            };
            break;
        }
        case fromUserAction.USER_LISTADO_LOAD_FAIL:{
            const error = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                listado: new ListadoUsuariosResponse(),
                error: error
            };
        }

            break;
        case fromUserAction.USER_DELETE:{
            return {
                ...state,
                loanding: true,
                loaded: false,
                
                listado : state.listado
            };
            break;
        }

        case fromUserAction.USER_DELETE_SUCCESS:{
            const payload = action.payload;
            let stateCopy = (JSON.parse(JSON.stringify(state.listado))) as ListadoUsuariosResponse;
            let usuarios = stateCopy.usuarios.filter(x=> x.idUsuario != payload.idUsuario);
            stateCopy.usuarios = usuarios;
            return {
                ...state,
                loanding: false,
                loaded: true,
                listado : stateCopy
                
            };
            break;
        }
        case fromUserAction.USER_DELETE_FAIL:{
            return {
                ...state,
                loanding: false,
                loaded: true,
                
                listado : state.listado
            };
            break;
        }
    }
}

export const getState = (state: ListadoUsuariosState) => state;

