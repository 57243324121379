import { Action } from '@ngrx/store';
import { DetalleRespuesta } from 'src/app/shared/models/request/encuesta/detalle-respuesta.model';
import { AnalisisResultadosResponse } from 'src/app/shared/models/response/dashboard/analisis/analisis-resultados-response';
import { DetalleRespuestaResponse } from 'src/app/shared/models/response/encuestas/detalle-respuesta-response.model';
import { ListadoRespuestasResponse } from 'src/app/shared/models/response/encuestas/listado-respuestas-response.model';


export const ANALISIS_RESULTADOS_LOAD = '[Analisis resultados] Load';
export const ANALISIS_RESULTADOS_LOAD_SUCCESS = '[Analisis resultados] Load Success';
export const ANALISIS_RESULTADOS_LOAD_FAIL = '[Analisis resultados] Load Fail';

export class AnalisisResultadosLoad implements Action {
    readonly type = ANALISIS_RESULTADOS_LOAD;
    constructor(public payload: string) { }
}

export class AnalisisResultadosLoadSuccess implements Action {
    readonly type = ANALISIS_RESULTADOS_LOAD_SUCCESS;
    constructor(public payload: AnalisisResultadosResponse) { }
}

export class AnalisisResultadosLoadFail implements Action {
    readonly type = ANALISIS_RESULTADOS_LOAD_FAIL;
    constructor(public payload: any) { }
}

export const RESULTADOS_DETALLE_LOAD = '[Resultados detalle] Load';
export const RESULTADOS_DETALLE_LOAD_SUCCESS = '[Resultados detalle]  Success';
export const RESULTADOS_DETALLE_LOAD_FAIL = '[Resultados detalle]  Fail';

export class ResultadosDetalleLoad implements Action {
    readonly type = RESULTADOS_DETALLE_LOAD;
    constructor(public payload: string) { }
}

export class ResultadosDetalleLoadSuccess implements Action {
    readonly type = RESULTADOS_DETALLE_LOAD_SUCCESS;
    constructor(public payload: ListadoRespuestasResponse) { }
}

export class ResultadosDetalleLoadFail implements Action {
    readonly type = RESULTADOS_DETALLE_LOAD_FAIL;
    constructor(public payload: any) { }
}





export const SELECT_GRAFICA = '[Analisis Select] Option';

export class SelectGrafica implements Action {
    readonly type = SELECT_GRAFICA;
    constructor(public payload: number) { }
}



export const SELECT_DETALLE_RESULTADO = '[Detalle Encuesta] Select';

export class SelectDetalleResultado implements Action {
    readonly type = SELECT_DETALLE_RESULTADO;
    constructor(public payload: number) { }
}


export const DETALLE_RESULTADO_LOAD = '[Detalle Resultado] Load';
export const DETALLE_RESULTADO_SUCCESS = '[Detalle Resultado]  Success';
export const DETALLE_RESULTADO_FAIL = '[Detalle Resultado]  Fail';

export class DetalleResultadoLoad implements Action {
    readonly type = DETALLE_RESULTADO_LOAD;
    constructor(public payload: DetalleRespuesta) { }
}

export class DetalleResultadoLoadSuccesss implements Action {
    readonly type = DETALLE_RESULTADO_SUCCESS;
    constructor(public payload: DetalleRespuestaResponse) { }
}

export class DetalleResultadoLoadFail implements Action {
    readonly type = DETALLE_RESULTADO_FAIL;
    constructor(public payload: any) { }
}

export const REGRESA_LISTADO = '[Listado Respuestas]  Select';
export class RegresaListado implements Action {
    readonly type = REGRESA_LISTADO;
    constructor() { }
}







export type ResultadosAction =
AnalisisResultadosLoad |
AnalisisResultadosLoadSuccess |
AnalisisResultadosLoadFail |
SelectGrafica |
ResultadosDetalleLoad |
ResultadosDetalleLoadSuccess |
ResultadosDetalleLoadFail |
SelectDetalleResultado |
DetalleResultadoLoad |
DetalleResultadoLoadSuccesss |
DetalleResultadoLoadFail |
RegresaListado ;

