import { UserEffects } from './user.effects';
import { EncuestaInternaEffects } from './encuestas-internas.effects';
import { PerfilUserEffects } from './perfil-user-effects';
import {DepartamentoEffects} from './deparamentos.effects';
import { UsuarioGruposEffects } from './usuario-grupos.effects';
import { QuizzEffects } from './responder-quizz.effects';
import { GruposEffects } from './grupos.effects';
import { GruposDetalleEffects } from './grupo-detalle.effects';
import { ConfiguracionEffects } from './configuracion.effects';
import { DashBoardEffects } from './dashboard.effects';
import { DashBoardEvalEffects } from './dashboardEval.effects';
import { ResultadosEffects } from './resultados.effects';
import { WizardEffects } from './wizard.effects';
import { OptionSelectEffects } from './option-select.effets';
import { SucursalesEffects } from './sucursales.effects';
import { SucursalesConfiguradasEffects } from './sucursales-configuradas.effects';
import { CompartirGraficosEffects } from './compartir-graficos.effects';

//Evaluaciones
import { RegistroEvaluacionEffects } from './evaluaciones/registra-evaluacion.effects';
import {EvaluacionInternaEffects} from './evaluaciones/evaluaciones-internas.effects';
import { WizardEVEffects } from './evaluaciones/wizard-ev.effects';
import { LevantamientoEvaluacionEffects } from './evaluaciones/levantamiento-evaluacion.effects';


export const effects: any[] = [
    UserEffects,
    EncuestaInternaEffects,
    PerfilUserEffects,
    DepartamentoEffects,
    UsuarioGruposEffects,
    QuizzEffects,
    GruposEffects,
    GruposDetalleEffects,
    ConfiguracionEffects,
    DashBoardEffects,
    DashBoardEvalEffects,
    ResultadosEffects,
    WizardEffects,
    OptionSelectEffects,
    SucursalesEffects,
    SucursalesConfiguradasEffects,
    CompartirGraficosEffects,
    //Evaluaciones
    RegistroEvaluacionEffects,
    EvaluacionInternaEffects,
    WizardEVEffects,
    LevantamientoEvaluacionEffects
];

export * from './user.effects';
export * from './encuestas-internas.effects';
export * from './perfil-user-effects';
export * from './deparamentos.effects';

export * from './usuario-grupos.effects';
export *  from './responder-quizz.effects';
export *  from './grupos.effects';
export *  from './grupo-detalle.effects';
export * from './configuracion.effects';
export * from './dashboard.effects';
export * from './dashboardEval.effects';
export * from './resultados.effects';
export * from './wizard.effects';
export * from './option-select.effets';
export * from './sucursales.effects';
export * from './sucursales-configuradas.effects';
export * from './compartir-graficos.effects';

//Evaluaciones
export * from './evaluaciones/registra-evaluacion.effects';
export * from './evaluaciones/wizard-ev.effects';
export * from './evaluaciones/evaluaciones-internas.effects'
export * from './evaluaciones/wizard-ev.effects'
export * from './evaluaciones/levantamiento-evaluacion.effects';