

import { AddEvaluacionResponse } from 'src/app/shared/models/response/evaluaciones/add-evaluacion-response';
import * as fromEvaluacionAlta from '../../actions/evaluaciones/registra-evaluacion-action';


export interface AltaEvaluacionState {
    loanding: boolean;
    loaded: boolean;
    error: any;
    encuesta: AddEvaluacionResponse;
}

export const initialState: AltaEvaluacionState = {
    loanding: false,
    loaded: false,
    error : '',
    encuesta: new AddEvaluacionResponse()
};
export function reducer(state= initialState, action: fromEvaluacionAlta.EvaluacionAltaAction ): AltaEvaluacionState {
    switch (action.type) {
        case fromEvaluacionAlta.EVALUACION_ADD: {
            return {
                ...state,
                loanding: true,
                loaded : false,
                error: '',
                encuesta: new AddEvaluacionResponse()
            };
        }
        case fromEvaluacionAlta.EVALUACION_ADD_SUCCESS : {
            const data = action.payload;
            return {
                ...state,
                loanding: false,
                loaded : true,
                error: '',
                encuesta: data
            };

        }
        case fromEvaluacionAlta.EVALUACION_ADD_FAIL: {
            const data = action.payload;
            return {
                ...state,
                loanding: false,
                loaded : false,
                error: '',
                encuesta: new AddEvaluacionResponse()
            };
        }
    }
}

export const getState = (state: AltaEvaluacionState) => state;