
import { DashBoardEvalGeneralResponse } from 'src/app/shared/models/response/dashboard/general/dash-board-eval-general-response.model';
import * as fromDashBoardEvalAction from '../actions/dashboard-eval-genera.action';

export interface DashBoardEvalState {
    loanding: boolean;
    loaded: boolean;
    error: any;
    dashBoardEvalGeneral: DashBoardEvalGeneralResponse;
 }
 export const initialState: DashBoardEvalState = {
     loanding:false,
     loaded:false,
     error:'',
     dashBoardEvalGeneral: new DashBoardEvalGeneralResponse()
 }
 export function reducer(state = initialState, action: fromDashBoardEvalAction.DashBoardEvalGeneralAction ): DashBoardEvalState {
    switch (action.type) {
        case fromDashBoardEvalAction.DASHBOARD_EVAL_GENERAL_LOAD: {

            return {
                ...state,
                loanding: true,
                loaded: false,
                dashBoardEvalGeneral :  {...state.dashBoardEvalGeneral},
                error: ''
            };
        }
        case fromDashBoardEvalAction.DASHBOARD_EVAL_GENERAL_LOAD_SUCCESS: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                dashBoardEvalGeneral :  payload,
                error: ''
            };
        }
        case fromDashBoardEvalAction.DASHBOARD_EVAL_GENERAL_LOAD_FAIL: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                dashBoardEvalGeneral :  {...state.dashBoardEvalGeneral},
                error: payload
            };
        }
    }
}

export const getState = (state: DashBoardEvalState) => state;
