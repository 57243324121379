
import { SidebarNavbar } from 'src/app/shared/models/sidebar-navbar';
import * as fromUserAction from '../actions/option.select-action';
export interface OptionSelectState {
   loanding: boolean;
   loaded: boolean;
   option: SidebarNavbar;
   error: any;
}
const inicialOption = new SidebarNavbar ();
inicialOption.codigo = 'x';
inicialOption.idEncuesta = -1;
inicialOption.eligioEncuesta = false;

export const initialState: OptionSelectState = {
   loanding: false,
   loaded : false,
   option : inicialOption,
   error: ''
};

export function reducer(state = initialState, action: fromUserAction.OptionMenuActions ): OptionSelectState {
    switch (action.type) {
        case fromUserAction.USER_SELECT_OPTION: {
            const payload = action.payload;
            return {
                ...state,
                loanding: true,
                loaded: false,
                option : payload,
                error: ''
            };
        }
        case fromUserAction.USER_SELECT_OPTION_SUCCESS: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                option : {...state.option},
                error: ''
            };

        }

        case fromUserAction.USER_SELECT_OPTION_FAIL: {
            const data = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                option: new SidebarNavbar(),
                error : data
            };

        }
        default:
            return {
                ...state
            }
            break;
     }
}
export const getState = (state: OptionSelectState) => state;
