
import { TipoRespuesta } from 'src/app/shared/models/enums/enumTipoRespuesta.model';
import { ListadoEvaluacionInterna } from 'src/app/shared/models/response/evaluaciones/listado-evaluacion-interna.model';
import * as fromEvaluacionInterna from '../../actions/evaluaciones/mis-evaluaciones-action';
import { TipoSeccion } from '../../../shared/models/response/evaluaciones/Tipo-seccion.model';
import { state } from '@angular/animations';
import { State } from '@ngrx/store';



export interface TipoSeccionInternaState {
    agregar : boolean;
    loanding: boolean;
    loaded: boolean;
    tipoSeccion: TipoSeccion;
    error: string;
}
export const initialState: TipoSeccionInternaState = {
    loanding: false,
    loaded : false,
    tipoSeccion : new TipoSeccion(),
    error : '',
    agregar : false
};

export function reducer(state = initialState, action: fromEvaluacionInterna.EvaluacionInternaAction ): TipoSeccionInternaState {
    switch (action.type) {
        case fromEvaluacionInterna.TIPOSECCION_LOAD: {
            return {
                ...state,
                loanding: true,
                loaded : false,
                tipoSeccion :new TipoSeccion(),
                error: '',
                agregar : false
            };
        }
        case fromEvaluacionInterna.TIPOSECCION_LOAD_SUCCESS: {
            const tipoSeccion = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                tipoSeccion,
                error : '',
                agregar : false
            };
        }
        case fromEvaluacionInterna.TIPOSECCION_LOAD_FAIL : {
            const data = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                tipoSeccion :new TipoSeccion(),
                error : data,
                agregar : false
            };

        }
    }
}

export const getState = (state: TipoSeccionInternaState) => state;
export const getSeccionesType= (state: TipoSeccionInternaState) => state != null ? state.tipoSeccion : new TipoSeccion();
