

import { TipoRespuesta } from 'src/app/shared/models/enums/enumTipoRespuesta.model';
import { Sucursal } from 'src/app/shared/models/response/encuestas/sucursal';
import * as fromSucursalesAction from '../actions/sucursales.action';


export interface SucursalesState {
    loaded: boolean;
    loanding: boolean
    sucursales: Sucursal[],
    error: any,
    idTipoRespuesta : number,
    consulto : boolean,
    elimino: boolean,
    inserto: boolean,
    edito: boolean
}

export const initialState: SucursalesState = {
    loanding: false,
    loaded: false,
    sucursales: [],
    error: '',
    idTipoRespuesta : null,
    consulto : false,
    elimino : false,
    inserto : false,
    edito : false
};

export function reducer(state = initialState, action: fromSucursalesAction.SucursalesAction): SucursalesState {
    switch (action.type) {
        case fromSucursalesAction.GET_SUCURSALES_LOAD: {
            return {
                ...state,
                loanding: true,
                loaded: false,
                sucursales: [],
                error: '',
                idTipoRespuesta : null,
                consulto : false,
                elimino : false,
                inserto : false,
                edito : false
            };
        }
        case fromSucursalesAction.GET_SUCURSALES_LOAD_SUCCESS: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                sucursales: payload.sucursales,
                error: '',
                idTipoRespuesta : TipoRespuesta.Exito,
                consulto : true,
                elimino : false,
                inserto : false,
                edito : false
            };
        }
        case fromSucursalesAction.GET_SUCURSALES_LOAD_FAIL: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                sucursales: payload.sucursales,
                error: '',
                idTipoRespuesta : TipoRespuesta.Error,
                consulto : false,
                elimino : false,
                inserto : false,
                edito : false
            };
        }
        case fromSucursalesAction.ADD_SUCURSAL: {
           
            return {
                ...state,
                loanding: true,
                loaded: false,
                sucursales: state.sucursales,
                error: '',
                idTipoRespuesta : null,
                consulto : false,
                elimino : false,
                inserto : false,
                edito : false
            };
        }
        case fromSucursalesAction.ADD_SUCURSAL_SUCCESS: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                sucursales: [...state.sucursales, payload.sucursal],
                error: '',
                idTipoRespuesta : TipoRespuesta.Exito,
                consulto : false,
                elimino : false,
                inserto : true,
                edito : false
            };
        }
        case fromSucursalesAction.ADD_SUCURSAL_FAIL: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                sucursales: state.sucursales,
                error: payload,
                idTipoRespuesta : TipoRespuesta.Error,
                consulto : false,
                elimino : false,
                inserto : false,
                edito : false
            };
        }
        case fromSucursalesAction.DELETE_SUCURSAL: {
           
            return {
                ...state,
                loanding: true,
                loaded: false,
                sucursales: state.sucursales,
                error: '',
                idTipoRespuesta : null,
                consulto : false,
                elimino : false,
                inserto : false,
                edito : false
            };
        }
        case fromSucursalesAction.DELETE_SUCURSAL_SUCCESS: {
            const payload = action.payload;
           
            return {
                ...state,
                loanding: false,
                loaded: true,
                sucursales: state.sucursales.filter(x=> x.id != payload.idSucursal),
                error: payload,
                idTipoRespuesta : payload.tipoRespuesta,
                consulto : false,
                elimino : true,
                inserto : false,
                edito : false
            };
        }
        case fromSucursalesAction.DELETE_SUCURSAL_FAIL: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                sucursales: state.sucursales,
                error: payload,
                idTipoRespuesta : TipoRespuesta.Error,
                consulto : false,
                elimino : false,
                inserto : false,
                edito : false
            };
        }
        case fromSucursalesAction.EDIT_SUCURSAL: {
         
            return {
                ...state,
                loanding: true,
                loaded: false,
                sucursales: state.sucursales,
                error: '',
                idTipoRespuesta : null,
                consulto : false,
                elimino : false,
                inserto : false,
                edito : false
            };
        }
        case fromSucursalesAction.EDIT_SUCURSAL_SUCCESS: {
            const payload = action.payload;
            let sucursalEdita = {...action.payload.sucursal}
            let sucursales = JSON.parse(JSON.stringify(state.sucursales)) as Sucursal[];
            let indexSucursal = sucursales.findIndex(x=> x.id == sucursalEdita.id );
            sucursales[indexSucursal] = sucursalEdita;
            return {
                ...state,
                loanding: false,
                loaded: true,
                sucursales: sucursales,
                error: '',
                idTipoRespuesta : payload.tipoRespuesta,
                consulto : false,
                elimino : false,
                inserto : false,
                edito : true
            };
        }
        case fromSucursalesAction.EDIT_SUCURSAL_FAIL: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                sucursales: state.sucursales,
                error: payload,
                idTipoRespuesta : TipoRespuesta.Error,
                consulto : false,
                elimino : false,
                inserto : false,
                edito : false
            };
        }

       
    }
}

export const getState = (state: SucursalesState) => state;

