import { ResponseBase } from '../response-base.model';
export class UsuarioResponse extends ResponseBase {
    id: number;
    token?: string;
    login?: string;
    nombre?: string;
    apellidoPaterno?: string;
    apellidoMaterno?: string;
    cargo?: string;
    email?: string;
    nombreCompleto?: string;
    idRol?: string;
}
