import { Action } from "@ngrx/store";
import { ResponseBase } from "src/app/shared/models/response/response-base.model";
import { ListadoUsuariosResponse } from "src/app/shared/models/response/usuario/listado-usuarios-response.model";
import { ResponseDeleteUser } from "src/app/shared/models/response/usuario/responseDeleteUser.model";


export const USER_DELETE = '[User] Delete';
export const USER_DELETE_SUCCESS = '[User] Delete Success';
export const USER_DELETE_FAIL = '[User] Delete Fail';

export const USER_LISTADO_LOAD = '[User] Listado Load';
export const USER_LISTADO_LOAD_SUCCESS = '[User] Listado Success';
export const USER_LISTADO_LOAD_FAIL = '[User] Listado Fail';


// action user listado admin

export class UserListadoLoad implements Action {
    readonly type = USER_LISTADO_LOAD;
    constructor() { }
}

export class UserListadoSuccess implements Action {
    readonly type = USER_LISTADO_LOAD_SUCCESS;
    constructor(public payload: ListadoUsuariosResponse ) { }
}

export class UserListadoFail implements Action {
    readonly type = USER_LISTADO_LOAD_FAIL;
    constructor(public payload: any) { }
}

export class UserDelete implements Action {
    readonly type = USER_DELETE;
    constructor(public payload: number) { }
}

export class UserDeleteSuccess implements Action {
    readonly type = USER_DELETE_SUCCESS;
    constructor(public payload: ResponseDeleteUser ) { }
}

export class UserDeleteFail implements Action {
    readonly type = USER_DELETE_FAIL;
    constructor(public payload: any) { }
}

export type AdminAction = UserListadoLoad | UserListadoSuccess | 
                         UserListadoFail | UserDelete | UserDeleteSuccess | UserDeleteFail;




