import { Injectable } from "@angular/core";
import { EnumOrigenes } from "../shared/models/enums/enum-origenes";

@Injectable(
{
    providedIn: 'root'
})
// evaluacion/:codigo
export class QuizzService 
{

    constructor( ) 
    { }

    cleanConfigQuizz() 
    {
        // Limpia los valores de los atributos del localStorage
        localStorage.currentCodeQuizz = undefined;
        localStorage.idTipoEncuesta = undefined;
        localStorage.idEncuesta = undefined;
        localStorage.codigo = undefined;
        localStorage.origen = EnumOrigenes.Ninguno;
    }

    setConfigQuizz(code: string, idTipoEncuesta: number, idEncuesta: number, 
        idTipoEvaluacion: number, origen: EnumOrigenes = EnumOrigenes.Encuesta)
    {
        // Establace los valores en los atributos del localStorage
        localStorage.currentCodeQuizz = code;
        localStorage.idTipoEncuesta = idTipoEncuesta;
        localStorage.idEncuesta = idEncuesta;
        localStorage.idTipoEvaluacion = idTipoEvaluacion;
        localStorage.origen = origen;
    }

    getCode()
    { 
        // Recupera el "Codigo" de localStorage
        console.log(localStorage.currentCodeQuizz)
        return localStorage.currentCodeQuizz != undefined ? 
            localStorage.currentCodeQuizz.toString() : ""; 
    }
    
    getTipoEncuesta()
    { 
        // Recupera el "Tipo de encuesta" de localStorage
        return localStorage.idTipoEncuesta != undefined ? 
            parseInt(localStorage.idTipoEncuesta.toString()) : 0; 
    }
    getTipoEvaluacion()
    { 
        // Recupera el "Tipo de encuesta" de localStorage
        return localStorage.idTipoEvaluacion != undefined ? 
            parseInt(localStorage.idTipoEvaluacion.toString()) : 0; 
    }

    getIdEncuesta()
    {
        // Recupera el "Id de encuesta" de localStorage
        return localStorage.idEncuesta != undefined ? 
            parseInt(localStorage.idEncuesta.toString()) : 0;
    }

    getRole()
    {
        // Recupera el "Rol" de localStorage
        return localStorage.rol != undefined ? 
            parseInt(localStorage.rol.toString()) : 0;
    }
    setConfigEval(code: string)
    {
        // Establace los valores en los atributos del localStorage
        localStorage.currentCodeQuizz = code;

    }

}