
import { UsuarioEditResponse } from 'src/app/shared/models/response/usuario/usuario-edit-response.model';
import * as fromUserAction from '../actions/user.action';


export interface UsuarioEditState {
    loanding: boolean;
    loaded: boolean;
    response: UsuarioEditResponse;
    error: string;
    elimino: boolean
}
export const initialState: UsuarioEditState = {
    loanding: false,
    loaded: false,
    response: new UsuarioEditResponse(),
    error: '',
    elimino: false
};


export function reducer(state = initialState, action: fromUserAction.UserActions): UsuarioEditState {
    switch (action.type) {
        case fromUserAction.USER_EDIT:
            return {
                ...state,
                loanding: true,
                loaded: false,
                response: new UsuarioEditResponse(),
                error: '',
                elimino: false
            };

        case fromUserAction.USER_EDIT_SUCCESS:
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                response: payload,
                error: '',
                elimino: false
            };

        case fromUserAction.USER_EDIT_FAIL:
            const error = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                response: new UsuarioEditResponse(),
                error: error,
                elimino: false
            };

        

    }
}

export const getState = (state: UsuarioEditState) => state;

