
import { AnalisisResultadosResponse } from 'src/app/shared/models/response/dashboard/analisis/analisis-resultados-response';
import { RespuestasEncuesta } from 'src/app/shared/models/response/encuestas/respuestas-encuesta.model';
import { SeccionesDetalleRespuesta } from 'src/app/shared/models/response/encuestas/secciones-detalle-respuesta.model';
import * as fromResultadosAcion from '../actions/resultados.action';
export interface ResultadosState {
    loanding: boolean;
    loaded: boolean;
    error: any;
    resultados: AnalisisResultadosResponse;
    selectOption: boolean;
    tipoGrafica : number;
    respuestas : RespuestasEncuesta[];
    idRespuesta : number;
    detalleRespuesta: boolean;
    secciones : SeccionesDetalleRespuesta [];
    selectRespuesta : boolean,
    regresoListado : boolean
 }
 export const initialState: ResultadosState = {
     loanding:false,
     loaded:false,
     error:'',
     resultados: new AnalisisResultadosResponse(),
     selectOption : false,
     tipoGrafica : 0,
     respuestas :[],
     idRespuesta : 0,
     detalleRespuesta : false,
     secciones : [],
     selectRespuesta : false,
     regresoListado : false
 }
 export function reducer(state = initialState, action: fromResultadosAcion.ResultadosAction ): ResultadosState {
    switch (action.type) {
        case fromResultadosAcion.ANALISIS_RESULTADOS_LOAD: {

            return {
                ...state,
                loanding: true,
                loaded: false,
                resultados :  new AnalisisResultadosResponse(),
                error: '',
                selectOption : false,
                tipoGrafica : state.tipoGrafica,
                respuestas :[],
                idRespuesta : 0,
                detalleRespuesta : false,
                secciones : [],
                selectRespuesta : false,
                regresoListado : false
            };
        }
        case fromResultadosAcion.ANALISIS_RESULTADOS_LOAD_SUCCESS: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                resultados :  payload,
                error: '',
                selectOption : false,
                tipoGrafica : state.tipoGrafica,
                respuestas :[],
                idRespuesta : 0,
                detalleRespuesta : false,
                secciones : [],
                selectRespuesta : false,
                regresoListado : false
            };
        }
        case fromResultadosAcion.ANALISIS_RESULTADOS_LOAD_FAIL: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                resultados : {...state.resultados},
                error: payload,
                selectOption : false,
                tipoGrafica : state.tipoGrafica,
                respuestas :[],
                idRespuesta : 0,
                detalleRespuesta : false,
                secciones : [],
                selectRespuesta : false,
                regresoListado : false
            };
        }
        case fromResultadosAcion.SELECT_GRAFICA : {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                resultados :  state.resultados,
                error: '',
                selectOption : true,
                tipoGrafica : payload,
                respuestas : state.respuestas,
                idRespuesta : 0,
                detalleRespuesta : false,
                secciones : [],
                selectRespuesta : false,
                regresoListado : false
            };
        }
        case fromResultadosAcion.RESULTADOS_DETALLE_LOAD : {
            const payload = action.payload;
            return {
                ...state,
                loanding: true,
                loaded: false,
                resultados :  null,
                error: '',
                selectOption : false,
                respuestas :[],
                idRespuesta : 0,
                detalleRespuesta : false,
                secciones : [],
                selectRespuesta : false,
                regresoListado : false
            };
        }
        case fromResultadosAcion.RESULTADOS_DETALLE_LOAD_SUCCESS : {
            const payload = action.payload;
           
            return {
                ...state,
                loanding: false,
                loaded: true,
                resultados :  null,
                error: '',
                selectOption : false,
                respuestas :payload.respuestas,
                idRespuesta : 0,
                detalleRespuesta : false,
                secciones : [],
                selectRespuesta : false,
                regresoListado : false
            };
        }
        case fromResultadosAcion.RESULTADOS_DETALLE_LOAD_FAIL : {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                resultados :  null,
                error: 'error',
                selectOption : false,
                respuestas :[],
                idRespuesta : 0,
                detalleRespuesta : false,
                secciones : [],
                selectRespuesta : false,
                regresoListado : false
               
            };
        }
        case fromResultadosAcion.SELECT_DETALLE_RESULTADO : {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                resultados :  null,
                error: payload,
                selectOption : false,
                respuestas :state.respuestas,
                idRespuesta : payload,
                detalleRespuesta : false,
                secciones : [],
                selectRespuesta : false,
                regresoListado : false
               
            };
        }
        case fromResultadosAcion.DETALLE_RESULTADO_LOAD :{
            return {
                ...state,
                loanding: true,
                loaded: false,
                resultados :  null,
                error: '',
                selectOption : false,
                respuestas :state.respuestas,
                idRespuesta : state.idRespuesta,
                detalleRespuesta : false,
                secciones : [],
                selectRespuesta : false,
                regresoListado : false
               
            };
        }
        case fromResultadosAcion.DETALLE_RESULTADO_SUCCESS :{
            const payload = action.payload;
          
            return {
                ...state,
                loanding: false,
                loaded: true,
                resultados :  null,
                error: '',
                selectOption : false,
                respuestas :state.respuestas,
                idRespuesta : state.idRespuesta,
                detalleRespuesta : true,
                secciones : payload.secciones,
                selectRespuesta : false,
                regresoListado : false
               
            };
        }
        case fromResultadosAcion.DETALLE_RESULTADO_FAIL :{
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                resultados :  null,
                error: payload,
                selectOption : false,
                respuestas :state.respuestas,
                idRespuesta : state.idRespuesta,
                detalleRespuesta : false,
                secciones : [],
                selectRespuesta : false,
                regresoListado : false
               
            };
        }
        case fromResultadosAcion.REGRESA_LISTADO :{
           
            return {
                ...state,
                loanding: false,
                loaded: false,
                resultados :  null,
                error: '',
                selectOption : false,
                respuestas :state.respuestas,
                idRespuesta : state.idRespuesta,
                detalleRespuesta : false,
                secciones : [],
                selectRespuesta : false,
                regresoListado : true
               
            };
        }
    }
}

export const getState = (state: ResultadosState) => state;
export const getRespuestaSelect = (state: ResultadosState) => state != undefined ? state.idRespuesta : undefined;
