import { Action } from '@ngrx/store';

export const SEND_CHILD = '[Selected Child] ';

export class SendChild implements Action {
    readonly type = SEND_CHILD;
    constructor(public payload: any) { }
}



export type NotificacionesDetalleGrupoAction = SendChild;
