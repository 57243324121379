
import { ResponseBase } from 'src/app/shared/models/response/response-base.model';
import * as fromEncuestaAction from '../actions/encuestas-internas.action';
export interface DeletedPreguntatState {
   loanding: boolean;
   loaded: boolean;
   response: ResponseBase;
   error: any;
}

export const initialState: DeletedPreguntatState = {
   loanding: false,
   loaded : false,
   response : new ResponseBase(),
   error: ''
};

export function reducer(state = initialState, action: fromEncuestaAction.EncuestaInternaAction ): DeletedPreguntatState {
    switch (action.type) {
        case fromEncuestaAction.PREGUNTAS_DELETED: {
            const payload = action.payload;
            return {
                ...state,
                loanding: true,
                loaded: false,
                response :  new ResponseBase(),
                error: ''
            };
        }
        case fromEncuestaAction.PREGUNTAS_DELETED_SUCCESS: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                response : payload,
                error: ''
            };

        }

        case fromEncuestaAction.PREGUNTAS_DELETED_FAIL: {
            const data = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                response: new ResponseBase(),
                error : data
            };

        }
     }
   }
   export const getState = (state: DeletedPreguntatState) => state;