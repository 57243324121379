import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { QuizzService } from "src/app/services/quizzSelection.services";

@Injectable({
    providedIn: 'root'
})
export class Utils {
    constructor(private router: Router,
        private quizzService: QuizzService) {
    }
    //General
    sendAdmin(){
        this.router.navigate([`/admin/usuarios`]);
    }
    sendUser(){
        this.router.navigate([`/`]);
    }
    sendGrupos() {
        this.router.navigate([`/pages/grupos`]);
    }
    sendMiembros() {
        this.router.navigate([`/pages/miembros`]);
    }
    sendPreView() {
        /* const url = this.router.serializeUrl(
            this.router.createUrlTree([`/#/quizz/preview/${this.quizzService.getCode()}/true`])
        ); */
        // window.open(url, '_blank');
        window.open(`/#/quizz/preview/${this.quizzService.getCode()}/true`, '_blank');
    }
    //Encuestas
    sendEncuesta() {
        this.router.navigate([`/pages/encuesta/${this.quizzService.getCode()}`]);
    }
    sendMisEncuestas() {
        this.router.navigate([`/pages/mis-encuestas`]);
    }
    sendCompartirEnc() {
        this.router.navigate([`/pages/encuesta/compartir/${this.quizzService.getCode()}`]);
    }
    sendResultadosGeneralesEnc() {
        this.router.navigate([`/pages/encuesta/resultados-encuesta/${this.quizzService.getCode()}`]);
    }
    sendRespuestasEnc(){
        this.router.navigate([`/pages/encuesta/respuestas/${this.quizzService.getCode()}`]);
    }
    sendDetalleRespuestasEnc(idRespuesta : number) {
        this.router.navigate([`/pages/encuesta/respuestas/detalle/${this.quizzService.getCode()}/${idRespuesta}`]);
    }
    sendEstadisticasGeneralesEnc() {
        this.router.navigate([`/pages/encuesta/estadisticas-encuesta/${this.quizzService.getCode()}`]);
    }
    sendAnalisisSucursalesEnc() {
        this.router.navigate([`/pages/encuesta/analisis-sucursales/${this.quizzService.getCode()}`]);
    }
    sendPreferenciasEnc() {
        this.router.navigate([`/pages/encuesta/preferencias-encuesta/${this.quizzService.getCode()}`]);
    }
    sendPreferenciasSucursalesEnc() {
        // this.router.navigate([`/pages/encuesta/preferencias-sucursales/${this.quizzService.getCode()}`]);
        this.router.navigate([`/pages/sucursales`]);
    }
    //Evaluaciones
    sendEvaluacion() {
        this.router.navigate([`/pages/evaluacion/${this.quizzService.getCode()}`]);
    }
    sendMisEvaluaciones() {
        this.router.navigate([`/pages/mis-evaluaciones`]);
    }
    sendCompartirEva() {
        this.router.navigate([`/pages/evaluacion/compartir/${this.quizzService.getCode()}`]);
    }
    sendResultadosGeneralesEva() {
        this.router.navigate([`/pages/evaluacion/resultados-evaluacion/${this.quizzService.getCode()}`]);
    }
    sendRespuestasEva(){
        this.router.navigate([`/pages/evaluacion/respuestas/${this.quizzService.getCode()}`]);
    }
    sendDetalleRespuestasEva(idRespuesta : number) {
        this.router.navigate([`/pages/evaluacion/respuestas/detalle/${this.quizzService.getCode()}/${idRespuesta}`]);
    }
    sendEstadisticasGeneralesEva() {
        this.router.navigate([`/pages/evaluacion/estadisticas-evaluacion/${this.quizzService.getCode()}`]);
    }
    sendAnalisisSucursalesEva() {
        this.router.navigate([`/pages/evaluacion/analisis-sucursales/${this.quizzService.getCode()}`]);
    }
    sendPreferenciasEva(){
        this.router.navigate([`/pages/evaluacion/preferencias-evaluacion/${this.quizzService.getCode()}`]);
    }
    sendPreferenciasSucursalesEva() {
        this.router.navigate([`/pages/evaluacion/preferencias-sucursales/${this.quizzService.getCode()}`]);
    }
}