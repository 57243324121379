import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "src/app/config/config.service";
import { ErrorHandlerService } from "../error-handler.service";
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";
import { catchError } from "rxjs/operators";
import { UsuarioCompartirResponse } from "src/app/shared/models/response/compartir-graficos/usuario-compartir-response.model";
import { CompartirEncuestaRequest } from "src/app/shared/models/request/compartir-graficos/CompartirGraficosRequest.model";
import { ResultadosCompartidosResponse } from "src/app/shared/models/response/compartir-graficos/ResultadosCompartidosResponse.model";
import { InvitacionesResponse } from "src/app/shared/models/response/compartir-graficos/InvitacionesResponse.model";



@Injectable({
    providedIn: 'root'
})
export class CompartirGraficoServices {
    headers: any;
    constructor(private config: ConfigService,
        private http: HttpClient,
        private servicesError: ErrorHandlerService) {
        this.config.headers.subscribe((headers) => {
            if (headers) {
                this.headers = headers;
            }
        });

    }

    getUsuarioCompartir(codigo : string) : Observable<UsuarioCompartirResponse> {
        return this.http.get<UsuarioCompartirResponse>(`${environment.baseUrl}00/01?codigo=${codigo}`, { headers: this.headers })
        .pipe(catchError(this.servicesError.erroHandler));
    }

    enviaInitacionResultadosCompartir(model : CompartirEncuestaRequest) : Observable<ResultadosCompartidosResponse> {
        return this.http.post<ResultadosCompartidosResponse>(`${environment.baseUrl}07/06`,model, { headers: this.headers })
        .pipe(catchError(this.servicesError.erroHandler));
    }

    getLinksCompartir(idEncuesta : number) : Observable<ResultadosCompartidosResponse> {
        return this.http.get<ResultadosCompartidosResponse>(`${environment.baseUrl}07/12?idEncuesta=${idEncuesta}`, { headers: this.headers })
        .pipe(catchError(this.servicesError.erroHandler));
    }
    deleteLinksCompartir(id : number) : Observable<ResultadosCompartidosResponse> {
        return this.http.delete<ResultadosCompartidosResponse>(`${environment.baseUrl}07/01?id=${id}`, { headers: this.headers })
        .pipe(catchError(this.servicesError.erroHandler));
    }

    getInvitaciones(idUsuario : number) : Observable<InvitacionesResponse> {
        return this.http.get<InvitacionesResponse>(`${environment.baseUrl}07/14?idUsuario=${idUsuario}`, { headers: this.headers })
        .pipe(catchError(this.servicesError.erroHandler));
    }
}