
import * as fromNotificacionDetalle from '../actions/notificacion-grupo-detalle.action';


export interface NotificacionDetalleState {

    loaded: boolean;
    grupo: any;
    error: string;
}
export const initialState: NotificacionDetalleState = {
    loaded : false,
    grupo: {},
    error : ''
};

export function reducer(state = initialState, action: fromNotificacionDetalle.NotificacionesDetalleGrupoAction ): NotificacionDetalleState {
    switch (action.type) {
        case fromNotificacionDetalle.SEND_CHILD: {
            const grupo = action.payload;
            return {
                ...state,
                loaded : true,
                grupo :  grupo,
                error: ''
            };
        }
    }
}

export const getState = (state: NotificacionDetalleState) => state;

