import { Action } from '@ngrx/store';
import { MisEvaluacionesRequest } from 'src/app/shared/models/request/evaluacion/mis-evaluaciones.request';
import { ListadoEvaluacionInterna } from 'src/app/shared/models/response/evaluaciones/listado-evaluacion-interna.model';
import {TipoSeccion} from 'src/app/shared/models/response/evaluaciones/Tipo-seccion.model';
import { UsuarioAppResponse } from 'src/app/shared/models/response/grupo/usuario-app-response';
import { UsuariosAppDetalle } from 'src/app/shared/models/response/grupo/usuarios-app';
import { MiembrosResponse } from 'src/app/shared/models/response/miembros-response.model';
import { EliminarEvaluacionResponse } from '../../../shared/models/response/evaluaciones/eliminar-evaluacion.models';

export const EVALUACION_LOAD = '[Evaluacion] Load';
export const EVALUACION_LOAD_SUCCESS = '[Evaluacion] Load Success';
export const EVALUACION_LOAD_FAIL = '[Evaluacion] Load Fail';


export class EvaluacionLoad implements Action {
    readonly type = EVALUACION_LOAD;
    constructor(public payload : MisEvaluacionesRequest) { }
}

export class EvaluacionLoadSuccess implements Action {
    readonly type = EVALUACION_LOAD_SUCCESS;
    constructor(public payload: ListadoEvaluacionInterna) {
     }
}
export class EvaluacionLoadFail implements Action {
    readonly type = EVALUACION_LOAD_FAIL;
    constructor(public payload: any) {
    }
}

export const TIPOSECCION_LOAD = '[Tipo] Load';
export const TIPOSECCION_LOAD_SUCCESS = '[Tipo] Load Success';
export const TIPOSECCION_LOAD_FAIL = '[Tpo] Load Fail';


export class TipoSeccionLoad implements Action {
    readonly type = TIPOSECCION_LOAD;
    constructor(public payload : number) { }
}

export class TipoSeccionLoadSuccess implements Action {
    readonly type = TIPOSECCION_LOAD_SUCCESS;
    constructor(public payload: TipoSeccion) {
     }
}
export class TipoSeccionLoadFail implements Action {
    readonly type = TIPOSECCION_LOAD_FAIL;
    constructor(public payload: any) {
    }
}
    
export  const DELETE_LOAD = '[Evaluacion] Delete';
export  const DELETE_LOAD_SUCCESS = '[Evaluacion] Delete success';
export  const DELETE_LOAD_FAIL = '[Evaluacion] Delete fail';
export class EvaluacionDelete implements Action {
    readonly type = DELETE_LOAD;
    constructor(public payload : number) { }
}

export class EvaluacionDeleteSuccess implements Action {
    readonly type = DELETE_LOAD_SUCCESS;
    constructor(public payload: EliminarEvaluacionResponse) {
     }
}
export class EvaluacionDeleteFail implements Action {
    readonly type = DELETE_LOAD_FAIL;
    constructor(public payload: any) {
    }
}

export  const MIEMBROS_LOAD = '[Miembros] Load';
export  const MIEMBROS_LOAD_SUCCESS = '[Miembros] Load success';
export  const MIEMBROS_LOAD_FAIL = '[Miembros] Load fail';

export class MiembroLoad implements Action {
    readonly type = MIEMBROS_LOAD;
    constructor(public payload : number) { }
}

export class MiembroSuccess implements Action {
    readonly type = MIEMBROS_LOAD_SUCCESS;
    constructor(public payload: UsuarioAppResponse) {
     }
}
export class MiembroFail implements Action {
    readonly type = MIEMBROS_LOAD_FAIL;
    constructor(public payload: any) {
    }
}


export type EvaluacionInternaAction =
EvaluacionLoad |
EvaluacionLoadSuccess |
EvaluacionLoadFail |
TipoSeccionLoad|
TipoSeccionLoadSuccess|
TipoSeccionLoadFail |
EvaluacionDelete |
EvaluacionDeleteSuccess |
EvaluacionDeleteFail |
MiembroLoad |
MiembroSuccess |
MiembroFail;
