
import { PreguntaResponse } from 'src/app/shared/models/response/encuestas/pregunta-response.model';
import * as fromActionEncuestas from '../actions/encuestas-internas.action';

export interface AddPreguntaState {
    loanding: boolean;
    loaded: boolean;
    error: any;
    response: PreguntaResponse;
}

export const initialState: AddPreguntaState = {
    loanding: false,
    loaded: false,
    error : '',
    response: new PreguntaResponse()
};

export function reducer(state= initialState, action: fromActionEncuestas.EncuestaInternaAction ): AddPreguntaState {
    switch (action.type) {
        case fromActionEncuestas.PREGUNTAS_ADD: {
            return {
                ...state,
                loanding: true,
                loaded : false,
                error: '',
                response: new PreguntaResponse()
            };
        }
        case fromActionEncuestas.PREGUNTAS_ADD_SUCCESS : {
            const data = action.payload;
            return {
                ...state,
                loanding: false,
                loaded : true,
                error: '',
                response: data
            };

        }
        case fromActionEncuestas.PREGUNTAS_ADD_FAIL : {
            const data = action.payload;
            return {
                ...state,
                loanding: false,
                loaded : true,
                error: data,
                response: new PreguntaResponse()
            };

        }
    }
}

export const getState = (state: AddPreguntaState) => state;
