
import * as fromModalPerfilAction from '../actions/modal-perfil-action';


export interface ModalPefilState {

    openModal: boolean;
   
}
export const initialState: ModalPefilState = {
    openModal : false,
    
};

export function reducer(state = initialState, action: fromModalPerfilAction.ModalPerfilAction ): ModalPefilState {
    switch (action.type) {
        case fromModalPerfilAction.OPEN_MODAL_PERFIL: {
           
            return {
                ...state,
                openModal : true
            };
        }
        case fromModalPerfilAction.CLOSE_MODAL_PERFIL: {
           
            return {
                ...state,
                openModal : false
            };
        }
    }
}

export const getState = (state: ModalPefilState) => state;

