import { RouteInfo } from "../vertical-sidebar.metadata";

export const ROUTES: RouteInfo[] = [


    {
        path: '/admin/usuarios',
        title: 'Usuarios',
        icon: 'Users',
        class: '',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: []
    },
    
    {
        path: 'icons',
        title: 'Icons',
        icon: 'Feather',
        class: 'has-arrow',
        label: '',
        labelClass: '',
        extralink: false,
        submenu: [
            {
                path: '/icons/fontawesome',
                title: 'Fontawesome',
                icon: 'mdi mdi-emoticon-cool',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },
            {
                path: '/icons/simpleline',
                title: 'Simple Line Icons',
                icon: 'mdi mdi mdi-image-broken-variant',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            },
            {
                path: '/icons/material',
                title: 'Material Icons',
                icon: 'mdi mdi-emoticon',
                class: '',
                label: '',
                labelClass: '',
                extralink: false,
                submenu: []
            }
        ]
    }
];
