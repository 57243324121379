import { Component, OnInit, HostListener } from "@angular/core";
import { Router } from "@angular/router";
declare var $: any;

import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { Utils } from "src/app/shared/tools/utils.services";
import { QuizzService } from "../../services/quizzSelection.services";

@Component({
  selector: "app-full-layout",
  templateUrl: "./full.component.html",
  styleUrls: ["./full.component.scss"],
})
export class FullComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {};
  active = 1;

  constructor(
    public router: Router,
    private serviceQuizz: QuizzService,
    private utils: Utils
  ) { }

  tabStatus = "justified";

  public isCollapsed = false;

  public innerWidth: any;
  public defaultSidebar: any;
  public showSettings = false;
  public showMobileMenu = false;
  public expandLogo = false;
  public showMinLogo: boolean;
  muestraLayout : boolean = false;

  options = {
    theme: "light", // two possible values: light, dark
    dir: "ltr", // two possible values: ltr, rtl
    layout: "vertical", // two possible values: vertical, horizontal
    sidebartype: "full", // four possible values: full, iconbar, overlay, mini-sidebar
    sidebarpos: "fixed", // two possible values: fixed, absolute
    headerpos: "fixed", // two possible values: fixed, absolute
    boxed: "full", // two possible values: full, boxed
    navbarbg: "skin6", // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: "skin5", // six possible values: skin(1/2/3/4/5/6)
    logobg: "skin6", // six possible values: skin(1/2/3/4/5/6)
  };

  Logo() {
    this.expandLogo = !this.expandLogo;
  }
  

  ngOnInit() {
    
    if (this.router.url === "/") {
      this.router.navigate(["/dashboard/classic"]);
    }
    this.defaultSidebar = this.options.sidebartype;
    this.handleSidebar();
   
    this.redirectUser();

  
  }
  redirectUser() {
    let rol = this.serviceQuizz.getRole();
   
    switch (rol) {
      case 1:
        setTimeout(function(){
          this.muestraLayout = true;
         },2000);
        this.utils.sendAdmin();
        break;
      case 2:
       
         this.muestraLayout = true;
        //this.utils.sendUser();
        break;
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: string) {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    switch (this.defaultSidebar) {
      case "full":
      case "iconbar":
        if (this.innerWidth < 1170) {
          if (this.innerWidth >= 1000 && this.innerWidth <= 1024)
            this.showMinLogo = true;
          this.options.sidebartype = "mini-sidebar";
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      case "overlay":
        if (this.innerWidth < 767) {
          this.options.sidebartype = "mini-sidebar";
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  toggleSidebarType() {
    switch (this.options.sidebartype) {
      case "full":
      case "iconbar":
        this.showMinLogo = true;
        this.options.sidebartype = "mini-sidebar";
        break;

      case "overlay":
        this.showMobileMenu = !this.showMobileMenu;
        break;

      case "mini-sidebar":
        this.showMinLogo = false;
        if (this.defaultSidebar === "mini-sidebar") {
          this.options.sidebartype = "full";
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  handleClick(event: boolean) {
    this.showMobileMenu = event;
  }
}
