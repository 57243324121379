import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { FullComponent } from "./layouts/full/full.component";
import { BlankComponent } from "./layouts/blank/blank.component";
import { AuthGuard } from "./config/auth.guard";
import { AdminComponent } from "./layouts/admin/admin.component";
import { MiddleComponent } from './layouts/middle/middle.component';

export const Approutes: Routes = [
  {
    path: "evaluacion/:codigo",
    loadChildren: () =>
      import(
        "src/app/shared/responder_evaluacion/responder-evaluacion.module"
      ).then((m) => m.ResponderEvaluacionModule),
  },
  {
    path: "evaluacion/:codigo/:idUsuario",
    loadChildren: () =>
      import(
        "src/app/shared/responder_evaluacion/responder-evaluacion.module"
      ).then((m) => m.ResponderEvaluacionModule),
  },
  {
    path: "evaluacion/:codigo/:preview",
    loadChildren: () =>
      import(
        "src/app/shared/responder_evaluacion/responder-evaluacion.module"
      ).then((m) => m.ResponderEvaluacionModule),
  },
  {
    path: "bienvenido",
    component: MiddleComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "admin",
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./admin/admin.module").then(
            (m) => m.AdminModule
          ),
      },
    ],
  },
  {
    path: "",
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "/dashboard/encuestas", pathMatch: "full" },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboards/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "pages",
        loadChildren: () =>
          import("./pages/pages.module").then((m) => m.PagesModule),
      }
    ],
  },
  {
    path: "",
    component: BlankComponent,
    children: [
      {
        path: "authentication",
        loadChildren: () =>
          import("./authentication/authentication.module").then(
            (m) => m.AuthenticationModule
          ),
      },
    ],
  },
  {
    path: "quizz",
    component: BlankComponent,
    children: [
      {
        path: "responder/:codigo",
        loadChildren: () =>
          import('./responder/responder-encuesta.module').then((m) => m.ResponderEncuestaModule),
      },
      {
        path: "responder/:codigo/:idUsuario",
        loadChildren: () =>
          import('./responder/responder-encuesta.module').then((m) => m.ResponderEncuestaModule),
      },
      {
        path: "preview/:codigo/:preview",
        loadChildren: () =>
          import('./responder/responder-encuesta.module').then((m) => m.ResponderEncuestaModule),
      },
      {
        path: 'responder/:codigo/:idUsuarioMcl/:niss/:origen',
        loadChildren: () =>
        import('./responder/responder-encuesta.module').then((m) => m.ResponderEncuestaModule),
      }
    ],
  },
  {
    path: "quizz",
    component: BlankComponent,
    children: [
      {
        path: "result/:codigo",
        loadChildren: () =>
          import("src/app/shared/compartir/compartir-resultados.module").then(
            (m) => m.CompartirResultadosModule
          ),
      },
    ],
  },
  {
    path: "quizz",
    component: BlankComponent,
    children: [
      {
        path: "evaluacion/evidencias/:idEva/:idResp/:idMiem",
        loadChildren: () =>
          import("src/app/shared/evidencia-img/evidencia-img.module").then(
            (m) => m.EvidenciaIMG
          ),
      },
    ],
  },
  {
    path: "icons",
    component: BlankComponent,
    loadChildren: () =>
      import("./icons/icons.module").then((m) => m.IconsModule),
  },
  {
    path: "**",
    redirectTo: "authentication/404",
  },
];
