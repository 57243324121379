import { Action } from '@ngrx/store';
import { LoginRequest } from 'src/app/shared/models/request/usuario/loginRequest.model';
import { UserProfileEdit } from 'src/app/shared/models/request/usuario/user-profile-edit';
import { PerfilUsuario } from 'src/app/shared/models/response/perfil/perfil-usuario.model';
import { ResponseBase } from 'src/app/shared/models/response/response-base.model';
import { ListadoUsuariosResponse } from 'src/app/shared/models/response/usuario/listado-usuarios-response.model';
import { UsuarioResponse } from 'src/app/shared/models/response/usuario/loginResponse.model';
import { UsuarioEditResponse } from 'src/app/shared/models/response/usuario/usuario-edit-response.model';


export const LOGIN_USER = '[LoginUser] Login user';
export const LOGIN_USER_SUCCESS = '[LoginUser] Login user success';
export const LOGIN_USER_FAIL = '[LoginUser] Login user fail';
export const LOGIN_CURRENT_STATE = '[LoginUser] Login current state';

export const LOGOFF_USER = '[LogOff] Log Off';
export const LOGOFF_USER_SUCESS = '[LogOff] Log Success';
export const LOGOFF_USER_FAIL = '[LogOff] Log Fail';



export const USER_APP_LOAD = '[User] App Load';
export const USER_APP_LOAD_SUCCESS = '[User] App Success';
export const USER_APP_LOAD_FAIL = '[User] App Fail';


export const USER_EDIT = '[User] Edit';
export const USER_EDIT_SUCCESS = '[User] Edit Success';
export const USER_EDIT_FAIL = '[User] Edit Fail';






// Action login user
export class LoginUser implements Action {
    readonly type = LOGIN_USER;
    constructor(public payload: LoginRequest) {
    }
}

export class LoginUserSuccess implements Action {
    readonly type = LOGIN_USER_SUCCESS;

    constructor(public payload: UsuarioResponse) {
    }
}
export class LoginCurrentState implements Action {
    readonly type = LOGIN_CURRENT_STATE;

    constructor() {
    }
}


export class LoginUserFail implements Action {
    readonly type = LOGIN_USER_FAIL;
    constructor(public payload: any) {}
}

// action log off

export class LogOff implements Action {
    readonly type = LOGOFF_USER;
    constructor() { }
}

export class LogOffSucess implements Action {
    readonly type = LOGOFF_USER_SUCESS;
    constructor() { }
}

export class LogOffFail implements Action {
    readonly type = LOGOFF_USER_FAIL;
    constructor(public payload: any) { }
}




// acciones para obtener el usuario de la aplicaicon

export class UserAppLoad implements Action {
    readonly type = USER_APP_LOAD;
    constructor(public payload: number) { }
}

export class UserAppSuccess implements Action {
    readonly type = USER_APP_LOAD_SUCCESS;
    constructor(public payload: PerfilUsuario ) { }
}

export class UserAppFail implements Action {
    readonly type = USER_APP_LOAD_FAIL;
    constructor(public payload: any) { }
}


// acciones para editar el perfil del usuario


export class UserEdit implements Action {
    readonly type = USER_EDIT;
    constructor(public payload: UserProfileEdit) { }
}

export class UserEditSuccess implements Action {
    readonly type = USER_EDIT_SUCCESS;
    constructor(public payload: UsuarioEditResponse ) { }
}

export class UserEditFail implements Action {
    readonly type = USER_EDIT_FAIL;
    constructor(public payload: any) { }
}







export type UserActions = LoginUser |
                          LoginUserSuccess |
                          LoginUserFail |
                          LogOff |
                          LogOffSucess|
                          LoginUserFail |
                          LoginCurrentState |
                        
                        
                          UserAppLoad |
                          UserAppSuccess |
                          UserAppFail |
                          UserEdit|
                          UserEditSuccess|
                          UserEditFail ;
                      