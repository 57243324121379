
import { ListadoSecciones } from 'src/app/shared/models/response/encuestas/listado-secciones.model';
import * as fromActionEncuestas from '../actions/encuestas-internas.action';

export interface SeccionesState {
    loanding: boolean;
    loaded: boolean;
    listado: ListadoSecciones;
    error: string;
}
export const initialState: SeccionesState = {
    loanding: false,
    loaded : false,
    listado : new ListadoSecciones(),
    error: ''
};


export function reducer(state = initialState, action: fromActionEncuestas.EncuestaInternaAction ): SeccionesState {
    switch (action.type) {
        case fromActionEncuestas.SECCIONES_LOAD :
             return {
                 ...state,
                 loanding: true,
                 loaded : false,
                 listado : new ListadoSecciones(),
                 error : ''
             };
             break;
             case fromActionEncuestas.SECCIONES_LOAD_SUCCESS :
                const payload = action.payload;
                return {
                    ...state,
                    loanding: false,
                    loaded : true,
                    listado : payload ,
                    error : ''
                };
            break;
            case fromActionEncuestas.SECCIONES_LOAD_FAIL :
                const error = action.payload;
                return {
                    ...state,
                    loanding: false,
                    loaded : false,
                    listado: new ListadoSecciones(),
                    error : error
                };

                break;
    }
}

export const getState = (state: SeccionesState) => state;

