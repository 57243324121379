<nav class="sidebar-nav">
    <ul id="sidebarnav">
        <!-- First level menu -->
        <li class="sidebar-item" [ngClass]="[sidebarnavItem.ddclass]" [class.active]="showMenu === sidebarnavItem.title" *ngFor="let sidebarnavItem of sidebarnavItems" [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
            <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">{{sidebarnavItem.title | translate}}</div>
            <a class="sidebar-link waves-effect waves-dark" [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null" [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink;" (mouseover)="addExpandClass(sidebarnavItem.title)" [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
                <!-- <i [ngClass]="[sidebarnavItem.icon]"></i> -->
                <i-feather [name]="sidebarnavItem.icon" [ngClass]="[sidebarnavItem.icon]"></i-feather>
                <span class="hide-menu">{{sidebarnavItem.title | translate}}</span>
            </a>
            <!-- Second level menu -->
            <ul aria-expanded="false" class="collapse first-level" *ngIf="sidebarnavItem.submenu.length > 0" [ngClass]="{'in' : showMenu === sidebarnavItem.title }">
                <li class="sidebar-item" *ngFor="let sidebarnavSubItem of sidebarnavItem.submenu" [class.active]="showSubMenu === sidebarnavSubItem.title" [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'active'">
                    <a class="sidebar-link" [routerLink]="sidebarnavSubItem.submenu.length > 0 ? null : [sidebarnavSubItem.path]" [routerLinkActive]="sidebarnavSubItem.submenu.length != 0 ? '' : 'router-link-active'" [ngClass]="[sidebarnavSubItem.class]" *ngIf="!sidebarnavSubItem.extralink;"
                        (mouseover)="addActiveClass(sidebarnavSubItem.title)">
                        <i [ngClass]="[sidebarnavSubItem.icon]"></i>
                        <span class="hide-menu">{{sidebarnavSubItem.title | translate}}</span>
                    </a>
                    <!-- Third level menu -->
                    <ul aria-expanded="false" class="collapse second-level" *ngIf="sidebarnavSubItem.submenu.length > 0" [ngClass]="{'in' : showSubMenu === sidebarnavSubItem.title }">
                        <li class="sidebar-item" *ngFor="let sidebarnavSubsubItem of sidebarnavSubItem.submenu" [ngClass]="[sidebarnavSubsubItem.class]">
                            <a class="sidebar-link" [routerLinkActive]="sidebarnavSubsubItem.submenu.length != 0 ? '' : ''" [routerLink]="sidebarnavSubsubItem.submenu.length > 0 ? null : [sidebarnavSubsubItem.path]" *ngIf="!sidebarnavSubsubItem.extralink;">
                                <i [ngClass]="[sidebarnavSubsubItem.icon]"></i>
                                <span class="hide-menu">{{sidebarnavSubsubItem.title | translate}}</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
</nav>