
import { Invitaciones } from 'src/app/shared/models/response/compartir-graficos/Invitaciones.model';
import { LinksCompartirResultados } from 'src/app/shared/models/response/compartir-graficos/LinksCompartirResultados.model';
import { UsuarioCompartirResponse } from 'src/app/shared/models/response/compartir-graficos/usuario-compartir-response.model';
import { ResponseBase } from 'src/app/shared/models/response/response-base.model';
import * as fromCompartirGraficos from '../actions/compartir-graficos.action';

export interface CompartirGraficosState {
    loaded: boolean;
    loanding: boolean;
    usuario: UsuarioCompartirResponse,
    error : any,
    invitacion : ResponseBase,
    elimino : boolean,
    consultoLink : boolean,
    links : LinksCompartirResultados [],
    invitacionesLoaded : boolean,
    invitaciones : Invitaciones[]
}

export const initialState: CompartirGraficosState = {
    loaded: false,
    loanding: false,
    usuario: new UsuarioCompartirResponse(),
    error : '',
    invitacion :  new ResponseBase(),
    elimino : false,
    consultoLink : false,
    links : [],
    invitacionesLoaded :false,
    invitaciones : []
}

export function reducer(state = initialState, action: fromCompartirGraficos.CompartirGraficoAction): CompartirGraficosState {
    switch (action.type) {
        case fromCompartirGraficos.BUSCAR_USUARIO_COMPARTIR_LOAD: {
            return {
                ...state,
                loanding: true,
                loaded: false,
                usuario: new UsuarioCompartirResponse(),
                error: '',
                invitacion :  new ResponseBase(),
                links : state.links,
                elimino : false,
                consultoLink : false,
                invitacionesLoaded : false,
                invitaciones : state.invitaciones
            }
        }
        case fromCompartirGraficos.BUSCAR_USUARIO_COMPARTIR_LOAD_SUCCESS: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                usuario : payload,
                error: '',
                invitacion :  new ResponseBase(),
                links : state.links,
                elimino : false,
                consultoLink : false,
                invitacionesLoaded : false,
                invitaciones : state.invitaciones
            }
        }
        case fromCompartirGraficos.BUSCAR_USUARIO_COMPARTIR_LOAD_ERROR: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                usuario: new UsuarioCompartirResponse(),
                error: 'Error',
                invitacion :  new ResponseBase(),
                links : state.links,
                elimino : false,
                consultoLink : false,
                invitacionesLoaded : false,
                invitaciones : state.invitaciones
            }
        }

        case fromCompartirGraficos.ENVIAR_INVITACION_GRAFICOS: {
            
            return {
                ...state,
                loanding: true,
                loaded: false,
                usuario: state.usuario,
                error: '',
                invitacion :  new ResponseBase(),
                links : state.links,
                elimino : false,
                consultoLink : false,
                invitacionesLoaded : false,
                invitaciones : state.invitaciones
            }
        }
        case fromCompartirGraficos.ENVIAR_INVITACION_GRAFICOS_SUCCESS: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                usuario: state.usuario,
                error: '',
                invitacion : payload,
                links : payload.links ,
                elimino : false,
                consultoLink : true,
                invitacionesLoaded : false,
                invitaciones : state.invitaciones
            }
        }
        case fromCompartirGraficos.ENVIAR_INVITACION_GRAFICOS_FAIL: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                usuario: state.usuario,
                error: 'Error',
                invitacion :  new ResponseBase(),
                links : state.links,
                elimino : false,
                consultoLink : false,
                invitacionesLoaded : false,
                invitaciones : state.invitaciones
               
            }
        }
        case fromCompartirGraficos.GET_INVITADO_RESULTADOS: {
            const payload = action.payload;
            return {
                ...state,
                loanding: true,
                loaded: false,
                usuario: state.usuario,
                error: '',
                invitacion : state.invitacion,
                links : state.links,
                elimino : false,
                consultoLink : false,
                invitacionesLoaded : false,
                invitaciones : state.invitaciones
               
            }
        }
        case fromCompartirGraficos.GET_INVITADO_RESULTADOS_SUCCESS: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                usuario: state.usuario,
                error: '',
                invitacion : state.invitacion,
                links : payload.links,
                elimino : false,
                consultoLink : true,
                invitacionesLoaded : false,
                invitaciones : state.invitaciones
               
            }
        }
        case fromCompartirGraficos.GET_INVITADO_RESULTADOS_FAIL: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                usuario: state.usuario,
                error: 'Error',
                invitacion : state.invitacion,
                links : [],
                elimino : false,
                consultoLink : false,
                invitacionesLoaded : false,
                invitaciones : state.invitaciones
               
            }
        }
        case fromCompartirGraficos.DELETE_LINK_COMPARTIR: {
           
            return {
                ...state,
                loanding: true,
                loaded: false,
                usuario: state.usuario,
                error: '',
                invitacion : state.invitacion,
                links : state.links,
                elimino : false,
                consultoLink : false,
                invitacionesLoaded : false,
                invitaciones : state.invitaciones
               
            }
        }
        case fromCompartirGraficos.DELETE_LINK_COMPARTIR_SUCCESS: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                usuario: state.usuario,
                error: '',
                invitacion : state.invitacion,
                links : payload.links,
                elimino : true,
                consultoLink : false,
                invitacionesLoaded : false,
                invitaciones : state.invitaciones
               
            }
        }
        case fromCompartirGraficos.DELETE_LINK_COMPARTIR_FAIL: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                usuario: state.usuario,
                error: '',
                invitacion : state.invitacion,
                links : payload.links,
                elimino : false,
                consultoLink : false,
                invitacionesLoaded : false,
                invitaciones : state.invitaciones
               
            }
        }
        case fromCompartirGraficos.INVITACIONES_LOAD: {
            const payload = action.payload;
            return {
                ...state,
                loanding: true,
                loaded: false,
                usuario: state.usuario,
                error: '',
                invitacion : state.invitacion,
                links : state.links,
                elimino : false,
                consultoLink : false,
                invitacionesLoaded : false,
                invitaciones : state.invitaciones
               
            }
        }
        case fromCompartirGraficos.INVITACIONES_LOAD_SUCCESS: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                usuario: state.usuario,
                error: '',
                invitacion : state.invitacion,
                links : state.links,
                elimino : false,
                consultoLink : false,
                invitacionesLoaded : true,
                invitaciones : payload.invitaciones
               
            }
        }
        case fromCompartirGraficos.INVITACIONES_LOAD_ERROR: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                usuario: state.usuario,
                error: 'Error',
                invitacion : state.invitacion,
                links : state.links,
                elimino : false,
                consultoLink : false,
                invitacionesLoaded : false,
                invitaciones : []
               
            }
        }
    }
}



export const getState = (state: CompartirGraficosState) => state;