
import { AltaGrupoResponse } from 'src/app/shared/models/response/grupo/alta-grupo-response';
import { ListadoGrupoResponse } from 'src/app/shared/models/response/grupo/listado-grupo-response';
import { MiembroQuizz } from 'src/app/shared/models/response/grupo/miembro-quizz';
import { PutGrupoResponse } from 'src/app/shared/models/response/grupo/put-grupo-response';
import { UsuariosAppDetalle } from 'src/app/shared/models/response/grupo/usuarios-app';
import * as fromDetalleGrupoAction from '../actions/grupo-detalle-action';

export interface DetalleGrupoState {
   loanding: boolean;
   loaded: boolean;
   inserto: boolean;
   edito: boolean;
   eliminoMiembro: boolean;
   eliminoGrupo: boolean;
   consulto: boolean;
   añadirMiembros: AltaGrupoResponse;
   error: any;
   grupo: PutGrupoResponse;
   MiembroSeleccionado: UsuariosAppDetalle;
   miembros: MiembroQuizz[];
   allUser: UsuariosAppDetalle [];
   usuariosNoAsignados: UsuariosAppDetalle [];
   usuariosAsignados: UsuariosAppDetalle [];
   asigno: boolean;
   usuariosGrupos: ListadoGrupoResponse;
   nombreGrupo: string;
}


export const initialState: DetalleGrupoState = {
   loanding: false,
   loaded : false,
   inserto: false,
   edito : false,
   eliminoGrupo: false,
   eliminoMiembro: false,
   consulto: false,
   añadirMiembros : new AltaGrupoResponse(),
   error: '',
   grupo: new PutGrupoResponse(),
   MiembroSeleccionado: new UsuariosAppDetalle(),
   miembros: [],
   allUser: [],
   usuariosNoAsignados: [],
   usuariosAsignados: [],
   asigno: false,
   usuariosGrupos : new ListadoGrupoResponse(),
   nombreGrupo : ''
};

export function reducer(state = initialState, action: fromDetalleGrupoAction.DetalleGrupoAction ): DetalleGrupoState {
    switch (action.type) {
        case fromDetalleGrupoAction.ASIGNA_GRUPOS_LOAD: {
            const payload = action.payload;
            return {
                ...state,
                loanding: true,
                error: '',
                miembros : state.miembros,
                grupo :  new PutGrupoResponse(),
                edito : false,
                asigno : false,
                inserto : false,
                eliminoGrupo: false,
                eliminoMiembro: false,
                allUser: [...state.allUser],
                usuariosNoAsignados:  [...state.usuariosNoAsignados],
                usuariosAsignados: [...state.usuariosAsignados]
            };
        }
        case fromDetalleGrupoAction.ASIGNA_GRUPOS_LOAD_SUCCESS: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                inserto: true,
                asigno: false,
                añadirMiembros : payload,
                miembros : state.miembros,
                grupo : state.grupo,
                edito : false,
                eliminoGrupo: false,
                eliminoMiembro: false,
                allUser: [...state.allUser],
                usuariosNoAsignados:  [...state.usuariosNoAsignados],
                usuariosAsignados: [...state.usuariosAsignados]
            };

        }
        case fromDetalleGrupoAction.ASIGNA_GRUPOS_LOAD_FAIL: {
            const payload = action.payload;
            return {
                ...state,
                error: payload,
                inserto: false,
                consulto: false,
                grupo : state.grupo,
                edito : false,
                eliminoGrupo: false,
                eliminoMiembro: false,
                allUser: [...state.allUser],
                usuariosNoAsignados:  [...state.usuariosNoAsignados],
                usuariosAsignados: [...state.usuariosAsignados]
            };

        }
        case fromDetalleGrupoAction.GET_MIEMBROS_GRUPOS: {
            const payload = action.payload;
            return {
                ...state,
                loanding: true,
                inserto: false,
                consulto: false,
                asigno: false,
                error: '',
                añadirMiembros : state.añadirMiembros,
                grupo : state.grupo,
                edito : false,
                eliminoGrupo: false,
                eliminoMiembro: false,
                allUser: [...state.allUser],
                usuariosNoAsignados:  [...state.usuariosNoAsignados],
                usuariosAsignados: [...state.usuariosAsignados]
            };
        }
        case fromDetalleGrupoAction.GET_MIEMBROS_GRUPOS_SUCCESS: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                consulto: true,
                loaded: true,
                inserto: false,
                añadirMiembros: state.añadirMiembros,
                grupo : state.grupo,
                edito : false,
                eliminoGrupo: false,
                asigno: false,
                eliminoMiembro: false,
                allUser: payload.allUser,
                usuariosNoAsignados: payload.noAsignados,
                usuariosAsignados: payload.asignados,
                miembros : payload.miembros,
                nombreGrupo : payload.nombre
            };

        }
        case fromDetalleGrupoAction.GET_MIEMBROS_GRUPOS_FAIL: {
            const payload = action.payload;
            return {
                ...state,
                error: '',
                inserto: false,
                consulto: false,
                grupo : state.grupo,
                edito : false,
                eliminoGrupo: false,
                eliminoMiembro: false,
                allUser: [...state.allUser],
                usuariosNoAsignados:  [...state.usuariosNoAsignados],
                usuariosAsignados: [...state.usuariosAsignados]
            };

        }


        case fromDetalleGrupoAction.EDITA_GRUPO: {
            const payload = action.payload;
            return {
                ...state,
                loanding: true,
                inserto: false,
                consulto: false,
                error: '',
                añadirMiembros : state.añadirMiembros,
                miembros : state.miembros,
                grupo : state.grupo,
                edito : false,
                asigno: false,
                eliminoGrupo: false,
                eliminoMiembro: false,
                allUser: [...state.allUser],
                usuariosNoAsignados:  [...state.usuariosNoAsignados],
                usuariosAsignados: [...state.usuariosAsignados]
            };
        }
        case fromDetalleGrupoAction.EDITA_GRUPO_SUCCESS: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                consulto: false,
                loaded: true,
                inserto: false,
                añadirMiembros: state.añadirMiembros,
                miembros : state.miembros,
                edito : true,
                eliminoGrupo: false,
                asigno: false,
                eliminoMiembro: false,
                usuariosGrupos: payload,
                allUser: [...state.allUser],
                usuariosNoAsignados:  [...state.usuariosNoAsignados],
                usuariosAsignados: [...state.usuariosAsignados]
            };

        }
        case fromDetalleGrupoAction.EDITA_GRUPO_FAIL: {
            const payload = action.payload;
            return {
                ...state,
                error: '',
                inserto: false,
                consulto: false,
                añadirMiembros : state.añadirMiembros,
                miembros : state.miembros,
                grupo : state.grupo,
                edito : false,
                eliminoGrupo: false,
                eliminoMiembro: false,
                allUser: [...state.allUser],
                usuariosNoAsignados:  [...state.usuariosNoAsignados],
                usuariosAsignados: [...state.usuariosAsignados]
            };

        }
        case fromDetalleGrupoAction.ADD_MIEMBRO: {
            const payload = action.payload;
            const asignados = [...state.usuariosAsignados, payload];
            return {
                ...state,
                error: '',
                inserto: false,
                consulto: false,
                añadirMiembros : state.añadirMiembros,
                miembros : {...state.miembros },
                grupo : state.grupo,
                edito : false,
                eliminoGrupo: false,
                asigno: true,
                eliminoMiembro: false,
                allUser: [...state.allUser],
                usuariosNoAsignados: [...state.usuariosNoAsignados.filter((miembro) => miembro.id !== payload.id)],
                usuariosAsignados: asignados
            };

        }
        case fromDetalleGrupoAction.REMOVE_MIEMBRO: {
            const payload = action.payload;
            return {
                ...state,
                error: '',
                inserto: false,
                consulto: false,
                añadirMiembros : state.añadirMiembros,
                miembros : {...state.miembros },
                grupo : state.grupo,
                edito : false,
                eliminoGrupo: false,
                asigno: true,
                eliminoMiembro: false,
                allUser: [...state.allUser],
                usuariosNoAsignados: [...state.usuariosNoAsignados, payload],
                usuariosAsignados: [...state.usuariosAsignados.filter((miembro) => miembro.id !== payload.id)]
            };

        }
        case fromDetalleGrupoAction.DELETE_GRUPO : {
            return {
                ...state
            }
        }
        case fromDetalleGrupoAction.DELETE_GRUPO_SUCCESS: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                eliminoGrupo : payload.tipoRespuesta === 0 ? true : false,
                loaded : true,
                usuariosGrupos: payload,
                
            };
        }
        case fromDetalleGrupoAction.DELETE_GRUPO_FAIL : {
            const payload = action.payload;
            return {
                ...state,
                error : payload,
                allUser: [...state.allUser],
                usuariosNoAsignados:  [...state.usuariosNoAsignados],
                usuariosAsignados: [...state.usuariosAsignados]
            };
        }

        case fromDetalleGrupoAction.DELETE_MIEMBRO_ASIGNADO : {
            const payload = action.payload;
            return {
                ...state,
                error : '',
                allUser: [...state.allUser],
                usuariosNoAsignados:  [...state.usuariosNoAsignados],
                usuariosAsignados: [...state.usuariosAsignados],
                eliminoMiembro: false,
                añadirMiembros: {...state.añadirMiembros},
                miembros : {...state.miembros},
                loanding: false,
                loaded: false,
                consulto: false,
                inserto: false,
                edito: false,
                asigno: false,
                
            };
        }
        case fromDetalleGrupoAction.DELETE_MIEMBRO_ASIGNADO_SUCCESS : {
            const payload = action.payload;
            return {
                ...state,
                error : '',
                allUser: [...state.allUser],
                usuariosNoAsignados:  [...state.usuariosNoAsignados],
                usuariosAsignados: [...state.usuariosAsignados],
                eliminoMiembro: true,
                añadirMiembros: {...state.añadirMiembros},
                miembros : {...state.miembros},
                loanding: false,
                loaded: true,
                consulto: false,
                inserto: false,
                edito: false,
                asigno: false,
            };
        }
        case fromDetalleGrupoAction.DELETE_MIEMBRO_ASIGNADO_FAIL : {
            const payload = action.payload;
            return {
                ...state,
                error : payload,
                allUser: [...state.allUser],
                usuariosNoAsignados:  [...state.usuariosNoAsignados],
                usuariosAsignados: [...state.usuariosAsignados],
                eliminoMiembro: true,
                añadirMiembros: {...state.añadirMiembros},
                miembros : {...state.miembros},
                loanding: false,
                loaded: true
            };
        }
    }
}
export const getState = (state: DetalleGrupoState) => state;

