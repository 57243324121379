import { switchMap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { DepartamentoServices } from '../../services/departamentos/departamentos.service';
import * as fromDepartamentoAction from '../actions/departamentos.action';

@Injectable()
export class DepartamentoEffects {
    constructor(private actions$: Actions,
                private departamentoServices: DepartamentoServices) {

                }
                @Effect()
                departamento$: Observable<Action> = this.actions$.pipe(
                    ofType(fromDepartamentoAction.DEPARTAMENTO_LOAD),
                    switchMap(() => this.departamentoServices.getDepartamentos()
                    .pipe(map(response => {
                        return new fromDepartamentoAction.DepartamentoSuccess(response);
                    }), catchError(error => of(new fromDepartamentoAction.DepartamentoFail(error))))
                ));
}
