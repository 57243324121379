import { Action } from '@ngrx/store';
import { GrupoRequest } from 'src/app/shared/models/request/grupo/grupo-request.model';
import { AltaGrupoResponse } from 'src/app/shared/models/response/grupo/alta-grupo-response';
import { ListadoGrupoResponse } from 'src/app/shared/models/response/grupo/listado-grupo-response';



export const GRUPO_LOAD = '[Grupo] Load';
export const GRUPO_LOAD_SUCCESS = '[Grupo] Load Success';
export const GRUPO_LOAD_FAIL = '[Grupo] Load Fail';

export class GrupoLoad implements Action {
    readonly type = GRUPO_LOAD;
    constructor(public payload: number) { }
}

export class GrupoLoadSucess implements Action {
    readonly type = GRUPO_LOAD_SUCCESS;
    constructor(public payload: ListadoGrupoResponse) { }
}

export class GrupoLoadFail implements Action {
    readonly type = GRUPO_LOAD_FAIL;
    constructor(public payload: any) { }
}



export const ALTA_GRUPOS = '[Alta Grupo]';
export const ALTA_GRUPOS_SUCCESS = '[Alta Grupo]  Success';
export const ALTA_GRUPOS_FAIL = '[Alta Grupo]  Fail';

export class AltaGrupo implements Action {
    readonly type = ALTA_GRUPOS;
    constructor(public payload: GrupoRequest) { }
}

export class AltaGrupoSuccess implements Action {
    readonly type = ALTA_GRUPOS_SUCCESS;
    constructor(public payload: AltaGrupoResponse) { }
}

export class AltaGrupoFail implements Action {
    readonly type = ALTA_GRUPOS_FAIL;
    constructor(public payload: any) { }
}






export const SEND_DATA_GRUPO_DELETE = '[Send] Data Grupo Delete';
export const SEND_DATA_GRUPO_EDITO = '[Send] Data Grupo Edito';

export class SendDataGrupoDelete implements Action {
    readonly type = SEND_DATA_GRUPO_DELETE;
    constructor(public payload: any) { }
}
export class SendDataGrupoEdit implements Action {
    readonly type = SEND_DATA_GRUPO_EDITO;
    constructor(public payload: any) { }
}




export type GruposAction =
GrupoLoad |
GrupoLoadSucess |
GrupoLoadFail |

AltaGrupo |
AltaGrupoSuccess |
AltaGrupoFail |

SendDataGrupoDelete |
SendDataGrupoEdit ;



