import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import swal from 'sweetalert';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor( private router: Router) { }

  erroHandler(error: HttpErrorResponse) {
   
     
     if (error.status === 401) {
     localStorage.clear();
     window.location.href = '/#/authentication/login';
    } else {
      swal('¡Oops!', 'No es posible realizar la operación' , 'error');
    }
    return throwError(error.message || 'server Error');
  }

}
