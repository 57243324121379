import { Action } from '@ngrx/store';
import { ObtenerQuizzResponder } from 'src/app/shared/models/request/encuesta/obtener-quizz-responder.request';
import { ResponderPreguntaEV } from 'src/app/shared/models/response/evaluaciones/responder-pregunta';
import { QuizzResponse } from 'src/app/shared/models/response/encuestas/quizz-response';


export const GET_EVALUACION_LOAD = '[Get_Evaluacion] Load';
export const GET_EVALUACION_SUCCESS = '[Get_Evaluacion] Success';
export const GET_EVALUACION_FAIL = '[Get_Evaluacion] Fail';

export class GetEvaluacionLoad implements Action {
    readonly type = GET_EVALUACION_LOAD;
    constructor(public payload: ObtenerQuizzResponder) {
    }
}
export class GetEvaluacionSuccess implements Action {
    readonly type = GET_EVALUACION_SUCCESS;

    constructor(public payload: QuizzResponse) {
    }
}
export class GetEvaluacionFail implements Action {
    readonly type = GET_EVALUACION_FAIL;

    constructor(public payload: any) {
    }
}
export const RESPONDER_EVALUACION_LOAD = '[Responer_Evaluacion] Load';
export const RESPONDER_EVALUACION_SUCCESS = '[Responder_Evaluacion] Success';
export const RESPONDER_EVALUACION_FAIL = '[Responder_Evaluacion] Fail';

export class ResponderEvaluacionLoad implements Action {
    readonly type = RESPONDER_EVALUACION_LOAD;
    constructor(public payload: QuizzResponse) {
    }
}
export class ResponderEvaluacionSuccess implements Action {
    readonly type = RESPONDER_EVALUACION_SUCCESS;

    constructor(public payload: QuizzResponse) {
    }
}
export class ResponderEvaluacionFail implements Action {
    readonly type = RESPONDER_EVALUACION_FAIL;

    constructor(public payload: any) {
    }
}
export const RESPONDE_PREGUNTA_EVALUACION = '[Responer_Evaluacion] Responder Pregunta';

export class ResponderPreguntaEvaluacion implements Action {
    readonly type = RESPONDE_PREGUNTA_EVALUACION;

    constructor(public payload: ResponderPreguntaEV) {
    }
}
export const INICIAR_EVALUACION = '[Responer_Evaluacion] Iniciar Evaluacion';

export class IniciarEvaluacion implements Action {
    readonly type = INICIAR_EVALUACION;

    constructor() {
    }
}
export const TERMINO_EVALUACION = '[Responer_Evaluacion] Termino';

export class TerminoEvaluacion implements Action {
    readonly type = TERMINO_EVALUACION;
    constructor() {
    }
}
export const REINICIA_EVALUACION= '[Responer_Evaluacion] Reinici';

export class ReiniciaEvaluacion implements Action {
    readonly type = REINICIA_EVALUACION;
    constructor() {
    }
}

//export const SELECCIONA_SUCURSAL = '[Responer_Evaluacion] Elige Sucursal';

// export class EligeSucursal implements Action {
//     readonly type = SELECCIONA_SUCURSAL;
//     constructor(public payload: number) {
//     }
// }




export type EvaluacionAction =
GetEvaluacionLoad |
GetEvaluacionSuccess |
GetEvaluacionFail |
ResponderEvaluacionLoad |
ResponderEvaluacionSuccess |
ResponderEvaluacionFail |
ResponderPreguntaEvaluacion |
IniciarEvaluacion|
TerminoEvaluacion|
 ReiniciaEvaluacion ;
// EligeSucursal ;

