import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfigService } from 'src/app/config/config.service';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../error-handler.service';
import 'rxjs/add/operator/map'
import { ListadoUsuarioEncuestaResponse } from 'src/app/shared/models/response/usuario-encuesta/listado-usuario-encuesta-response.model';
import { UsuarioEncuestaResponse } from 'src/app/shared/models/response/usuario-encuesta/usuario-encuesta-response.model';
import { UsuarioEncuesta } from 'src/app/shared/models/request/usuario-encuesta/usuario-encuesta.model';
import { UsuarioDeleteResponse } from 'src/app/shared/models/response/usuario-encuesta/usuario-delete-response.model';
import { ImportaMiembrosRequest } from 'src/app/shared/models/request/grupo/importa-miembros-request.model';



@Injectable({
  providedIn: 'root'
})
export class UsuarioGrupoService {
  headers: any;

  constructor(private config: ConfigService,
    private servicesError: ErrorHandlerService,
    private http: HttpClient) {
    this.config.headers.subscribe((headers) => {
      if (headers) {
        this.headers = headers;
      }
    });
  }
  getListadoUsuariosGrupos(idUsuario: number): Observable<ListadoUsuarioEncuestaResponse> {
   
    return this.http.get<ListadoUsuarioEncuestaResponse>(`${environment.baseUrl}09/00?IdUsuario=${idUsuario}`, { headers: this.headers })
      .pipe(catchError(this.servicesError.erroHandler));
  }
  insertUsuarioGrupo(model: UsuarioEncuesta): Observable<UsuarioEncuestaResponse> {
    return this.http.post<UsuarioEncuestaResponse>(`${environment.baseUrl}09/00`, model, { headers: this.headers })
      .pipe(catchError(this.servicesError.erroHandler));
  }
  updateUsuarioGrupo(model: UsuarioEncuesta): Observable<UsuarioEncuestaResponse> {
    return this.http.put<UsuarioEncuestaResponse>(`${environment.baseUrl}09/00`, model, { headers: this.headers })
      .pipe(catchError(this.servicesError.erroHandler));
  }
  deleteUsuarioGrupo(idUsuarioGrupo: number): Observable<UsuarioDeleteResponse> {
    return this.http.delete<UsuarioDeleteResponse>(`${environment.baseUrl}09/00?IdUsuario=${idUsuarioGrupo}`, { headers: this.headers })
      .pipe(catchError(this.servicesError.erroHandler));
  }
  getFileLayout(): Observable<Blob> {
    return this.http.post<Blob>(`${environment.baseUrl}09/01`, [],
      { responseType: 'blob' as 'json' });


  }
  importaMiembros(model: ImportaMiembrosRequest): Observable<ListadoUsuarioEncuestaResponse> {
    return this.http.post<ListadoUsuarioEncuestaResponse>(`${environment.baseUrl}09/03`, model , { headers: this.headers })
    .pipe(catchError(this.servicesError.erroHandler));
  }

}
