
import { Preguntas } from 'src/app/shared/models/response/encuestas/preguntas.model';
import * as fromEncuestaAction from '../actions/encuestas-internas.action';

export interface PreguntaSelectState {
   loanding: boolean;
   loaded: boolean;
   pregunta: Preguntas;
   error: any;
}

export const initialState: PreguntaSelectState = {
   loanding: false,
   loaded : false,
   pregunta: new Preguntas,
   error: ''
};

export function reducer(state = initialState, action: fromEncuestaAction.EncuestaInternaAction ): PreguntaSelectState {
    switch (action.type) {
        case fromEncuestaAction.PREGUNTAS_SELECTED: {
            const payload = action.payload;
            return {
                ...state,
                loanding: true,
                loaded: false,
                pregunta : payload,
                error: ''
            };
        }
        case fromEncuestaAction.PREGUNTAS_SELECTED_SUCCESS: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                pregunta : payload,
                error: ''
            };

        }

        case fromEncuestaAction.PREGUNTAS_SELECTED_FAIL: {
            const data = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                pregunta: new Preguntas(),
                error : data
            };

        }
     }
   }
   export const getState = (state: PreguntaSelectState) => state;
