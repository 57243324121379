import { Action } from '@ngrx/store';
import { DashBoardEvalGeneralResponse } from 'src/app/shared/models/response/dashboard/general/dash-board-eval-general-response.model';


export const DASHBOARD_EVAL_GENERAL_LOAD = '[Dashboard Eval General] Load';
export const DASHBOARD_EVAL_GENERAL_LOAD_SUCCESS = '[Dashboard Eval General] Load Success';
export const DASHBOARD_EVAL_GENERAL_LOAD_FAIL = '[Dashboard Eval General] Load Fail';
export class DashBoardEvalGeneral implements Action {
    readonly type = DASHBOARD_EVAL_GENERAL_LOAD;
    constructor() {
    }
}
export class DashBoardEvalSuccess implements Action {
    readonly type = DASHBOARD_EVAL_GENERAL_LOAD_SUCCESS;
    constructor(public payload: DashBoardEvalGeneralResponse) {
    }
}

export class DashBoardEvalFail implements Action {
    readonly type = DASHBOARD_EVAL_GENERAL_LOAD_FAIL;
    constructor(public payload: any) {
    }
}
export type DashBoardEvalGeneralAction = 
DashBoardEvalGeneral | 
DashBoardEvalSuccess | 
DashBoardEvalFail ;

