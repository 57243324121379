
import { TipoRespuesta } from 'src/app/shared/models/enums/enumTipoRespuesta.model';
import { ListadoEvaluacionInterna } from 'src/app/shared/models/response/evaluaciones/listado-evaluacion-interna.model';
import * as fromEvaluacionInterna from '../../actions/evaluaciones/mis-evaluaciones-action';
import {EliminarEvaluacionResponse} from 'src/app/shared/models/response/evaluaciones/eliminar-evaluacion.models';


export interface EvaluacionInternaState {
    agregar : boolean;
    loanding: boolean;
    loaded: boolean;
    evaluacionConsultar: ListadoEvaluacionInterna;
    error: string;
}
export const initialState: EvaluacionInternaState = {
    loanding: false,
    loaded : false,
    evaluacionConsultar : new ListadoEvaluacionInterna(),
    error : '',
    agregar : false
};

export function reducer(state = initialState, action: fromEvaluacionInterna.EvaluacionInternaAction ): EvaluacionInternaState {
    switch (action.type) {
        case fromEvaluacionInterna.EVALUACION_LOAD: {
            return {
                ...state,
                loanding: true,
                loaded : false,
                evaluacionConsultar :  new ListadoEvaluacionInterna(),
                error: '',
                agregar : false
            };
        }
        case fromEvaluacionInterna.EVALUACION_LOAD_SUCCESS: {
            const evaluacionConsultar = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                evaluacionConsultar,
                error : '',
                agregar : false
            };
        }
        case fromEvaluacionInterna.EVALUACION_LOAD_FAIL : {
            const data = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                evaluacionConsultar : new ListadoEvaluacionInterna(),
                error : '',
                agregar : false
            };

        }

        // case fromEvaluacionInterna.ADD_ENCUESTA : {
        //     return {
        //         ...state,
        //         loanding: false,
        //         loaded: false,
        //         encuestaInterna : state.encuestaInterna,
        //         error : '',
        //         agregar : true

        case fromEvaluacionInterna.DELETE_LOAD: {
            return {
                ...state,
                loanding: true,
                loaded : false,
                evaluacionConsultar : state.evaluacionConsultar,
                error: '',
                agregar : false
            };
        }
        case fromEvaluacionInterna.DELETE_LOAD_SUCCESS: {
            const payload = action.payload;
            let listado = JSON.parse(JSON.stringify(state.evaluacionConsultar)) as ListadoEvaluacionInterna; 
            
            if(payload.tipoRespuesta == TipoRespuesta.Exito){
                let encuestas = listado.evaluacionInt.filter(x=> x.id != payload.idEvaluacion);
                listado.evaluacionInt = encuestas;
            }
            
            return {
                ...state,
                loanding: false,
                loaded: true,
                evaluacionConsultar : listado,
                error : '',
                agregar : false
            };
        }
        case fromEvaluacionInterna.DELETE_LOAD_FAIL : {
            const data = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: false,
                evaluacionConsultar : state.evaluacionConsultar,
                error : data,
                agregar : false
            };

        }

  

        
    }
}

export const getState = (state: EvaluacionInternaState) => state;
export const getEvaluaciones= (state: EvaluacionInternaState) => state != null ? state.evaluacionConsultar : new ListadoEvaluacionInterna();
