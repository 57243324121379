
import { EnumEstatusEncuesta } from 'src/app/shared/models/enums/enum-estatus-encuesta.model';
import { Preguntas } from 'src/app/shared/models/response/encuestas/preguntas.model';
import { SeccionesWizard } from 'src/app/shared/models/response/encuestas/wizard/secciones-wizard';
import * as fromWizarAction from '../actions/wizard-encuesta-action';


export interface WizardState {
    id: number;
    nombre: string;
    descripcion: string;
    idTipoEncuesta: number;
    idEstatusEncuesta: number;
    loaded: boolean;
    loading: boolean;
    inserta: boolean;
    edita: boolean;
    secciones: SeccionesWizard[];
    seccion: boolean,
    indexSeccion: number;
    addPregunta: boolean;
    deletePregunta : boolean;
    editoPregunta : boolean;
    editPregunta: boolean;
    error: string;
    preguntaEdit: Preguntas;
    logo: string;
    fileTerms: string;
    sendServer: boolean;
    saveServer: boolean;
    consulto: boolean,
    asigna: boolean,
    publicada : boolean,
    editoState : boolean,
    codigoEncuesta : string,
    failSave : boolean

}
export const initialState: WizardState = {
    id: 0,
    nombre: '',
    descripcion: '',
    idTipoEncuesta: 0,
    idEstatusEncuesta: 0,
    loaded: false,
    loading: false,
    inserta: false,
    edita: false,
    secciones: [],
    seccion: false,
    indexSeccion: undefined,
    addPregunta: false,
    deletePregunta: false,
    editPregunta: false,
    editoPregunta: false,
    preguntaEdit: new Preguntas(),
    error: '',
    logo: '',
    fileTerms: '',
    sendServer: false,
    saveServer: false,
    consulto: false,
    asigna: false,
    publicada: false,
    editoState: false,
    codigoEncuesta: '',
    failSave: false
};
export function reducer(state = initialState, action: fromWizarAction.WizardEncuestaAction): WizardState {
    
    switch (action.type) {
        case fromWizarAction.SECCION_ENCUESTA_ADD: {

            const payload = { ...action.payload };
            let currentIndex = state.indexSeccion == undefined ? 0 : state.indexSeccion;
            if (state.secciones.length > 0) {
                payload.nombre = `Sección ${state.secciones.length + 1}`;
                payload.index = state.secciones.length;
            } else {
                payload.nombre = `Sección 1`;
                payload.index = state.secciones.length;
            }
            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                editPregunta: false,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                secciones: [...state.secciones, payload],
                error: '',
                indexSeccion: currentIndex,
                addPregunta: false,
                deletePregunta: false,
                editoPregunta : false,
                preguntaEdit: null,
                logo: state.logo,
                fileTerms: state.fileTerms,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                publicada : false,
                editoState : payload.agregoUsuario,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false

            };

        };
        case fromWizarAction.RESET_ENCUESTA: {
            return {
                ...state,
                id: 0,
                nombre: '',
                descripcion: '',
                editPregunta: false,
                idTipoEncuesta: 0,
                loaded: true,
                loading: false,
                inserta: false,
                idEstatusEncuesta : undefined,
                edita: false,
                secciones: [],
                seccion: false,
                indexSeccion: undefined,
                error: '',
                addPregunta: false,
                deletePregunta: false,
                editoPregunta : false,
                preguntaEdit: null,
                logo: '',
                fileTerms: '',
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                publicada : false,
                editoState: false,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false
            }
        }
        case fromWizarAction.SECCION_ENCUESTA_SELECTED: {
            const payload = action.payload;
        
            let secciones = [...state.secciones];


            for (let i = 0; i < secciones.length; i++) {
                if (i == payload) {
                    let item = { ...secciones[payload] };
                    item.selected = !item.selected;
                    secciones.splice(payload, 1, item);
                } else {
                    let itemNew = { ...secciones[i] };
                    itemNew.selected = false;
                    secciones.splice(i, 1, itemNew);
                }
            }

            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                editPregunta: false,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                idTipoEncuesta: state.idTipoEncuesta,
                secciones: secciones,
                error: '',
                indexSeccion: payload,
                preguntaEdit: null,
                logo: state.logo,
                fileTerms: state.fileTerms,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                addPregunta: false,
                deletePregunta: false,
                editoPregunta : false,
                publicada : false,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false

            };

        }
        case fromWizarAction.SET_DATOS_GENERALES: {
            const payload = action.payload;
            let editoState = false;
            if(state.nombre.trim() != payload.nombre.trim()){
                editoState = true;
            } else if(editoState == false) {
                editoState = state.editoState;
            }
            if(state.descripcion.trim() != payload.descripcion.trim()){
                editoState = true;
            } else if(editoState == false) {
                editoState = state.editoState;
            }
            if(state.logo.trim() != payload.foto.trim()){
                editoState = true;
            } else if(editoState == false) {
                editoState = state.editoState;
            }

            if((typeof(state.fileTerms) == 'string')&&(state.fileTerms.trim() != payload.pdf.trim())){
                editoState = true;
            } else if(editoState == false) {
                editoState = state.editoState;
            }
           console.log(payload);
           //marca

            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: state.seccion,
                inserta: payload.id == 0 ? true : false,
                edita: payload.id > 0 ? true : false,
                id: state.id,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: payload.nombre,
                descripcion: payload.descripcion,
                idTipoEncuesta: payload.idTipoEncuesta,
                secciones: state.secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: null,
                logo: payload.foto,
                fileTerms: payload.pdf,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                deletePregunta :false,
                editoPregunta : false,
                publicada : false,
                editoState : editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false

            };

        }
        case fromWizarAction.PREGUNTA_ADD: {
            const payload = { ...action.payload };
            let secciones = JSON.parse(JSON.stringify([...state.secciones])) as SeccionesWizard[];
            let preguntas: Preguntas[] = [];
            //marca payload pregunta
            // console.log(payload);
            if (secciones[state.indexSeccion].preguntas.length > 0 ) {
                payload.idPregunta = 0;
                payload.indexPregunta = secciones[state.indexSeccion].preguntas.length;
                preguntas = [...secciones[state.indexSeccion].preguntas, payload];
            } else {
                const preguntaParse: any = {
                    id: 0,
                    indexPregunta: 0,
                    abiertas:payload.abiertas,
                    indexSeccion: state.indexSeccion,
                    descripcion: payload.descripcion,
                    idSeccion: payload.idSeccion,
                    idRespuesta: payload.idRespuesta,
                    tipoRespuesta: payload.tipoRespuesta,
                    respuestas: payload.respuestas,
                    objeto: payload.objeto,
                    opciones: payload.opciones,
                    comentarios: payload.comentarios,
                    id_seccion_enlazada: payload.id_seccion_enlazada,
                    opcion_habilita_seccion: payload.opcion_habilita_seccion
                }

                preguntas = [preguntaParse];
                //marca pregunta
                // console.log(preguntas)
            }


            secciones[state.indexSeccion].preguntas = preguntas;
            

            //console.log(secciones[state.indexSeccion].preguntas)
            
            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                idTipoEncuesta: state.idTipoEncuesta,
                secciones: secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: true,
                editPregunta: false,
                preguntaEdit: null,
                logo: state.logo,
                fileTerms: state.fileTerms,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                deletePregunta : false,
                editoPregunta : false,
                publicada : false,
                editoState : true,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false
            };
        }
        case fromWizarAction.PREGUNTA_DELETE: {
            const payload = action.payload;
            let secciones = JSON.parse(JSON.stringify([...state.secciones])) as SeccionesWizard[];

            secciones[state.indexSeccion].preguntas = secciones[state.indexSeccion].preguntas.filter(x => x.indexPregunta !== payload.indexPregunta);
             let preguntas =  JSON.parse(JSON.stringify(secciones[state.indexSeccion].preguntas)) as Preguntas[];

             for(let i = 0; i < preguntas.length; i++){
                 preguntas[i].indexPregunta = i;
             }
             secciones[state.indexSeccion].preguntas = preguntas;
           
            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                idTipoEncuesta: state.idTipoEncuesta,
                secciones: secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: null,
                logo: state.logo,
                fileTerms: state.fileTerms,
                saveServer: false,
                consulto: false,
                asigna : false,
                deletePregunta : true,
                editoPregunta : false,
                publicada : false,
                editoState : true,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false


            };

        }

        case fromWizarAction.PREGUNTA_COPIAR: {
            let payload = { ...action.payload };
            let secciones = JSON.parse(JSON.stringify([...state.secciones])) as SeccionesWizard[];
            payload.indexPregunta = secciones[state.indexSeccion].preguntas.length;
            payload.idPregunta = 0;
            
            secciones[state.indexSeccion].preguntas = [...secciones[state.indexSeccion].preguntas, payload]
            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                nombre: state.nombre,
                descripcion: state.descripcion,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                secciones: secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: true,
                editPregunta: false,
                preguntaEdit: null,
                logo: state.logo,
                fileTerms: state.fileTerms,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                deletePregunta : false,
                editoPregunta : false,
                publicada : false,
                editoState : true,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false


            };

        }
        case fromWizarAction.SELECT_EDIT: {
            let payload = action.payload;
            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                nombre: state.nombre,
                descripcion: state.descripcion,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                secciones: state.secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                editPregunta: true,
                preguntaEdit: payload,
                logo: state.logo,
                fileTerms: state.fileTerms,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                deletePregunta : false,
                editoPregunta : false,
                publicada : false,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false


            };
        }

        case fromWizarAction.CLOSE_EDIT: {
          
            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                nombre: state.nombre,
                descripcion: state.descripcion,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                secciones: state.secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: undefined,
                logo: state.logo,
                fileTerms: state.fileTerms,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                deletePregunta : false,
                editoPregunta : false,
                publicada : false,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false


            };
        }
        case fromWizarAction.EDIT_PREGUNTA: {
            let payload = JSON.parse(JSON.stringify(action.payload));
           
            let secciones = JSON.parse(JSON.stringify([...state.secciones])) as SeccionesWizard[];
            let pregunta =  secciones[state.indexSeccion].preguntas[payload.indexPregunta] as Preguntas;
           
            pregunta.descripcion = payload.descripcion;
            pregunta.tipoRespuesta = payload.tipoRespuesta;
            pregunta.respuestas = payload.respuestas;
            pregunta.opciones = payload.opciones;
            pregunta.objeto = payload.objeto;
            pregunta.abiertas = payload.abiertas;
            pregunta.comentarios = payload.comentarios;
            pregunta.id_seccion_enlazada = payload.id_seccion_enlazada;
            pregunta.opcion_habilita_seccion = payload.opcion_habilita_seccion;

            secciones[state.indexSeccion].preguntas[payload.indexPregunta] = pregunta;

            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                nombre: state.nombre,
                idEstatusEncuesta : state.idEstatusEncuesta,
                descripcion: state.descripcion,
                idTipoEncuesta: state.idTipoEncuesta,
                secciones: secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: payload,
                logo: state.logo,
                fileTerms: state.fileTerms,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                deletePregunta :false,
                editoPregunta : true,
                publicada : false,
                editoState : true,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false


            };


        }
        case fromWizarAction.CREAR_ENCUESTA: {

            return {
                ...state,
                loading: false,
                loaded: false,
                seccion: true,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                nombre: state.nombre,
                descripcion: state.descripcion,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                secciones: state.secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: undefined,
                logo: state.logo,
                fileTerms: state.fileTerms,
                sendServer: true,
                saveServer: false,
                consulto: false,
                asigna : false,
                deletePregunta : false,
                editoPregunta : false,
                publicada : false,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false

            }
        }
        case fromWizarAction.REGISTRA_ENCUESTA_SERVER: {

            return {
                ...state,
                loading: true,
                loaded: false,
                seccion: true,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                nombre: state.nombre,
                descripcion: state.descripcion,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                secciones: state.secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: undefined,
                logo: state.logo,
                fileTerms: state.fileTerms,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                deletePregunta : false,
                editoPregunta : false,
                publicada : false,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false

            };
        }
        case fromWizarAction.REGISTRA_ENCUESTA_SERVER_SUCCESS: {
            let payload = action.payload;
            
            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                inserta: false,
                edita: true,
                id: payload.id,
                nombre: payload.nombre,
                idEstatusEncuesta : payload.idEstatus ,
                descripcion: payload.descripcion,
                idTipoEncuesta: payload.idTipoEncuesta,
                secciones: payload.secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: undefined,
                logo: payload.logo,
                fileTerms: payload.fileTerms,
                sendServer: false,
                saveServer: true,
                consulto: false,
                asigna : false,
                deletePregunta : false,
                editoPregunta : false,
                publicada : false,
                editoState : false,
                codigoEncuesta : payload.codigo,
                failSave : false
            };
        }

        case fromWizarAction.REGISTRA_ENCUESTA_SERVER_FAIL: {
            let payload = action.payload;
            return {
                ...state,
                loading: false,
                loaded: false,
                seccion: true,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                nombre: state.nombre,
                idEstatusEncuesta : state.idEstatusEncuesta,
                descripcion: state.descripcion,
                idTipoEncuesta: state.idTipoEncuesta,
                secciones: state.secciones,
                error: payload,
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: undefined,
                logo: state.logo,
                fileTerms: state.fileTerms,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                deletePregunta : false,
                editoPregunta : false,
                publicada : false,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : true

            };

        }
        case fromWizarAction.GET_ENCUESTA_WIZARD: {
            return {
                ...state,
                loading: true,
                loaded: false,
                id: 0,
                nombre: '',
                descripcion: '',
                seccion: false,
                inserta: false,
                edita: false,
                idTipoEncuesta: 0,
                secciones: [],
                error: '',
                indexSeccion: 0,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: undefined,
                logo: '',
                fileTerms: '',
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                deletePregunta : false,
                editoPregunta : false,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false



            }
        }
        case fromWizarAction.GET_ENCUESTA_WIZARD_SUCCESS: {
            let payload = action.payload;
            return {
                ...state,
                loading: false,
                loaded: true,
                id: payload.id,
                idEstatusEncuesta : payload.idEstatus,
                nombre: payload.nombre,
                descripcion: payload.descripcion,
                seccion: true,
                inserta: false,
                edita: false,
                idTipoEncuesta: payload.idTipoEncuesta,
            
                secciones: payload.secciones,
                error: '',
                indexSeccion: 0,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: undefined,
                logo: payload.logo,
                fileTerms: payload.fileTerms,
                sendServer: false,
                saveServer: false,
                consulto: true,
                asigna : false,
                deletePregunta : false,
                editoPregunta : false,
                publicada : false,
                editoState : state.editoState,
                codigoEncuesta : payload.codigo,
                failSave : false



            }
        }
        case fromWizarAction.GET_ENCUESTA_WIZARD_FAIL: {
            let payload = action.payload;
            return {
                ...state,
                error: payload,
                loading: false,
                loaded: false,
            }
        }
        case fromWizarAction.SET_TIPO_ENCUESTA:{
            let payload = action.payload;
            return {
                ...state,
                idTipoEncuesta : payload,
                idEstatusEncuesta : EnumEstatusEncuesta.Borrador,
                loading:false,
                loaded: true,
                id: state.id,
                nombre: state.nombre,
                descripcion: state.descripcion,
                seccion : state.seccion,
                inserta : state.inserta,
                edita: state.edita,
                secciones:state.secciones,
                error:'',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: undefined,
                logo: state.logo,
                fileTerms: state.fileTerms,
                sendServer: false,
                saveServer: false,
                consulto: state.consulto,
                asigna : true,
                deletePregunta : false,
                editoPregunta : false,
                publicada : false,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false


            }
        }
        case fromWizarAction.PUBLICA_ENCUESTA_WIZARD_LOAD : {
            return {
                ...state
            }
        }
        case fromWizarAction.PUBLICA_ENCUESTA_WIZARD_SUCCESS : {
            return {
                ...state,
                idTipoEncuesta : state.idTipoEncuesta,
                loading:false,
                loaded: true,
                id: state.id,
                idEstatusEncuesta : EnumEstatusEncuesta.Publicada,
                nombre: state.nombre,
                descripcion: state.descripcion,
                seccion : state.seccion,
                inserta : state.inserta,
                edita: state.edita,
                secciones:state.secciones,
                error:'',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: undefined,
                logo: state.logo,
                fileTerms: state.fileTerms,
                sendServer: false,
                saveServer: false,
                consulto: state.consulto,
                asigna : false,
                deletePregunta : false,
                editoPregunta : false,
                publicada : true,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false



            }
        }
        case fromWizarAction.SET_SEND_SERVER : {
            let payload = action.payload;
            return {
                ...state,
                idTipoEncuesta : state.idTipoEncuesta,
                loading:false,
                loaded: false,
                id: state.id,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                seccion : state.seccion,
                inserta : state.inserta,
                edita: state.edita,
                secciones:state.secciones,
                error:'',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: undefined,
                logo: state.logo,
                fileTerms: state.fileTerms,
                sendServer: payload,
                saveServer: false,
                consulto: state.consulto,
                asigna : false,
                deletePregunta : false,
                editoPregunta : false,
                publicada : true,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false



            }
        }
        case fromWizarAction.SET_CONSULTO_SERVER : {
            return {
                ...state,
                idTipoEncuesta : state.idTipoEncuesta,
                loading:false,
                loaded: false,
                id: state.id,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                seccion : state.seccion,
                inserta : state.inserta,
                edita: state.edita,
                secciones:state.secciones,
                error:'',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                editPregunta: false,
                preguntaEdit: undefined,
                logo: state.logo,
                fileTerms: state.fileTerms,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : state.asigna,
                deletePregunta : state.deletePregunta,
                editoPregunta : state.editPregunta,
                publicada : state.publicada,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false



            }
        }
        case fromWizarAction.SELECT_SECCION_CONFIGURACION : {
            
            let payload = JSON.parse(JSON.stringify(action.payload)) as SeccionesWizard;
            let secciones = JSON.parse(JSON.stringify(state.secciones)) as SeccionesWizard[];
           

            for(var i = 0; i < secciones.length; i++){
                if(secciones[i].index == payload.index) {
                    secciones[i].selected = true;
                    secciones[i].selectConfiguration = true;
                } else {
                    secciones[i].selected = false;
                    secciones[i].selectConfiguration = false;
                }
            }
            
            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                editPregunta: false,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                secciones: secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                deletePregunta: false,
                editoPregunta : false,
                preguntaEdit: null,
                logo: state.logo,
                fileTerms: state.fileTerms,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                publicada : false,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false

            };
        }
        case fromWizarAction.CLOSE_SECCION_CONFIGURACION : {
            let payload = JSON.parse(JSON.stringify(action.payload)) as SeccionesWizard;
            let secciones = JSON.parse(JSON.stringify(state.secciones)) as SeccionesWizard[];
            secciones[payload.index].selectConfiguration = false;

            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                editPregunta: false,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                secciones: secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                deletePregunta: false,
                editoPregunta : false,
                preguntaEdit: null,
                logo: state.logo,
                fileTerms: state.fileTerms,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                publicada : false,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false

            };
        }
        case fromWizarAction.CLOSE_ALL_SECCION_CONFIGURACION : {
            
            let secciones = JSON.parse(JSON.stringify(state.secciones)) as SeccionesWizard[];
            for(var i = 0; i < secciones.length;i++){
                secciones[i].selectConfiguration = false;
            }
            

            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                editPregunta: false,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                secciones: secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                deletePregunta: false,
                editoPregunta : false,
                preguntaEdit: null,
                logo: state.logo,
                fileTerms: state.fileTerms,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                publicada : false,
                editoState : state.editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false

            };
        }
        case fromWizarAction.CHANGE_NOMBRE_SECCION : {
            let payload = action.payload;
            
            let secciones = JSON.parse(JSON.stringify(state.secciones)) as SeccionesWizard[];
            let nombreActual =  secciones[payload.indexSeccion].nombre;
            let nuevoNombre =  payload.nombre;
            let seccion = secciones[payload.indexSeccion];
           
           
            let editoState : boolean = false;
            if(nuevoNombre.trim() !=  nombreActual.trim()) {
                editoState = true;
            }
            if(nuevoNombre.trim().length > 0){
                seccion.nombre = payload.nombre;
                seccion.valid = true;
                seccion.selectConfiguration = false;
            } else {
                seccion.valid = false;
                seccion.selectConfiguration = true;
            }
            

            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                editPregunta: false,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                secciones: secciones,
                error: '',
                indexSeccion: state.indexSeccion,
                addPregunta: false,
                deletePregunta: false,
                editoPregunta : false,
                preguntaEdit: null,
                logo: state.logo,
                fileTerms: state.fileTerms,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                publicada : false,
                editoState : editoState,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false

            };
        }
        case fromWizarAction.ELIMINAR_SECCION : {
            let payload = action.payload;
            let indexSeccion = 0;
            let secciones = JSON.parse(JSON.stringify(state.secciones)) as SeccionesWizard[];
            secciones = secciones.filter(x=> x.index != payload);
           if(secciones.length > 0){
               secciones[indexSeccion].selected = true;
           }

            

            return {
                ...state,
                loading: false,
                loaded: true,
                seccion: true,
                editPregunta: false,
                inserta: state.id == 0 ? true : false,
                edita: state.id > 0 ? true : false,
                id: state.id,
                idTipoEncuesta: state.idTipoEncuesta,
                idEstatusEncuesta : state.idEstatusEncuesta,
                nombre: state.nombre,
                descripcion: state.descripcion,
                secciones: secciones,
                error: '',
                indexSeccion: indexSeccion,
                addPregunta: false,
                deletePregunta: false,
                editoPregunta : false,
                preguntaEdit: null,
                logo: state.logo,
                fileTerms: state.fileTerms,
                sendServer: false,
                saveServer: false,
                consulto: false,
                asigna : false,
                publicada : false,
                editoState : true,
                codigoEncuesta : state.codigoEncuesta,
                failSave : false

            };
        }
        default:
            return {
                ...state
            }
            break;
    }
}

export const getState = (state: WizardState) => state;
export const getEditoStateWizard = (state: WizardState) => state.editoState;
export const getEditoFailSave = (state: WizardState) => state.failSave;