import { Action } from "@ngrx/store";
import { ListadoSucursalesConfiguracionResponse } from "src/app/shared/models/response/sucursales/listado-sucursales-configuracion.model";
import { SucursalesConfiguradas } from "src/app/shared/models/response/sucursales/sucursales-configuradas.model";



export const GET_SUCURSALES_CONFIGURACION_LOAD = '[Configuracion Sucursales] Load';
export const GET_SUCURSALES_CONFIGURACION_LOAD_SUCCESS = '[Configuracion Sucursales] Load Success';
export const GET_SUCURSALES_CONFIGURACION_LOAD_FAIL = '[SConfiguracion Sucursales] Load Fail';

export class ConfiguracionSucursalesLoad implements Action {
    readonly type = GET_SUCURSALES_CONFIGURACION_LOAD;
    constructor(public payload: any) { }
}

export class ConfiguracionSucursalesLoadSuccess implements Action {
    readonly type = GET_SUCURSALES_CONFIGURACION_LOAD_SUCCESS;
    constructor(public payload: ListadoSucursalesConfiguracionResponse) { }
}

export class ConfiguracionSucursalesSucursalesLoadFail implements Action {
    readonly type = GET_SUCURSALES_CONFIGURACION_LOAD_FAIL;
    constructor(public payload: any) { }
}

export const SELECT_SUCURSAL = '[Configuracion Sucursales] Select';

export class SelectSucursal implements Action {
    readonly type = SELECT_SUCURSAL;
    constructor(public payload: SucursalesConfiguradas) { }
}





export const ASIGNA_SUCURSAL_LOAD = '[Asigna Sucursales] Load';
export const ASIGNA_SUCURSAL_LOAD_SUCCESS = '[Asigna Sucursales] Load Success';
export const ASIGNA_SUCURSAL_LOAD_FAIL = '[ Asigna Sucursales] Load Fail';

export class AsignaSucursalLoad implements Action {
    readonly type = ASIGNA_SUCURSAL_LOAD;
    constructor(public payload: SucursalesConfiguradas[]) { }
}

export class AsignaSucursalLoadSuccess implements Action {
    readonly type = ASIGNA_SUCURSAL_LOAD_SUCCESS;
    constructor(public payload: ListadoSucursalesConfiguracionResponse) { }
}

export class AsignaSucursalLoadFail implements Action {
    readonly type = ASIGNA_SUCURSAL_LOAD_FAIL;
    constructor(public payload: any) { }
}


export type ConfiguracionSucursalesAction = 
ConfiguracionSucursalesLoad |
ConfiguracionSucursalesLoadSuccess |
ConfiguracionSucursalesSucursalesLoadFail |
SelectSucursal |
AsignaSucursalLoad | 
AsignaSucursalLoadSuccess |
AsignaSucursalLoadFail ;