export class Preguntas {

    idPregunta: number;
    indexPregunta : number;
    indexSeccion : number;
    descripcion: string;
    idSeccion: number;
    idRespuesta: number;
    tipoRespuesta: number;
    respuestas: string;
    opciones: object[] = [];
    objeto: any;
    abiertas : string [] = [];
    comentarios: string;
    id_seccion_enlazada: number;
    opcion_habilita_seccion: string;
}


