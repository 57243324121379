import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, Data } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit {
  pageInfo: Data = Object.create(null);
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter(route => route.outlet === 'primary'))
      .pipe(mergeMap(route => route.data))
      .subscribe(event => {
        this.titleService.setTitle(event['title']);
        let newEvent = this.changeUrl(event);
      
        this.pageInfo = newEvent;
        
      });
    }
  ngOnInit() { }
  changeUrl(data : any) {

   
    if(localStorage.currentCodeQuizz){
      let code = localStorage.currentCodeQuizz.toString();
      for(var i = 0 ; i < data.urls.length; i++){
       
        if(data.urls[i].url) {
          data.urls[i].url = data.urls[i].url.replace("{code}", code);
        }
        
      }
    }
   
    
    return data;
  }
}
