
import * as fromUserReducer from './user.reducer';
import * as fromEncuestaReducer from './encuestas-internas.reducer';
import * as fromProfileReducer from './profile-user.reducer';
import * as fromDepartamentoReducer from './depatamentos.reducer';
import * as fromDetalleEncuestaInternaReducer from './detalle-encuesta.reducer';

import * as fromUsuariosReducer from './user-listado-admin.reducer';
import * as fromUserAppReducer from './user-app-reducer';
import * as fromUserEditAppReducer from './user-edit-app.reducer';
import * as fromSeccionesReducer from './secciones.reducer';
import * as fromPreguntasReducer from './preguntas.reducer';
import * as fromAddPreguntaReducer from './registro-pregunta.reducer';
import * as fromPreguntaSelectedReducer from './pregunta-select.reducer';
import * as fromPreguntaDeletedReducer from './pregunta-delete.reducer';
import * as fromPreguntaCopyReducer from './copy-pregunta.reducet';
import * as fromUsuarioGrupoReducer from './usuario-grupo.reducer';
import * as fromQuizzReducer from './quizz.reducer';
import * as fromGruposReducer from './grupos.reducer';
import * as fromAddMiembrosReducer from './addMiembros.reducer';
import * as fromNotificacionReducer from './notificacion.reducer';
import * as fromNotificacionGrupoDetalleReducer from './grupos-notificacion-detalle-reducer';
import * as fromConfiguracionReducer from './configuracion.reducer';
import * as fromDashBoardReducer from './dashboard.reducer';
import * as fromDashBoardEvalReducer from './dashboardEval.reducer';
import * as fromAnalisisReducer from './resultados.reducer';
import * as fromWizard from './wizard.reducer';
import * as fromOptionSelect from './option-select.reducer';
import * as fromSucursales from './sucursales.reducer';
import * as fromSucursalesConfiguradas from './sucursales-configuradas.reducer';
import * as fromTipoSecc from './evaluaciones/tipo-seccion-eval.reducer';
import * as fromPerfilModal from './modal-perfil-reducer';
import * as fromCompartirGraficosReducer from './compartir-graficos.reducer';
import * as fromCollapse from './collapse-compartir.reducer';

//Evaluaciones
import * as fromAddEvaluacionReducer from './evaluaciones/registra-evaluacion.reducer';
import * as fromWizardEVReducer from './evaluaciones/wizard-ev.reducer';
import * as fromEvaluacionReducer from '././evaluaciones/evaluaciones-internas.reducer';
import * as fromTipoSeccionReducer from './evaluaciones/tipo-seccion-eval.reducer'
import * as fromMiembrosCuentaReducer from '././evaluaciones/miembros-cuenta.reducer';
import * as fromUserEvaluadoReducer from './evaluaciones/levantamiento-evaluacion.reducer'
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PreguntasEvaluaciones } from '../../shared/models/response/evaluaciones/responderEvaluacion/preguntasEvaluaciones';




export interface AppState {
    user: fromUserReducer.LoginState ;
    encuestaInterna: fromEncuestaReducer.EncuestaInternaState;
    profile: fromProfileReducer.PerfilState;
    departamento: fromDepartamentoReducer.DepartamentoState;
    collapseCompartir : fromCollapse.OpcionCollpaseCompartirState,
    getEncuesta: fromDetalleEncuestaInternaReducer.DetalleEncuestaInternaState;
    usuarios: fromUsuariosReducer.ListadoUsuariosState;
    usuarioApp: fromUserAppReducer.UserAppState;
    usuarioEdit: fromUserEditAppReducer.UsuarioEditState;
    secciones: fromSeccionesReducer.SeccionesState;
    preguntas: fromPreguntasReducer.PreguntasState;
    addPregunta: fromAddPreguntaReducer.AddPreguntaState;
    selectPregunta: fromPreguntaSelectedReducer.PreguntaSelectState;
    deletedPregunta: fromPreguntaDeletedReducer.DeletedPreguntatState;
    copyPregunta: fromPreguntaCopyReducer.CopyPreguntatState;
    usuariosGrupos: fromUsuarioGrupoReducer.UsuarioGrupoState;
    quizz: fromQuizzReducer.QuizzState;
    grupos: fromGruposReducer.GruposState;
    miembros: fromAddMiembrosReducer.DetalleGrupoState;
    notificacion: fromNotificacionReducer.NotificacionState;
    notificacionDetalle: fromNotificacionGrupoDetalleReducer.NotificacionDetalleState;
    configuracion: fromConfiguracionReducer.ConfiguracionState;
    dashBoard: fromDashBoardReducer.DashBoardState,
    dashBoardEval: fromDashBoardEvalReducer.DashBoardEvalState,
    resultados : fromAnalisisReducer.ResultadosState,
    wizard: fromWizard.WizardState,
    optionSelect : fromOptionSelect.OptionSelectState,
    sucursales : fromSucursales.SucursalesState,
    sucursalesConfiguradas : fromSucursalesConfiguradas.SucursalesConfiguradasState,
    modalPerfil : fromPerfilModal.ModalPefilState,
    compartirGraficos : fromCompartirGraficosReducer.CompartirGraficosState,
    //Evaluaciones
    addEvaluacion : fromAddEvaluacionReducer.AltaEvaluacionState,
    wizardEV : fromWizardEVReducer.WizardEVState
    evaluacionConsultar: fromEvaluacionReducer.EvaluacionInternaState;
    TipoSeccion:fromTipoSeccionReducer.TipoSeccionInternaState;
    miembrosCuenta:fromMiembrosCuentaReducer.MiembrosInternaState;
    evaluacion: fromUserEvaluadoReducer.EvaluacionState;
}
export const reducers = {
    user : fromUserReducer.reducer,
    encuestaInterna : fromEncuestaReducer.reducer,
    profile : fromProfileReducer.reducer,
    departamento: fromDepartamentoReducer.reducer,
    collapseCompartir : fromCollapse.reducer,
    getEncuesta : fromDetalleEncuestaInternaReducer.reducer,
    usuarios : fromUsuariosReducer.reducer,
    usuarioApp : fromUserAppReducer.reducer,
    usuarioEdit: fromUserEditAppReducer.reducer,
    secciones : fromSeccionesReducer.reducer,
    preguntas : fromPreguntasReducer.reducer,
    addPregunta: fromAddPreguntaReducer.reducer,
    selectPregunta : fromPreguntaSelectedReducer.reducer,
    deletedPregunta : fromPreguntaDeletedReducer.reducer,
    copyPregunta: fromPreguntaCopyReducer.reducer,
    usuariosGrupos : fromUsuarioGrupoReducer.reducer,
    quizz: fromQuizzReducer.reducer,
    grupos: fromGruposReducer.reducer,
    miembros : fromAddMiembrosReducer.reducer,
    notificacion : fromNotificacionReducer.reducer,
    notificacionDetalle: fromNotificacionGrupoDetalleReducer.reducer,
    configuracion : fromConfiguracionReducer.reducer,
    dashBoard : fromDashBoardReducer.reducer,
    dashBoardEval : fromDashBoardEvalReducer.reducer,
    resultados : fromAnalisisReducer.reducer,
    wizard: fromWizard.reducer,
    optionSelect : fromOptionSelect.reducer,
    sucursales : fromSucursales.reducer,
    sucursalesConfiguradas : fromSucursalesConfiguradas.reducer,
    modalPerfil : fromPerfilModal.reducer,
    compartirGraficos : fromCompartirGraficosReducer.reducer,
    //Evaluaciones
    addEvaluacion : fromAddEvaluacionReducer.reducer,
    wizardEV : fromWizardEVReducer.reducer,
    evaluacionConsultar : fromEvaluacionReducer.reducer,
    TipoSeccion : fromTipoSeccionReducer.reducer,
    miembrosCuenta:fromAddMiembrosReducer.reducer,
    evaluacion: fromUserEvaluadoReducer.reducer

};

export const getWizardState = createFeatureSelector<fromWizard.WizardState>('wizard');
export const getEditoWizard = createSelector(getWizardState, fromWizard.getEditoStateWizard);
export const getFailSaveEncuesta = createSelector(getWizardState, fromWizard.getEditoFailSave);
export const getQuizz = createFeatureSelector<fromQuizzReducer.QuizzState>('quizz');

export const getWizardEVState = createFeatureSelector<fromWizardEVReducer.WizardEVState>('wizardEV');
export const getEditoWizardEV = createSelector(getWizardEVState, fromWizardEVReducer.getEditoStateWizardEV);
export const getFailSaveEvaluacion = createSelector(getWizardEVState, fromWizardEVReducer.getEditoFailSaveEV);

export const getEvaluacionState = createFeatureSelector<fromUserEvaluadoReducer.EvaluacionState>('evaluacion');
// export const getQuizz = createFeatureSelector<fromQuizzReducer.QuizzState>('quizz');

export const getResultadosState = createFeatureSelector<fromAnalisisReducer.ResultadosState>('resultados');
export const getResultadosSelect = createSelector(getResultadosState, fromAnalisisReducer.getRespuestaSelect);

export const getOptionSelectState = createFeatureSelector<fromOptionSelect.OptionSelectState>('optionSelect');

export const getSucursalesSate = createFeatureSelector<fromSucursales.SucursalesState>('sucursales');

export const getTipoSeccion = createFeatureSelector<fromTipoSecc.TipoSeccionInternaState>('tipoSeccion');

export const getGruposUsuairosApp = createFeatureSelector<fromMiembrosCuentaReducer.MiembrosInternaState>('miembrosCuenta');

export const getUsuariosGrupoState = createFeatureSelector<fromUsuarioGrupoReducer.UsuarioGrupoState>('usuariosGrupos');
export const getUsuariosGrupos = () => createSelector(getUsuariosGrupoState, (usuarios) => {
    if (usuarios) {
        return usuarios.ListadoGrupo.usuariosEncuesta;
    } else {
        return undefined;
    }
});



export const getGruposState = createFeatureSelector<fromGruposReducer.GruposState>('grupos');

export const getGrupoById = (id: number) => createSelector(getGruposState, (grupos) => {
    if (grupos) {
        const grupoFound = grupos.listado.grupos.find(grupo => grupo.id === id);

        return grupoFound || undefined;
    } else {
        return undefined;
    }
});

export const getRespuestasUsuario= (pregunta: PreguntasEvaluaciones) => createSelector(getEvaluacionState, (evaluacionState) => {
    if (evaluacionState) {
        let respuestasUsuario : string [] = [];
        let indexSeccion = evaluacionState.evaluacion.secciones.findIndex(x=> x.id == pregunta.idSeccion);
        let indexPregunta = 0;
        if(pregunta.idMiembro != null && pregunta.idMiembro != undefined && pregunta.idMiembro > 0){
            indexPregunta = evaluacionState.evaluacion.secciones[indexSeccion].preguntasMiembros.findIndex(x=> x.idMiembro == pregunta.idMiembro && x.id == pregunta.id);
            respuestasUsuario = evaluacionState.evaluacion.secciones[indexSeccion].preguntasMiembros[indexPregunta].respuestasUsuario;
        } else {
            indexPregunta = evaluacionState.evaluacion.secciones[indexSeccion].preguntas.findIndex(x=> x.id == pregunta.id);
            respuestasUsuario = evaluacionState.evaluacion.secciones[indexSeccion].preguntas[indexPregunta].respuestasUsuario;
        }
        

        return respuestasUsuario || undefined;
    } else {
        return undefined;
    }
});

    
export const getEvaluacionUserState= () => createSelector(getEvaluacionState, (evaluacionState) => {
    if (evaluacionState) {
        return evaluacionState.evaluacion || undefined;
    } else {
        return undefined
    }
});

export const getPreguntasMiembro= (idSeccion : number, idMiembro : number) => createSelector(getEvaluacionState, (evaluacionState) => {
    if (evaluacionState) {
        
        let indexSeccion  = evaluacionState.evaluacion.secciones.findIndex(x=> x.id == idSeccion);
        let preguntas =  evaluacionState.evaluacion.secciones[indexSeccion].preguntasMiembros.filter(x=> x.idMiembro == idMiembro)
        //let preguntas = [...evaluacionState.evaluacion.secciones[indexSeccion].preguntasMiembros[indexPregunta]];
    

        return preguntas || undefined;
    } else {
        return undefined;
    }
});


export const getProfileState = createFeatureSelector<fromProfileReducer.PerfilState>('profile');
export const getProfile = createSelector(getProfileState, fromProfileReducer.reducer);