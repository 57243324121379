

import { EnumTipoRespuestas } from 'src/app/shared/models/enums/enum-tipo-respuestas.model';
import { PreguntasQuizz } from 'src/app/shared/models/response/encuestas/preguntas-quizz';
import { QuizzResponse } from 'src/app/shared/models/response/encuestas/quizz-response';
import * as fromQuizzAction from '../actions/responder-encuesta.action';



export interface QuizzState {
    loaded: boolean;
    loanding: boolean;
    error: string;
    quizz: QuizzResponse;
    editState: boolean;
    sendSever: boolean;
    nombreUsuario : string;
    inicioEncuesta : boolean;
    terminoEncuesta : boolean;
    reinicia : boolean,
    succesEncuesta : boolean
}

export const initialState: QuizzState = {
    loaded: false,
    loanding: false,
    error: '',
    quizz: new QuizzResponse(),
    editState: false,
    sendSever: false,
    nombreUsuario: '',
    inicioEncuesta : false,
    terminoEncuesta: false,
    reinicia: false,
    succesEncuesta : false


};
export function reducer(state = initialState, action: fromQuizzAction.QuizzAction): QuizzState {
    switch (action.type) {
        case fromQuizzAction.GET_ENCUESTA_LOAD: {
            return {
                ...state,
                loanding: true,
                loaded: false,
                error: '',
                editState: false,
                sendSever: false,
                nombreUsuario : '',
                inicioEncuesta : false,
                terminoEncuesta : false,
                reinicia : false,
                succesEncuesta : false
            };
        }
        case fromQuizzAction.GET_ENCUESTA_SUCCESS: {
            const response = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                error: '',
                quizz: response,
                editState: false,
                sendSever: false,
                nombreUsuario : state.nombreUsuario,
                inicioEncuesta : false,
                terminoEncuesta : false,
                reinicia : false,
                succesEncuesta : false
            };

        }
        case fromQuizzAction.GET_ENCUESTA_FAIL: {
            const error = action.payload;
            return {
                ...state,
                loaded: false,
                loanding: false,
                quizz: state.quizz,
                editState: false,
                error: error,
                sendSever: false,
                nombreUsuario : state.nombreUsuario,
                inicioEncuesta : false,
                terminoEncuesta : false,
                reinicia : false,
                succesEncuesta : false
            };
        }
        case fromQuizzAction.RESPONDER_ENCUESTA_LOAD: {
            const payload = action.payload;
            return {
                ...state,
                loanding: true,
                loaded: false,
                error: '',
                quizz: state.quizz,
                editState: false,
                sendSever: false,
                nombreUsuario : state.nombreUsuario,
                inicioEncuesta : state.inicioEncuesta,
                terminoEncuesta : false,
                reinicia : false,
                succesEncuesta : false
            };
        }
        case fromQuizzAction.RESPONDER_ENCUESTA_SUCCESS: {
            const payload = action.payload;
            return {
                ...state,
                loanding: false,
                loaded: true,
                error: '',
                quizz: payload,
                editState: false,
                sendSever: false,
                nombreUsuario : state.nombreUsuario,
                inicioEncuesta : state.inicioEncuesta,
                terminoEncuesta : false,
                reinicia : false,
                succesEncuesta : true

            };
        }
        case fromQuizzAction.RESPONDER_ENCUESTA_FAIL: {
            const payload = action.payload;
            return {
                ...state,
                error: payload,
                editState: false,
                sendSever: false,
                quizz: state.quizz,
                nombreUsuario : state.nombreUsuario,
                inicioEncuesta : state.inicioEncuesta,
                terminoEncuesta : state.terminoEncuesta,
                reinicia : false,
                succesEncuesta : false
            };
        }
        
        case fromQuizzAction.RESPONDE_PREGUNTA: {
            const payload = action.payload;
            let quizz = JSON.parse(JSON.stringify(state.quizz)) as QuizzResponse;
            const seccion = quizz.secciones.find(x => x.id === payload.idSeccion);
            const pregunta = seccion.preguntas.find(x => x.idPregunta === payload.idPregunta);
                        
            let loaded : boolean = false;
           // Si el tipo de pregunta es diferente de multiple y de abiertas
            if (payload.idTipoPregunta != EnumTipoRespuestas.OPCION_MULTIPLE && payload.idTipoPregunta != EnumTipoRespuestas.ABIERTAS && payload.idTipoPregunta != EnumTipoRespuestas.DATOS_GENERALES) {
                pregunta.respondida = true;
                pregunta.respuesta = payload.respuesta;
                pregunta.showValidacion = false;
            
                /* if(pregunta.abierta){ // Validaciones para preguntas SIMPLES con opción abierta
                    if(pregunta.opcionAbierta == pregunta.respuesta){
                        pregunta.showAbierta = true;
                        pregunta.respondida = false;
                        loaded = true;
                    } else {
                        pregunta.showAbierta = false;
                        pregunta.respondida = true;
                        loaded = true;
                    }
                    if(payload.respuesta != payload.opcionAbierta){
                        pregunta.comentarioAbierta =""
                    }
                } */

            } else if(payload.idTipoPregunta == EnumTipoRespuestas.OPCION_MULTIPLE) {
              
                pregunta.respondida = true;
                pregunta.respuesta = payload.respuesta;
                if (payload.seleccionado) {
                  pregunta.opcionesRespuestas.push(payload.respuesta);
                //   if(payload.opcionAbierta == payload.respuesta){
                //     pregunta.opcionesRespuestas = [];
                //     pregunta.opcionesRespuestas = [payload.respuesta];
                //     pregunta.showAbierta = true;
                //     loaded = true;
                //     pregunta.respondida = false;

                //   }
                } else {
                  const index = pregunta.opcionesRespuestas.findIndex(x => x === payload.respuesta);
                  pregunta.opcionesRespuestas.splice(index, 1);
                //   if(payload.opcionAbierta == payload.respuesta){
                //     pregunta.showAbierta = false;
                //     loaded = true;
                //     pregunta.respondida = false;
                //   }
                }
                if (pregunta.opcionesRespuestas.length === 0) {
                  pregunta.respondida = false;
                 
                  pregunta.showAbierta = false;
                } else {
                  pregunta.showValidacion = false;
                }
                if(pregunta.abierta){
                    if(payload.respuesta != payload.opcionAbierta){
                        pregunta.comentarioAbierta =""
                    }
                }
                
            } else if(payload.idTipoPregunta == EnumTipoRespuestas.ABIERTAS || payload.idTipoPregunta == EnumTipoRespuestas.DATOS_GENERALES) {
                payload.respuesta = payload.respuesta.trim();
                if (payload.respuesta.length === 0) {
                    pregunta.respondida = false;
                    pregunta.respuesta = '';
                    pregunta.showValidacion = true;
                } else {
                    pregunta.respondida = true;
                    pregunta.respuesta = payload.respuesta;
                    pregunta.showValidacion = false;
                }
            }
           
            return {
                ...state,
                loaded: loaded,
                loanding: false,
                quizz: quizz,
                editState: true,
                sendSever: false,
                nombreUsuario : state.nombreUsuario,
                inicioEncuesta : state.inicioEncuesta,
                terminoEncuesta : false,
                reinicia : false,
                succesEncuesta : false

            }
        }
        case fromQuizzAction.INICIAR_ENCUESTA : {
            return {
                ...state,
                error: '',
                editState: false,
                sendSever: false,
                quizz: state.quizz,
                nombreUsuario : state.nombreUsuario,
                inicioEncuesta : true,
                terminoEncuesta : false,
                reinicia : false,
                succesEncuesta : false
            };

        }
        case fromQuizzAction.TERMINO_ENCUESTA : {
            return {
                ...state,
                error: '',
                editState : false,
                sendSever : false,
                quizz : state.quizz,
                nombreUsuario : '',
                inicioEncuesta :false,
                terminoEncuesta : true,
                reinicia : false,
                succesEncuesta : false
            }
        }
        case fromQuizzAction.SELECCIONA_SUCURSAL : {
            const payload = action.payload;
            let quizz = JSON.parse(JSON.stringify(state.quizz)) as QuizzResponse;
            quizz.idSucursal = payload;
            return {
                ...state,
                error: '',
                loanding : state.loanding,
                loaded : state.loaded,
                editState : false,
                sendSever : false,
                quizz : quizz,
                nombreUsuario : state.nombreUsuario,
                inicioEncuesta :false,
                terminoEncuesta : false,
                reinicia : false,
                succesEncuesta : false
            }
        }
        
        
        case fromQuizzAction.REINICIA_ENCUESTA : {
            let quizz = JSON.parse(JSON.stringify(state.quizz)) as QuizzResponse;
            let preguntas = quizz.secciones[0].preguntas as PreguntasQuizz[];
            for(var i = 0; i < preguntas.length; i++){
                preguntas[i].respondida = false;
                preguntas[i].respuesta = '';
            }
            quizz.secciones[0].preguntas = preguntas;
            return {
                ...state,
                error: '',
                loanding : false,
                loaded : true,
                editState : false,
                sendSever : false,
                quizz : quizz,
                nombreUsuario : '',
                inicioEncuesta :false,
                terminoEncuesta : false,
                reinicia : true,
                succesEncuesta : false
            }
        }
        case fromQuizzAction.COMENTARIO_ABIERTO :{
            const payload = action.payload;
            let quizz = JSON.parse(JSON.stringify(state.quizz)) as QuizzResponse;
            const seccion = quizz.secciones.find(x => x.id === payload.idSeccion);
            
            const pregunta = seccion.preguntas.find(x => x.idPregunta === payload.idPregunta);

            
            if(payload.comentarioAbierta.length > 0){
                pregunta.respondida = true;
                pregunta.comentarioAbierta = payload.comentarioAbierta;
            } else {
                pregunta.respondida = false;
                pregunta.comentarioAbierta = "";
            }
            

            return {
                ...state,
                loaded: false,
                loanding: false,
                quizz: quizz,
                editState: true,
                sendSever: false,
                nombreUsuario : state.nombreUsuario,
                inicioEncuesta : state.inicioEncuesta,
                terminoEncuesta : false,
                reinicia : false

            }

        }
        case fromQuizzAction.ACTUALIZAR_SECCIONES : {
            /* let quizz = JSON.parse(JSON.stringify(state.quizz)) as QuizzResponse;
            const payload = action.payload;
            quizz.secciones = payload;
            return {
                ...state,
                quizz : quizz
            } */
            let quizz = JSON.parse(JSON.stringify(state.quizz)) as QuizzResponse;
            let returnSecciones = [];

            const payload = action.payload;
            quizz.secciones.forEach( ( sect, ix ) => {
                if( payload.find( x => x.indexSeccion == sect.indexSeccion) !== undefined ){
                    returnSecciones.push( sect );
                }
            } );
            payload.forEach( ( sect, ix ) => {
                if(quizz.secciones.find( x => x.indexSeccion == sect.indexSeccion ) === undefined){
                    returnSecciones.push( sect );
                }
            } );
            quizz.secciones = returnSecciones;
            return {
                ...state,
                quizz : quizz
            };
        }
        default:
            return {
                ...state
            }
            break;



    }
}

export const getQuizz = (state: QuizzState) => state.quizz;

