import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EncuestaInternaService } from 'src/app/services/encuestas/encuesta-interna.service';
import { MensajesAppService } from 'src/app/services/mensajes-app.service';
import * as fromQuizzResponderAction from '../actions/responder-encuesta.action';

@Injectable()
export class QuizzEffects {
    constructor(private actions$: Actions,
        private encuestasService: EncuestaInternaService,
        private messageServices: MensajesAppService) {

    }
    @Effect()
    quizzLoad$: Observable<Action> = this.actions$.pipe(
        ofType(fromQuizzResponderAction.GET_ENCUESTA_LOAD),
        map((action: fromQuizzResponderAction.GetEncuestaLoad) => action.payload),
        switchMap( (payload)  => this.encuestasService.getQuizz(payload).pipe(
            map(response => {
                return new fromQuizzResponderAction.GetEncuestaSuccess(response);
            }, catchError(error => of(new fromQuizzResponderAction.GetEncuestaFail(error))))
        ))
    );
    @Effect()
    responderEncuesta$: Observable<Action> = this.actions$.pipe(
        ofType(fromQuizzResponderAction.RESPONDER_ENCUESTA_LOAD),
        map((action: fromQuizzResponderAction.ResponderEncuestaLoad) => action.payload),
        switchMap( (payload)  => this.encuestasService.responderQuizz(payload).pipe(
            map(response => {
                return new fromQuizzResponderAction.ResponderEncuestaSuccess(response);
            }, catchError(error => of(new fromQuizzResponderAction.ResponderEncuestaFail(error))))
        ))
    );
}
