import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromGruposAction from '../actions/grupos-actions';
import { GruposService } from '../../services/grupos/grupos.service';
import { MensajesAppService } from 'src/app/services/mensajes-app.service';
import { TipoRespuesta } from 'src/app/shared/models/enums/enumTipoRespuesta.model';


@Injectable()
export class GruposEffects {
    constructor(private actions$: Actions,
        private servicesGrupos: GruposService,
        private servicesMessage: MensajesAppService
    ) {

    }
    @Effect()
    grupoLoadEffect$: Observable<Action> = this.actions$.pipe(
        ofType(fromGruposAction.GRUPO_LOAD),
        map((action: fromGruposAction.GrupoLoad) => action.payload),
        switchMap((payload) => this.servicesGrupos.getGruposByUsuarios(payload).pipe(
            map(response => {
                return new fromGruposAction.GrupoLoadSucess(response);
            }, catchError(error => of(new fromGruposAction.GrupoLoadFail(error))))
        ))
    );


    @Effect()
    grupoAltaGrupo$: Observable<Action> = this.actions$.pipe(
        ofType(fromGruposAction.ALTA_GRUPOS),
        map((action: fromGruposAction.AltaGrupo) => action.payload),
        switchMap((payload) => this.servicesGrupos.altaGrupo(payload).pipe(
            map(response => {
                this.servicesMessage.messageBox( response.tipoRespuesta, response.mensaje);
                if (response.tipoRespuesta === TipoRespuesta.Exito) {
                  return new fromGruposAction.AltaGrupoSuccess(response);
                } else {
                    return new fromGruposAction.AltaGrupoFail(response);
                }
            }, catchError(error => of(new fromGruposAction.AltaGrupoFail(error))))
        ))
    );
}
