import { Action } from '@ngrx/store';
import { CompartirEncuestaRequest } from 'src/app/shared/models/request/compartir-graficos/CompartirGraficosRequest.model';
import { InvitacionesResponse } from 'src/app/shared/models/response/compartir-graficos/InvitacionesResponse.model';
import { ResultadosCompartidosResponse } from 'src/app/shared/models/response/compartir-graficos/ResultadosCompartidosResponse.model';
import { UsuarioCompartirResponse } from 'src/app/shared/models/response/compartir-graficos/usuario-compartir-response.model';




export const BUSCAR_USUARIO_COMPARTIR_LOAD = '[Compartir] Buscar Usuario Load';
export class BuscarUsuarioCompartirLoad implements Action {
    readonly type = BUSCAR_USUARIO_COMPARTIR_LOAD;
    constructor(public payload: string) {
    }
}

export const BUSCAR_USUARIO_COMPARTIR_LOAD_SUCCESS = '[Compartir] Buscar Usuario Success';
export class BuscarUsuarioCompartirLoadSuccess implements Action {
    readonly type = BUSCAR_USUARIO_COMPARTIR_LOAD_SUCCESS;
    constructor(public payload: UsuarioCompartirResponse) {
    }
}


export const BUSCAR_USUARIO_COMPARTIR_LOAD_ERROR = '[Compartir] Buscar Usuario Error';
export class BuscarUsuarioCompartirLoadError implements Action {
    readonly type = BUSCAR_USUARIO_COMPARTIR_LOAD_ERROR;
    constructor(public payload: any) {
    }
}


export const ENVIAR_INVITACION_GRAFICOS = '[Compartir] Enviar Invitacion Load';
export class EnviarInvitacionLoad implements Action {
    readonly type = ENVIAR_INVITACION_GRAFICOS;
    constructor(public payload: CompartirEncuestaRequest) {
    }
}

export const ENVIAR_INVITACION_GRAFICOS_SUCCESS = '[Compartir] Enviar Invitacion Success';
export class EnviarInvitacionSuccess implements Action {
    readonly type = ENVIAR_INVITACION_GRAFICOS_SUCCESS;
    constructor(public payload: ResultadosCompartidosResponse) {
    }
}

export const ENVIAR_INVITACION_GRAFICOS_FAIL = '[Compartir] Enviar Invitacion Fail';
export class EnviarInvitacionFail implements Action {
    readonly type = ENVIAR_INVITACION_GRAFICOS_FAIL;
    constructor(public payload: any) {
    }
}

export const GET_INVITADO_RESULTADOS = '[Compartir] Get Invitados Resultados';
export class GetInvitadosResultados implements Action {
    readonly type = GET_INVITADO_RESULTADOS;
    constructor(public payload: number) {
    }
}

export const GET_INVITADO_RESULTADOS_SUCCESS = '[Compartir] Get Invitados Resultados Success';
export class GetInvitadosResultadosSuccess implements Action {
    readonly type = GET_INVITADO_RESULTADOS_SUCCESS;
    constructor(public payload: ResultadosCompartidosResponse) {
    }
}

export const GET_INVITADO_RESULTADOS_FAIL = '[Compartir] Get Invitados Resultados Fail';
export class GetInvitadosResultadosFail implements Action {
    readonly type = GET_INVITADO_RESULTADOS_FAIL;
    constructor(public payload: any) {
    }
}


export const DELETE_LINK_COMPARTIR = '[Compartir] Delete Link';
export class DeleteLink implements Action {
    readonly type = DELETE_LINK_COMPARTIR;
    constructor(public payload: number) {
    }
}

export const DELETE_LINK_COMPARTIR_SUCCESS = '[Compartir] Delete Success';
export class DeleteLinkSuccess implements Action {
    readonly type = DELETE_LINK_COMPARTIR_SUCCESS;
    constructor(public payload: ResultadosCompartidosResponse) {
    }
}

export const DELETE_LINK_COMPARTIR_FAIL = '[Compartir] Delete Fail';
export class DeleteLinkFail implements Action {
    readonly type = DELETE_LINK_COMPARTIR_FAIL;
    constructor(public payload: any) {
    }
}

export const INVITACIONES_LOAD = '[Compartir] Invitaciones Load';
export class InvitacionesLoad implements Action {
    readonly type = INVITACIONES_LOAD;
    constructor(public payload: number) {
    }
}

export const INVITACIONES_LOAD_SUCCESS = '[Compartir] Invitaciones Load  Success';
export class InvitacionesLoadSuccess implements Action {
    readonly type = INVITACIONES_LOAD_SUCCESS;
    constructor(public payload: InvitacionesResponse) {
    }
}

export const INVITACIONES_LOAD_ERROR = '[Compartir] Invitaciones Load  Error';
export class InvitacionesLoadError implements Action {
    readonly type = INVITACIONES_LOAD_ERROR;
    constructor(public payload: any) {
    }
}


export const PAGINA_INVITACIONES = '[Compartir] Pagina Invitaciones';
export class PaginaInvitaciones implements Action {
    readonly type = PAGINA_INVITACIONES;
    constructor(public payload: number) {
    }
}




export type CompartirGraficoAction =
BuscarUsuarioCompartirLoad | 
BuscarUsuarioCompartirLoadSuccess |
BuscarUsuarioCompartirLoadError |
EnviarInvitacionLoad  |
EnviarInvitacionSuccess |
EnviarInvitacionFail |
GetInvitadosResultados |
GetInvitadosResultadosSuccess |
GetInvitadosResultadosFail |
DeleteLink |
DeleteLinkSuccess |
DeleteLinkFail |
InvitacionesLoad |
InvitacionesLoadSuccess |
InvitacionesLoadError  |
PaginaInvitaciones ;