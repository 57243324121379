import { Action } from '@ngrx/store';
import { AsignarGrupoRequest } from 'src/app/shared/models/request/encuesta/asignar-grupo-request.model';
import { ConfiguracionBasicaRequest } from 'src/app/shared/models/request/encuesta/configuracion-basica.model';
import { ConfiguracionResponse } from 'src/app/shared/models/response/encuestas/configuracion-response';
import { EncuestaGruposResponse } from 'src/app/shared/models/response/encuestas/encuesta-grupos-response.model';
import { EncuestaGrupos } from 'src/app/shared/models/response/encuestas/encuesta-grupos.model';
import { ResponseBase } from 'src/app/shared/models/response/response-base.model';


export const OPTION_SELECTED = '[Option Selected Configuracion] Load';
export class OptionSelectedConfiguracion implements Action {
    readonly type = OPTION_SELECTED;
    constructor(public payload: number) {
    }
}


export const GET_CONFIGURACION_BASICA_LOAD = '[Configuracion Basica] Load';
export const GET_CONFIGURACION_BASICA_LOAD_SUCCESS = '[Configuracion Basica] Success';
export const GET_CONFIGURACION_BASICA_LOAD_FAIL = '[Configuracion Basica] Fail';


export class ConfiguracionBasicaLoad implements Action {
    readonly type = GET_CONFIGURACION_BASICA_LOAD;
    constructor(public payload: string) {
    }
}

export class ConfiguracionBasicaSuccess implements Action{
    readonly type = GET_CONFIGURACION_BASICA_LOAD_SUCCESS;
    constructor(public payload: ConfiguracionResponse) {
    }
}

export class ConfiguracionBasicaFail implements Action {
    readonly type = GET_CONFIGURACION_BASICA_LOAD_FAIL;
    constructor(public payload:  any) {
    }
}



export const GET_CONFIGURACION_GRUPOS = '[Configuracion Grupos] Load';
export const GET_CONFIGURACION_GRUPOS_SUCCESS = '[Configuracion Grupos] Success';
export const GET_CONFIGURACION_GRUPOS_FAIL = '[Configuracion Grupos] Fail';

export class ConfiguracionGrupos implements Action {
    readonly type = GET_CONFIGURACION_GRUPOS;
    constructor(public payload: any) {
    }
}
export class ConfiguracionGruposSuccess implements Action {
    readonly type = GET_CONFIGURACION_GRUPOS_SUCCESS;
    constructor(public payload: EncuestaGruposResponse) {
    }
}

export class ConfiguracionGruposFail implements Action {
    readonly type = GET_CONFIGURACION_GRUPOS_FAIL;
    constructor(public payload: any) {
    }
}

export const SELECCIONA_GRUPO = '[Grupo] Selecciona';

export class SeleccionaGrupo implements Action {
    readonly type = SELECCIONA_GRUPO;
    constructor(public payload: EncuestaGrupos) {
    }
}
export const SEND_CONFIGURACION_GRUPOS = '[Send Configuracion Grupos] Load';
export const SEND_CONFIGURACION_GRUPOS_SUCCESS = '[Send Configuracion Grupos] Success';
export const SEND_CONFIGURACION_GRUPOS_FAIL = '[Send Configuracion Grupos] Fail';



export class SendGrupoEncuesta implements Action {
    readonly type = SEND_CONFIGURACION_GRUPOS;
    constructor(public payload: AsignarGrupoRequest) {
    }
}
export class SendGrupoEncuestaSuccess implements Action {
    readonly type = SEND_CONFIGURACION_GRUPOS_SUCCESS;
    constructor(public payload: ResponseBase) {
    }
}
export class SendGrupoEncuestaFail implements Action {
    readonly type = SEND_CONFIGURACION_GRUPOS_FAIL;
    constructor(public payload: any) {
    }
}


export const SEND_CONFIGURACION_BASICA = '[Send Configuracion Basica] Load';
export const SEND_CONFIGURACION_BASICA_SUCCESS = '[Send Configuracion Basica] Success';
export const SEND_CONFIGURACION_BASICA_FAIL = '[Send Configuracion Basica] Fail';

export class SendConfiguracionBasica implements Action {
    readonly type = SEND_CONFIGURACION_BASICA;
    constructor(public payload: ConfiguracionBasicaRequest) {
    }
}

export class SendConfiguracionBasicaSuccess implements Action {
    readonly type = SEND_CONFIGURACION_BASICA_SUCCESS;
    constructor(public payload: ConfiguracionResponse) {
    }
}

export class SendConfiguracionBasicaFail implements Action {
    readonly type = SEND_CONFIGURACION_BASICA_FAIL;
    constructor(public payload: any) {
    }
}

export type ConfiguracionAction =
OptionSelectedConfiguracion |
ConfiguracionBasicaLoad |
ConfiguracionBasicaSuccess |
ConfiguracionBasicaFail |
ConfiguracionGrupos |
ConfiguracionGruposSuccess |
ConfiguracionGruposFail |
SeleccionaGrupo |
SendGrupoEncuesta |
SendGrupoEncuesta |
SendGrupoEncuestaSuccess |
SendGrupoEncuestaFail |
SendConfiguracionBasica |
SendConfiguracionBasicaSuccess | 
SendConfiguracionBasicaFail ;