import { Action } from '@ngrx/store';
import { Departamento } from 'src/app/shared/models/response/departamentos/departamento.model';


export const DEPARTAMENTO_LOAD = '[Departamento] Load';
export const DEPARTAMENTO_SUCCESS = '[Departamento] Success';
export const DEPARTAMENTO_FAIL = '[Departamento] Fail';


export class DepartamentoLoad implements Action {
    readonly type = DEPARTAMENTO_LOAD;
    constructor() {
    }
}

export class DepartamentoSuccess implements Action{
    readonly type = DEPARTAMENTO_SUCCESS;
    constructor(public payload: Departamento[]) {
    }
}

export class DepartamentoFail implements Action {
    readonly type = DEPARTAMENTO_FAIL;
    constructor(public payload:  any) {
    }
}

export type DepartamentoAction = DepartamentoLoad | DepartamentoSuccess | DepartamentoFail;