import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { MensajesAppService } from 'src/app/services/mensajes-app.service';
import * as fromWizardEVAction from '../../actions/evaluaciones/wizard-evaluaciones-action';
import { EvaluacionesService } from 'src/app/services/evaluaciones/evaluaciones.service';
import { ToastrService } from 'ngx-toastr';
import { TipoRespuesta } from 'src/app/shared/models/enums/enumTipoRespuesta.model';



@Injectable()
export class WizardEVEffects {
    constructor(private actions$: Actions,
                private evaluacionServices: EvaluacionesService,
                private messageServices: MensajesAppService,
                private toastr: ToastrService) {
        }
        @Effect()
        registraEvaluacion$: Observable<Action> = this.actions$.pipe(
            ofType(fromWizardEVAction.REGISTRA_EVALUACION_SERVER),
            map((action: fromWizardEVAction.RegistraEvaluacionServer) => action.payload),
            switchMap( (payload)  => this.evaluacionServices.sendEvaluacion(payload).pipe(
                map(response => {              
                   
                    if(response.tipoRespuesta == TipoRespuesta.Exito){
                        
                      
                        this.toastr.success(response.mensaje);
                        return new fromWizardEVAction.RegistraEvaluacionServerSuccess(response);
                    } else {
                        
                        this.toastr.warning(response.mensaje);
                        return new fromWizardEVAction.RegistraEvaluacionServerFail(response);
                    }
                }, catchError(error => of(new fromWizardEVAction.RegistraEvaluacionServerFail(error))))
            ))
        );
        @Effect()
        wizardEVDetalle$: Observable<Action> = this.actions$.pipe(
            ofType(fromWizardEVAction.GET_EVALUACION_WIZARD),
            map((action: fromWizardEVAction.GetEvaluacionWizard) => action.payload),
            switchMap( (payload)  => this.evaluacionServices.getEvaluacionWizard(payload).pipe(
                map(response => {
                    
                    if(response.tipoRespuesta == TipoRespuesta.Exito){
                        return new fromWizardEVAction.GetEvaluacionWizardSuccess(response);
                    } else {
                        //this.messageServices.messageBox(response.tipoRespuesta, response.mensaje);
                        return new fromWizardEVAction.GetEvaluacionWizardFail(response);
                    }
                }, catchError(error => of(new fromWizardEVAction.GetEvaluacionWizardFail(error))))
            ))
        );
        @Effect()
        wizardEVPublicar$: Observable<Action> = this.actions$.pipe(
            ofType(fromWizardEVAction.PUBLICA_EVALUACION_WIZARD_LOAD),
            map((action: fromWizardEVAction.PublicaEvaluacionLoad) => action.payload),
            switchMap( (payload)  => this.evaluacionServices.publicaEvaluacion(payload).pipe(
                map(response => {
                    if(response.tipoRespuesta == TipoRespuesta.Exito){
                        return new fromWizardEVAction.PublicaEvaluacionSuccess(response);
                    } else {
                        return new fromWizardEVAction.PublicaEvaluacionFail(response);
                    }
                }, catchError(error => of(new fromWizardEVAction.PublicaEvaluacionFail(error))))
            ))
        );

        @Effect()
        wizardEVResponsables$: Observable<Action> = this.actions$.pipe(
            ofType(fromWizardEVAction.RESPONSABLES_LOAD),
            map((action: fromWizardEVAction.ResponsablesLoad) => action.payload),
            switchMap( (payload)  =>  this.evaluacionServices.getResponsables(payload).pipe(
                map(response => {                 
                    if(response.tipoRespuesta == TipoRespuesta.Exito){
                        
                       // this.toastr.success(response.mensaje);
                        return new fromWizardEVAction.ResponsablesLoadSuccess(response.usuariosEncuesta);
                    } else {
                       this.messageServices.messageBox(response.tipoRespuesta, response.mensaje);
                        this.toastr.warning(response.mensaje);
                        return new fromWizardEVAction.ResponsablesLoadFail(response);
                    }
                }, catchError(error => of(new fromWizardEVAction.ResponsablesLoadFail(error))))
            ))
        );
        @Effect()
        wizardEVEncuestaSimple$: Observable<Action> = this.actions$.pipe(
            ofType(fromWizardEVAction.ENCUESTAS_SIMPLE_LOAD),
            map((action: fromWizardEVAction.EncuestaSimpleLoad) => action.payload),
            switchMap( (payload)  =>  this.evaluacionServices.getEncuestaSimple(payload).pipe(
                map(response => {                 
                    
                    if(response.tipoRespuesta == TipoRespuesta.Exito){
                        return new fromWizardEVAction.EncuestaSimpleLoadSuccess(response.encuestas);
                    } else {
                       this.messageServices.messageBox(response.tipoRespuesta, response.mensaje);
                        this.toastr.warning(response.mensaje);
                        return new fromWizardEVAction.EncuestaSimpleLoadFail(response);
                    }
                }, catchError(error => of(new fromWizardEVAction.EncuestaSimpleLoadFail(error))))
            ))
        );
       
}