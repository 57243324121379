import { Action } from '@ngrx/store';
import { PerfilUsuario } from 'src/app/shared/models/response/perfil/perfil-usuario.model';

export const PROFILE_USER = '[Profile] user';
export const PROFILE_SUCCESS = '[Profile] user success';
export const PROFILE_FAIL = '[Profile user fail]';


export class ProfileUser implements Action {
    readonly type = PROFILE_USER;
    constructor(public payload: number) {
    }
}

export class ProfileUserSuccess implements Action{
    readonly type = PROFILE_SUCCESS;
    constructor(public payload: PerfilUsuario) {
    }
}

export class ProfileUserFail implements Action {
    readonly type = PROFILE_FAIL;
    constructor(public payload:  string) {
    }
}

export type UserPerfilAction =
                          ProfileUser |
                          ProfileUserSuccess |
                          ProfileUserFail ;