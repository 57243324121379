import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  baseUrl: string;
  headers = new BehaviorSubject(null);
  constructor() {
    this.baseUrl = environment.baseUrl;
   if(localStorage.idUsuario > 0){
      this.setLocal();
    } 
   
  }
  setLocal() {
    const headers = { 'Content-Type': 'application/json' };
    const token = this.token;
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    this.headers.next(headers);
  }

  get token() {
    return localStorage.sdk;
  }
  get rol(): number {
    return Number(localStorage.rol);
  }
}
