import { Injectable } from '@angular/core';
import swal from 'sweetalert';
import { TipoRespuesta } from '../shared/models/enums/enumTipoRespuesta.model';



@Injectable({
  providedIn: 'root'
})
export class MensajesAppService {

  constructor() { }
  messageBox(TipoRespuestaResponse: number, mensaje: string) {
    switch (TipoRespuestaResponse) {
      case TipoRespuesta.Exito:
        swal('¡Buen trabajo!', mensaje , 'success');
      break;
      case TipoRespuesta.Advertencia:
        swal('¡Advertencia!', mensaje , 'warning');
      break;
      case TipoRespuesta.Error:
        swal('¡Opps!', mensaje , 'error');
      break;
    }

  }
}
