import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { SidebarNavbar } from 'src/app/shared/models/sidebar-navbar';


@Injectable({
  providedIn: 'root'
})
export class NavbarSidebarService {

  constructor() {
  }
  getSeleccionUser(model: SidebarNavbar): Observable<SidebarNavbar> {
    if (model == null) {
     const sideBarNavBar = new SidebarNavbar();
     sideBarNavBar.idEncuesta = 0;
     sideBarNavBar.codigo = '';
     sideBarNavBar.eligioEncuesta = false;
     return of( sideBarNavBar);
    } else {
      return of(model);
    }
  }
}
