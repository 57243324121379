import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/config/config.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/observable';
import {  catchError } from 'rxjs/operators';
import { ErrorHandlerService } from '../error-handler.service';
import { NewUser } from 'src/app/shared/models/response/usuario/newUser.model';
import { LoginRequest } from 'src/app/shared/models/request/usuario/loginRequest.model';
import { UsuarioResponse } from 'src/app/shared/models/response/usuario/loginResponse.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  headers: any;
  usuario: NewUser;
  constructor(private http: HttpClient,
    private config: ConfigService,
    private servicesError: ErrorHandlerService
  ) {
    this.config.headers.subscribe((headers) => {
      if (headers) {
        this.headers = headers;
      }
    });
  }

  login(model: LoginRequest): Observable<UsuarioResponse> {
    const headersLogin = { 'Content-Type': 'application/json' };
    return this.http.post<UsuarioResponse>(`${environment.baseUrl}00/02`, model, { headers: headersLogin })
      .pipe(catchError(this.servicesError.erroHandler));

  }
  loginOld(model: LoginRequest): Observable<UsuarioResponse> {
    const headersLogin = { 'Content-Type': 'application/json' };
    return this.http.post<UsuarioResponse>(`${environment.baseUrlOld}00/02`, model, { headers: headersLogin })
      .pipe(catchError(this.servicesError.erroHandler));

  }
  lofOff(): Observable<any> {
    return this.http.get(`${environment.baseUrl}00/03`, { headers: this.headers })
      .pipe(catchError(this.servicesError.erroHandler));
  }
}
