import { switchMap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as fromRegistroEvaluacion from '../../actions/evaluaciones/registra-evaluacion-action';
import { MensajesAppService } from 'src/app/services/mensajes-app.service';
import { TipoRespuesta } from 'src/app/shared/models/enums/enumTipoRespuesta.model';
import { EvaluacionesService } from 'src/app/services/evaluaciones/evaluaciones.service';

@Injectable()
export class RegistroEvaluacionEffects{
    constructor(private actions$: Actions,
        private evaluacionesServices: EvaluacionesService,
        private servicesMessage: MensajesAppService) {
        }
    @Effect()
    addEvaluacion$ = this.actions$.pipe(ofType(fromRegistroEvaluacion.EVALUACION_ADD),
        map((action: fromRegistroEvaluacion.EvaluacionAdd) => action.payload),
        switchMap((payload) => this.evaluacionesServices.addEvaluacion(payload)
            .pipe(
                map(response => {
                    if (response.tipoRespuesta === TipoRespuesta.Exito) {
                        return new fromRegistroEvaluacion.EvaluacionAddSuccess(response);
                    } else {
                        this.servicesMessage.messageBox(response.tipoRespuesta, response.mensaje);
                        return new fromRegistroEvaluacion.EvaluacionAddFail(response);
                    }
                },
                catchError(error => {  return of(new fromRegistroEvaluacion.EvaluacionAddFail(error)); }  )
                )
            )
        )
    );
}
