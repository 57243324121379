import { Action } from "@ngrx/store";
import { SucursalRequest } from "src/app/shared/models/request/sucursal/sucursal-request.model";
import { EliminaSucursalResponse } from "src/app/shared/models/response/sucursales/elimina-sucursal-response.model";
import { ListadoSucursalResponse } from "src/app/shared/models/response/sucursales/listado-sucursal-response";
import { SucursalResponse } from "src/app/shared/models/response/sucursales/sucursal-response";



export const GET_SUCURSALES_LOAD = '[Sucursales] Load';
export const GET_SUCURSALES_LOAD_SUCCESS = '[Sucursales] Load Success';
export const GET_SUCURSALES_LOAD_FAIL = '[Sucursales] Load Fail';

export class SucursalesLoad implements Action {
    readonly type = GET_SUCURSALES_LOAD;
    constructor(public payload: number) { }
}

export class SucursalesLoadSuccess implements Action {
    readonly type = GET_SUCURSALES_LOAD_SUCCESS;
    constructor(public payload: ListadoSucursalResponse) { }
}

export class SucursalesLoadFail implements Action {
    readonly type = GET_SUCURSALES_LOAD_FAIL;
    constructor(public payload: any) { }
}

export const ADD_SUCURSAL= '[Sucursales] Add';
export const ADD_SUCURSAL_SUCCESS = '[Sucursales] Add success ';
export const ADD_SUCURSAL_FAIL = '[Sucursales] Add Fail';

export class SucursalesAdd implements Action {
    readonly type = ADD_SUCURSAL;
    constructor(public payload: SucursalRequest) { }
}

export class SucursalesAddSuccess implements Action {
    readonly type = ADD_SUCURSAL_SUCCESS;
    constructor(public payload: SucursalResponse) { }
}

export class SucursalesAddFail implements Action {
    readonly type = ADD_SUCURSAL_FAIL;
    constructor(public payload: any) { }
}




export const DELETE_SUCURSAL= '[Sucursales] Delete';
export const DELETE_SUCURSAL_SUCCESS = '[Sucursales] Delete success ';
export const DELETE_SUCURSAL_FAIL = '[Sucursales] Delete Fail';

export class SucursalDelete implements Action {
    readonly type = DELETE_SUCURSAL;
    constructor(public payload: number) { }
}

export class SucursalDeleteSuccess implements Action {
    readonly type = DELETE_SUCURSAL_SUCCESS;
    constructor(public payload: EliminaSucursalResponse) { }
}

export class SucursalDeleteFail implements Action {
    readonly type = DELETE_SUCURSAL_FAIL;
    constructor(public payload: any) { }
}





export const EDIT_SUCURSAL= '[Sucursal] Edit';
export const EDIT_SUCURSAL_SUCCESS = '[Sucursal] Edit success ';
export const EDIT_SUCURSAL_FAIL = '[Sucursal] Edit Fail';

export class SucursalEdit implements Action {
    readonly type = EDIT_SUCURSAL;
    constructor(public payload: SucursalRequest) { }
}

export class SucursalEditSuccess implements Action {
    readonly type = EDIT_SUCURSAL_SUCCESS;
    constructor(public payload: SucursalResponse) { }
}

export class SucursalEditFail implements Action {
    readonly type = EDIT_SUCURSAL_FAIL;
    constructor(public payload: any) { }
}


export type SucursalesAction = 
SucursalesLoad |
SucursalesLoadSuccess |
SucursalesLoadFail |
SucursalesAdd |
SucursalesAddSuccess  |
SucursalesAddFail |
SucursalDelete |
SucursalDeleteSuccess |
SucursalDeleteFail |
SucursalEdit |
SucursalEditSuccess |
SucursalEditFail ;
