import { switchMap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import * as fromProfileUserAction from '../actions/profile-user.action';
import { PerfilUserService } from 'src/app/services/perfil/perfil-user.service';

@Injectable()



export class PerfilUserEffects {
    constructor( private actions$: Actions,
                 private perfilServices: PerfilUserService) {
    }
    @Effect()
    perfilUser$: Observable<Action> = this.actions$.pipe(
        ofType(fromProfileUserAction.PROFILE_USER),
        map((action: fromProfileUserAction.ProfileUser) => action.payload),
        switchMap( (payload)  => this.perfilServices.getProfile(payload).pipe(
            map(response => {
                return new fromProfileUserAction.ProfileUserSuccess(response);
            }, catchError(error =>{  return of(new fromProfileUserAction.ProfileUserFail(error))}))
        ))
    );
}
