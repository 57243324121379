import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/config/config.service';

import { Observable } from 'rxjs/observable';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from '../error-handler.service';
import { PerfilUsuario } from 'src/app/shared/models/response/perfil/perfil-usuario.model';

@Injectable({
  providedIn: 'root'
})
export class PerfilUserService {
  headers: any;

  constructor(private config: ConfigService,
              private servicesError: ErrorHandlerService,
              private http: HttpClient ) {
                this.config.headers.subscribe((headers) => {
                  if (headers) {
                    this.headers = headers;
                  }
                });
              }
              getProfile(idUsuario: number): Observable<PerfilUsuario> {
                return this.http.get<PerfilUsuario>(`${environment.baseUrl}01/01?id=${idUsuario}`, { headers: this.headers })
                .pipe(catchError(this.servicesError.erroHandler));
              }
}
