import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DashBoardEvalServices } from 'src/app/services/dashboard/dashboardEval.services';
import { MensajesAppService } from 'src/app/services/mensajes-app.service';
import * as fromDashBoardEval from '../actions/dashboard-eval-genera.action';


@Injectable()
export class DashBoardEvalEffects {
    constructor(private actions$: Actions,
                private dashBoardEvalServices: DashBoardEvalServices,
                private messageServices: MensajesAppService) {
        }
        @Effect()
        dashBoardEvalGeneral$: Observable<Action> = this.actions$.pipe(
            ofType(fromDashBoardEval.DASHBOARD_EVAL_GENERAL_LOAD),
            switchMap(() => this.dashBoardEvalServices.getDashBoardEvalGeneral(localStorage.idUsuario)
            .pipe(map(response => {
                return new fromDashBoardEval.DashBoardEvalSuccess(response);
            }), catchError(error => of(new fromDashBoardEval.DashBoardEvalFail(error))))
        ));
}