import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { of } from "rxjs";
import { Observable } from "rxjs/observable";
import { catchError } from "rxjs/operators";
import { map, switchMap } from "rxjs/operators";

import { CompartirGraficoServices } from "src/app/services/compartir-grafico/compartir-grafico.services";
import { MensajesAppService } from "src/app/services/mensajes-app.service";
import { TipoRespuesta } from "src/app/shared/models/enums/enumTipoRespuesta.model";
import * as fromCompartirGrafico from '../actions/compartir-graficos.action';


@Injectable()
export class CompartirGraficosEffects {
    constructor(private actions$: Actions,
        private compartirGrafico: CompartirGraficoServices,
        private messageServices: MensajesAppService,
        private toastr: ToastrService) {
    }
  

    @Effect()
    compartirGraficoEncuesta$: Observable<Action> = this.actions$.pipe(
        ofType(fromCompartirGrafico.BUSCAR_USUARIO_COMPARTIR_LOAD),
        map((action: fromCompartirGrafico.BuscarUsuarioCompartirLoad) => action.payload),
        switchMap( (payload)  => this.compartirGrafico.getUsuarioCompartir(payload).pipe(
            map(response => {
                if (response.tipoRespuesta == TipoRespuesta.Exito) {
                    return new fromCompartirGrafico.BuscarUsuarioCompartirLoadSuccess(response);
                } else {
                    this.toastr.warning(response.mensaje);
                    return new fromCompartirGrafico.BuscarUsuarioCompartirLoadError(response);
                }
            }, catchError(error => of(new fromCompartirGrafico.BuscarUsuarioCompartirLoadError(error))))
        ))
    );

    @Effect()
    invitacionGraficoEncuesta$: Observable<Action> = this.actions$.pipe(
        ofType(fromCompartirGrafico.ENVIAR_INVITACION_GRAFICOS),
        map((action: fromCompartirGrafico.EnviarInvitacionLoad) => action.payload),
        switchMap( (payload)  => this.compartirGrafico.enviaInitacionResultadosCompartir(payload).pipe(
            map(response => {
                
                if (response.tipoRespuesta == TipoRespuesta.Exito) {
                    this.toastr.success(response.mensaje);
                    return new fromCompartirGrafico.EnviarInvitacionSuccess(response);
                } else {
                    this.toastr.warning(response.mensaje);
                    return new fromCompartirGrafico.EnviarInvitacionFail(response);
                }
            }, catchError(error => of(new fromCompartirGrafico.EnviarInvitacionFail(error))))
        ))
    );

    @Effect()
    getLinks$: Observable<Action> = this.actions$.pipe(
        ofType(fromCompartirGrafico.GET_INVITADO_RESULTADOS),
        map((action: fromCompartirGrafico.GetInvitadosResultados) => action.payload),
        switchMap( (payload)  => this.compartirGrafico.getLinksCompartir(payload).pipe(
            map(response => {
                
                if (response.tipoRespuesta == TipoRespuesta.Exito) {
                 
                    return new fromCompartirGrafico.GetInvitadosResultadosSuccess(response);
                } else {
                    this.toastr.warning(response.mensaje);
                    return new fromCompartirGrafico.GetInvitadosResultadosFail(response);
                }
            }, catchError(error => of(new fromCompartirGrafico.GetInvitadosResultadosFail(error))))
        ))
    );
    @Effect()
    getEliminaLink$: Observable<Action> = this.actions$.pipe(
        ofType(fromCompartirGrafico.DELETE_LINK_COMPARTIR),
        map((action: fromCompartirGrafico.DeleteLink) => action.payload),
        switchMap( (payload)  => this.compartirGrafico.deleteLinksCompartir(payload).pipe(
            map(response => {
                
                if (response.tipoRespuesta == TipoRespuesta.Exito) {
                    this.toastr.success(response.mensaje);
                    return new fromCompartirGrafico.DeleteLinkSuccess(response);
                } else {
                    this.toastr.warning(response.mensaje);
                    return new fromCompartirGrafico.DeleteLinkFail(response);
                }
            }, catchError(error => of(new fromCompartirGrafico.DeleteLinkFail(error))))
        ))
    );
    @Effect()
    invitaciones$: Observable<Action> = this.actions$.pipe(
        ofType(fromCompartirGrafico.INVITACIONES_LOAD),
        map((action: fromCompartirGrafico.InvitacionesLoad) => action.payload),
        switchMap( (payload)  => this.compartirGrafico.getInvitaciones(payload).pipe(
            map(response => {
                
                if (response.tipoRespuesta == TipoRespuesta.Exito) {
                   
                    return new fromCompartirGrafico.InvitacionesLoadSuccess(response);
                } else {
                    this.toastr.warning(response.mensaje);
                    return new fromCompartirGrafico.InvitacionesLoadError(response);
                }
            }, catchError(error => of(new fromCompartirGrafico.InvitacionesLoadError(error))))
        ))
      
    );

}