import { Action } from '@ngrx/store';

export const SELECTED_GRUPO = '[Selected Grupo]';

export class SelectedGrupo implements Action {
    readonly type = SELECTED_GRUPO;
    constructor(public payload: any) { }
}




export type NotificacionesAction =
SelectedGrupo;
