import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { GruposService } from '../../services/grupos/grupos.service';
import { MensajesAppService } from 'src/app/services/mensajes-app.service';

import { Observable } from 'rxjs';
import * as fromDetalleGruposAction from '../actions/grupo-detalle-action';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { of } from 'rxjs/internal/observable/of';
import { TipoRespuesta } from 'src/app/shared/models/enums/enumTipoRespuesta.model';


@Injectable()
export class GruposDetalleEffects {
    constructor(private actions$: Actions,
        private servicesGrupos: GruposService,
        private servicesMessage: MensajesAppService) {

        }
        @Effect()
        grupoAsignaEffect$: Observable<Action> = this.actions$.pipe(
            ofType(fromDetalleGruposAction.ASIGNA_GRUPOS_LOAD),
            map((action: fromDetalleGruposAction.AsignaGrupoLoad) => action.payload),
            switchMap((payload) => this.servicesGrupos.asignaGrupos(payload).pipe(
                map(response => {
                    this.servicesMessage.messageBox( response.tipoRespuesta, response.mensaje);
                    if (response.tipoRespuesta === TipoRespuesta.Exito) {
                      return new fromDetalleGruposAction.AsignaGrupoSuccess(response);
                    } else {
                        return new fromDetalleGruposAction.AsignaGrupoFail(response);
                    }
                }, catchError(error => of(new fromDetalleGruposAction.AsignaGrupoFail(error))))
            ))
        );
     @Effect()
        miembrosGrupo$: Observable<Action> = this.actions$.pipe(
            ofType(fromDetalleGruposAction.GET_MIEMBROS_GRUPOS),
            map((action: fromDetalleGruposAction.GetMiembros) => action.payload),
            switchMap((payload) => this.servicesGrupos.getMiembrosByGrupo(payload).pipe(
                map(response => {
                      return new fromDetalleGruposAction.GetMiembrosSucess(response);
                }, catchError(error => of(new fromDetalleGruposAction.GetMiembrosFail(error))))
            ))
        );
        @Effect()
        EditGrupo$: Observable<Action> = this.actions$.pipe(
            ofType(fromDetalleGruposAction.EDITA_GRUPO),
            map((action: fromDetalleGruposAction.EditaGrupo) => action.payload),
            switchMap((payload) => this.servicesGrupos.editaGrupo(payload).pipe(
                map(response => {
                    this.servicesMessage.messageBox( response.tipoRespuesta, response.mensaje);
                    if (response.tipoRespuesta === TipoRespuesta.Exito) {
                      return new fromDetalleGruposAction.EditaGrupoSuccess(response);
                    } else {
                        return new fromDetalleGruposAction.EditaGrupoFail(response);
                    }
                }, catchError(error => of(new fromDetalleGruposAction.EditaGrupoFail(error))))
            ))
        );
        @Effect()
        DeleteGrupo$: Observable<Action> = this.actions$.pipe(
            ofType(fromDetalleGruposAction.DELETE_GRUPO),
            map((action: fromDetalleGruposAction.DeleteGrupo) => action.payload),
            switchMap((payload) => this.servicesGrupos.deleteGrupo(payload).pipe(
                map(response => {
                    this.servicesMessage.messageBox( response.tipoRespuesta, response.mensaje);
                      if(response.tipoRespuesta == TipoRespuesta.Exito){
                        return new fromDetalleGruposAction.DeleteGrupoSuccess(response);
                      } else {
                          return new fromDetalleGruposAction.DeleteGrupoFail(response)
                      }
                      
                }, catchError(error => of(new fromDetalleGruposAction.DeleteGrupoFail(error))))
            ))
        );
        @Effect()
        DeleteMiembro$: Observable<Action> = this.actions$.pipe(
            ofType(fromDetalleGruposAction.DELETE_MIEMBRO_ASIGNADO),
            map((action: fromDetalleGruposAction.DeleteMiembroAsignado) => action.payload),
            switchMap((payload) => this.servicesGrupos.deleteMiembro(payload).pipe(
                map(response => {
                    this.servicesMessage.messageBox( response.tipoRespuesta, response.mensaje);
                    if (response.tipoRespuesta === TipoRespuesta.Exito) {
                        return new fromDetalleGruposAction.DeleteMiembroAsignadoSuccess(response);
                    } else {
                        return new fromDetalleGruposAction.DeleteMiembroAsignadoFail(response);
                    }
                }, catchError(error => of(new fromDetalleGruposAction.DeleteMiembroAsignado(error))))
            ))
        );
}
